const ABC = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");

var randomNumber = function(min, max) {
  const rand = min + Math.random() * (max - min);
  return Math.round(rand);
};
var randomZeichen = function() {
  const rand = Math.random() * 35;
  return ABC[Math.round(rand)];
};

exports.randomNumber = randomNumber;
exports.randomZeichen = randomZeichen;
