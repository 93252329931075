import React, { Component } from "react";
import ReactGA from "react-ga";
import classnames from "classnames";
import axios from "axios";
import TRANS from "../lib/translation";

import getField from "../lib/fromStore";

import Datenschutz from "../components/datenschutz";

export default class AlphaSignUP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      reasonwhy: "",
      acceptPP: false,
      errors: { email: "", acceptPP: "" },
      registered: false,
      showPP: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showPP = this.showPP.bind(this);

    // console.log(myFuncs.translationText(this.props.languages.lang));
  }

  validateEmail(email) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  onChange(event) {
    const { name, value } = event.target;

    if (event.target.type === "checkbox") {
      this.setState({ [name]: event.target.checked });
    } else {
      event.preventDefault();
      this.setState({ [name]: value });
    }
  }

  onSubmit(event) {
    event.preventDefault();
    let errors = this.state.errors;

    errors.email = this.validateEmail(this.state.email) ? "" : "ID24";

    errors.acceptPP = this.state.acceptPP ? "" : "ID25";

    this.setState({ errors });

    let proceed = true;
    Object.keys(errors).map(key =>
      // console.log(errors[key])
      errors[key] !== "" ? (proceed = false) : ""
    );

    if (proceed) {
      // console.log("weiter");
      console.log(event.target);
      console.log(event.target.name);
      const payload = {
        role: event.target.name,
        email: this.state.email,
        reasonwhy: this.state.reasonwhy,
        acceptPP: this.state.acceptPP
      };
      axios
        .post("/api/register/bealpha", payload)
        .then(res => {
          // console.log(res.data);
          this.setState({ registered: res.data });
          console.log(res.data);
          ReactGA.event({
            category: "User Action",
            action: "AlphaSignUp",
            label: this.state.reasonwhy
          });
        })
        .catch(err => console.log(err.response.data));
    }
  }

  showPP() {
    this.setState({ showPP: !this.state.showPP });
  }

  render() {
    return (
      <div className="ASU">
        {this.state.registered ? (
          <div className="ASU-ELEMENT">
            <TRANS field="ID26" />
          </div>
        ) : (
          <div className="ASU-ELEMENT">
            <div>
              <small>
                <b>
                  <u>
                    <TRANS field="ID27" />
                  </u>
                </b>
              </small>
            </div>
            <div>
              <form noValidate>
                <div className="form-group">
                  <label>
                    <TRANS field="ID28" />
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={classnames("form-control", {
                      "is-invalid": this.state.errors.email
                    })}
                    id="exampleFormControlInput1"
                    placeholder={getField("ID29")}
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                  {this.state.errors.email && (
                    <div className="invalid-feedback">
                      <TRANS field={this.state.errors.email} />
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    <TRANS field="ID30" />:
                  </label>
                  <textarea
                    name="reasonwhy"
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="2"
                    value={this.state.reasonwhy}
                    onChange={this.onChange}
                  ></textarea>
                </div>
                <div className="form-check">
                  <input
                    name="acceptPP"
                    className={classnames("form-check-input", {
                      "is-invalid": this.state.errors.acceptPP
                    })}
                    type="checkbox"
                    id="defaultCheck1"
                    checked={this.state.acceptPP}
                    onChange={this.onChange}
                  />
                  <label className="form-check-label">
                    <TRANS field="ID31" />
                    &nbsp;
                  </label>
                  <label
                    className="form-check-label privacy-policy-click"
                    onClick={this.showPP}
                  >
                    {" "}
                    <u>
                      <TRANS field="ID32" />
                    </u>
                  </label>
                  {this.state.errors.acceptPP && (
                    <div className="invalid-feedback">
                      <TRANS field={this.state.errors.acceptPP} />
                    </div>
                  )}
                  <div className="privacy-policy">
                    {this.state.showPP ? (
                      <div>
                        <Datenschutz lang={getField("ID0")} />
                        <div
                          className="form-check-label privacy-policy-click"
                          onClick={this.showPP}
                        >
                          <u>
                            <TRANS field="ID35" />
                          </u>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div>
                  <br />
                </div>
                <div className="buttons">
                  <div>
                    <button
                      name="join"
                      type="submit"
                      className="btn btn-primary sm-3"
                      onClick={this.onSubmit}
                    >
                      {getField("ID33")}
                    </button>
                  </div>
                  <div>
                    <button
                      name="developer"
                      type="submit"
                      className="btn btn-primary sm-3"
                      onClick={this.onSubmit}
                    >
                      {getField("ID34")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
