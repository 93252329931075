import React, { Component } from "react";
const myFuncs = require("../lib/myFuncs.js");

export default class LetterAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      output: "X",
      goal: "",
      counter: 0
    };

    this.timer = this.timer.bind(this);
  }
  update_stuff() {
    if (this.state.goal !== this.props.letter) {
      this.setState({ timer: setInterval(this.timer, 100) });
      this.setState({ repeat: this.props.repeat });
      this.setState({ goal: this.props.letter });
    }
  }

  componentDidMount() {
    this.update_stuff();
  }
  componentWillUnmount() {
    clearInterval(this.state.timer);
  }
  componentDidUpdate(prevProps, prevState) {
    this.update_stuff();
  }

  timer() {
    this.setState({ output: myFuncs.randomZeichen() });
    this.setState({ counter: this.state.counter + 1 });
    if (this.state.counter > this.state.repeat) {
      clearInterval(this.state.timer);
      this.setState({ output: this.props.letter });
    }
  }

  render() {
    return <span>{this.state.output} </span>;
  }
}
