import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import React from "react";
import "./App.css";
import { Provider } from "react-redux";

import { Router, Route } from "react-router-dom";

// my modules
import Navbar from "./components/Navbar.js";
import FlightMonitor from "./components/FlightMonitor";
import LanguageSwitcher from "./components/LanguageSwitcher";
import CoolTextBlock from "./components/CoolTextBlock";
import AlphaSignUP from "./components/AlphaSignUP";

import store from "./store";

var DeBug = false;
//DeBug = true;

var trackingId = "DEBUG";
if (!DeBug) {
  trackingId = "UA-105814366-3";
}
ReactGA.initialize(trackingId, {
  debug: DeBug
});
const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App(props) {
  return (
    <Provider store={store}>
      <Router history={history}>
        <div className="App ">
          <div className="container">
            <LanguageSwitcher />
            <Navbar />
            <Route
              exact
              path="/flight-monitor"
              render={props => <FlightMonitor />}
            />
            <Route exact path="/" render={props => <FlightMonitor />} />
            <Route
              path="/our-mission"
              render={props => <CoolTextBlock ID="ID16" />}
            />
            <Route
              path="/our-mission"
              render={props => <CoolTextBlock ID="ID17" />}
            />
            <Route
              path="/our-mission"
              render={props => <CoolTextBlock ID="ID18" />}
            />
            <Route path="/reg-log" render={props => <AlphaSignUP />} />
            <Route path="/contact" render={props => <AlphaSignUP />} />
          </div>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
