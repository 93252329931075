const initialState = {
  lang: "en",
  en: {
    ID0: "en",
    ID1: "Depature",
    ID2: "Arrival",
    ID3: "Flight Number",
    ID4: "Pilot",
    ID5: "Origin",
    ID6: "Dest.",
    ID7: "UTC",
    ID8: "Information",
    ID9: "Day",
    ID10: "Flight Monitor",
    ID11: "Live ACARS",
    ID12: "Menu",
    ID13:
      "Virtual-Airline.com is a computer game simulation based platform for virtual pilots to fly for any virtual Airline they want. They can track their hours and achievements in one place.",
    ID14: "More",
    ID15: "Current Day & Time UTC",
    ID16: {
      title: "FEATURES:",
      list: [
        "track all hour hours and destinations in one account",
        "fly for any virtual airline you wish",
        "no min. hours! this is our free time passion",
        "optional career mode",
        "enthusiast based point system",
        "optional bonus points (realism challenges)",
        "entity simulated fleet (usage) / renting pool aircrafts",
        "monthly actual flight schedules to their real world equivalent",
        "more to be come !"
      ]
    },
    ID17: {
      title: "WHY ANOTHER VIRTUAL AIRLINE:",
      text: [
        "YES, exactly! There are many virtual airlines out there and many of dead ones. There are small ones with 10 or so registered virtual pilots and some with many up to 10.000 and more.",
        "So why do I wanted to build another one. Well, for explaining I need to be opening up a little more the scope:",
        "Let's say I was about to flying in real life and as a passenger from Hannover in Germany to Cusco in Peru. There are many different connections but in matter of time or cost I could end up booking 3 flights. First leg would be Hannover to Madrid by Ryanair. Second leg Madrid to Lima by Iberia. And third leg Lima to Cusco by Latam. I need to fly onboard of 3 different airlines. Further I wish to return from Cusco to New York by Avianca and then to Paris by Air France and last but not least back to Hannover by KLM.",
        "So for a nice interesting round trip of 6 different Locations I would end up being a customer of 6 different airlines.",
        "That means in terms of virtual airlines I mostly need to register on 4 to 6 websites.",
        "",
        "I built Virtual-Airlines.com to overcome these hurdles."
      ]
    },
    ID18: {
      title: "BONUS POINTS / MORE REALISM CHALLENGES:",
      list: [
        "being on time by departure / arrival",
        "cold start / setup time",
        "waiting for delay on ground (random event by percentage OR wear/tear calculations)",
        "emergency situations in air (random event by percentage OR wear/tear calculations)",
        "taking other virtual pilots to a different location",
        "optional bonus points (realism challenges)",
        "economy system"
      ]
    },
    ID19: "Sign ON/ Sign IN",
    ID20: "The Idea",
    ID21: "Contact",
    ID22: "FAQ & Manual",
    ID23: "Pilot APP ACARS+",
    ID24: "Email is not valid!",
    ID25: "Privacy Policy must be agreed to!",
    ID26: "Registration Successful!",
    ID27:
      "This platform is still under construction. If you wish to be the first one to test things out feel free to register for the first public ALPHA test coming soon.",
    ID28: "Email Address:",
    ID29: "name@example.com",
    ID30: "Reason Why You Want To Join",
    ID31: "I agree to the",
    ID32: "privacy policy.",
    ID33: "I want to get notified and join the ALPHA when it's public!",
    ID34: "I want to participate developing this platform!",
    ID35: "close privacy policy"
  },
  de: {
    ID0: "de",
    ID1: "Abflug",
    ID2: "Ankunft",
    ID3: "Flugnummer",
    ID4: "Pilot",
    ID5: "Start",
    ID6: "Ziel",
    ID7: "UTC",
    ID8: "Information",
    ID9: "Tag",
    ID10: "Flug Monitor",
    ID11: "Live ACARS",
    ID12: "Menü",
    ID13:
      "Virtual-Airline.com ist eine Computerspiel-Simulationsbasierende Plattform, auf der virtuelle Piloten für jede gewünschte virtuelle Fluggesellschaft fliegen können. Sie können ihre Stunden und Leistungen an einem Ort verfolgen.",
    ID14: "Mehr",
    ID15: "Aktueller Tag & Zeit UTC",
    ID16: {
      title: "FEATURES:",
      list: [
        "verfolge alle deine Stunden und Ziele in einem Konto",
        "fliege für jede virtuelle Fluggesellschaft, die du möchtest",
        "keine min. Stunden! das ist unsere Freizeitleidenschaft",
        "optionaler Karrieremodus",
        "enthusiastisches Punktesystem",
        "optionale Bonuspunkte (Realismus-Herausforderungen)",
        "als Entität simulierte Flotte (Nutzungsgrad) / Anmietung von Pool-Flugzeugen",
        "monatlich tatsächliche Flugpläne, die ihren tatsächlichen Flugplänen entsprechen",
        "mehr wird kommen!"
      ]
    },
    ID17: {
      title: "WARUM NOCH EINE VIRTUELLE FLUGLINIE:",
      text: [
        "JA, genau! Es gibt viele virtuelle Fluggesellschaften und viele die bereits nicht mehr existieren. Es gibt kleine mit 10 oder mehr registrierten virtuellen Piloten und einige mit vielen bis zu 10.000 und mehr.",
        "Also, warum wollte ich noch eine bauen? Nun, um zu erklären, muss ich ein bisschen ausholen:",
        "Nehmen wir an, ich würde im realen Leben fliegen und als Passagier von Hannover in Deutschland nach Cusco in Peru. Es gibt viele verschiedene Verbindungen, aber in Bezug auf Zeit oder Kosten könnte ich am Ende 3 Flüge buchen. Die erste Etappe wäre Hannover nach Madrid mit Ryanair, Madrid nach Lima mit Iberia und Lima nach Cusco mit Latam, ich muss mit 3 verschiedenen Fluggesellschaften an Bord fliegen und möchte von Cusco mit Avianca nach New York und dann mit Air France nach Paris zurückkehren und zu guter Letzt zurück nach Hannover mit KLM. ",
        "Also für eine schöne interessante Rundreise von 6 verschiedenen Städten würde ich ein Kunde von 6 verschiedenen Fluggesellschaften sein.",
        "In Bezug auf virtuelle Fluggesellschaften muss ich mich meist auf 4 bis 6 Websites registrieren.",
        "",
        "Ich habe Virtual-Airlines.com gebaut, um diese Hürden zu überwinden."
      ]
    },
    ID18: {
      title: "BONUSPUNKTE / MEHR REALISMUS HERAUSFORDERUNGEN:",
      list: [
        "pünktlich bei Abflug / Ankunft sein",
        "Cold Start / Konfigurations-Zeit",
        "Warten auf Verzögerung am Boden (zufälliges Ereignis nach Prozentsatz ODER Verschleißberechnung)",
        "Notfallsituationen in der Luft (zufälliges Ereignis nach Prozentsatz ODER Verschleißberechnung)",
        "andere virtuelle Piloten an einen anderen Ort bringen",
        "optionale Bonuspunkte (Realismus-Herausforderungen)",
        "Wirtschaftssystem"
      ]
    },
    ID19: "Registrieren/Anmelden",
    ID20: "Die Idee",
    ID21: "Kontakt",
    ID22: "FAQ & Anleitung",
    ID23: "Piloten APP ACARS+",
    ID24: "Email ist nicht gültig!",
    ID25: "Datenschutzerklärung muss akzeptiert werden!",
    ID26: "Registrierung erfolgreich!",
    ID27:
      "Diese Plattform ist noch im Aufbau. Wenn Du einer der ersten sein willst um Alles auszutesten, registriere dich für den ersten öffentlichen Alpha-Test, kommt in Kürze.",
    ID28: "E-Mail-Adresse:",
    ID29: "name@beispiel.de",
    ID30: "Begründe, warum du teilnehmen möchtest",
    ID31: "Ich stimme der",
    ID32: "Datenschutzbestimmungen zu.",
    ID33:
      "Ich will informiert werden wenn die Alpha öffentlich geht und teilnehmen!",
    ID34: "Ich will helfen diese Plattform mitzuentwickeln!",
    ID35: "Datenschutzbestimmungen schließen"
  },
  es: {
    ID0: "es",
    ID1: "Salida",
    ID2: "Llegada",
    ID3: "Número de vuelo",
    ID4: "Piloto",
    ID5: "Origen",
    ID6: "Dest.",
    ID7: "UTC",
    ID8: "Información",
    ID9: "Día",
    ID10: "Monitor de vuelo",
    ID11: "ACARS en vivo",
    ID12: "Menú",
    ID13:
      "Virtual-Airline.com es una plataforma basada en simulación de juegos de computadora para que los pilotos virtuales vuelen por cualquier aerolínea virtual que deseen. Pueden seguir sus horas y logros en un solo lugar",
    ID14: "Más",
    ID15: "Día y Hora actual UTC",
    ID16: {
      title: "CARACTERÍSTICAS:",
      list: [
        "rastrea todas tus horas y destinos en una sola cuenta",
        "vuela para cualquier aerolínea virtual que desees",
        "¡Sin horas mínimas! Esta es nuestra pasión por el tiempo libre",
        "modo de carrera opcional",
        "sistema de puntos basado en entusiastas",
        "puntos de bonificación opcionales (desafíos de realismo)",
        "flota de simulación de entidad (uso) / alquiler de aeronaves de piscina",
        "horarios mensuales de vuelos reales a su equivalente en el mundo real",
        "más por venir!"
      ]
    },
    ID17: {
      title: "POR QUÉ OTRA AERONAVE VIRTUAL:",
      text: [
        "¡SÍ, exactamente! Hay muchas aerolíneas virtuales por ahí y muchas de las muertas. Hay otras pequeñas con aproximadamente 10 pilotos virtuales registrados y algunas con hasta 10.000 y más",
        "Entonces, ¿por qué quería construir otro? Bueno, para explicarlo necesito abrir un poco más el alcance:",
        "Digamos que estaba a punto de volar en la vida real y como pasajero de Hannover en Alemania a Cusco en Perú. Hay muchas conexiones diferentes, pero en cuestión de tiempo o costo podría terminar reservando 3 vuelos. La primera etapa sería Hannover a Madrid en Ryanair. Segunda etapa Madrid a Lima en Iberia. Y tercera etapa Lima a Cusco en Latam. Necesito volar a bordo de 3 aerolíneas diferentes. Además, deseo regresar de Cusco a Nueva York en Avianca y luego a París en Air France y por último, pero no menos importante, de regreso a Hannover por KLM ",
        "Por lo tanto, para un interesante viaje de ida y vuelta de 6 ubicaciones diferentes, terminaría siendo cliente de 6 aerolíneas diferentes",
        "Eso significa que, en términos de líneas aéreas virtuales, necesito registrarme principalmente en 4 a 6 sitios web",
        "",
        "Creé Virtual-Airlines.com para superar estos obstáculos"
      ]
    },
    ID18: {
      title: "PUNTOS DE BONIFICACIÓN / MÁS DESAFÍOS DEL REALISMO:",
      list: [
        "llegar a tiempo a la salida / llegada",
        "tiempo de arranque / configuración en frío",
        "esperando retraso en tierra (evento aleatorio por porcentaje O cálculos de desgaste / rotura)",
        "situaciones de emergencia en el aire (evento aleatorio por porcentaje O cálculos de desgaste / rotura)",
        "llevando a otros pilotos virtuales a una ubicación diferente",
        "puntos de bonificación opcionales (desafíos de realismo)",
        "sistema económico"
      ]
    },
    ID19: "Registro / Inicio de sesión",
    ID20: "La Idea",
    ID21: "Contacto",
    ID22: "FAQ & Instrucciones",
    ID23: "Piloto APP ACARS+",
    ID24: "¡El correo electrónico no es válido!",
    ID25: "¡La política de privacidad debe ser aceptada!",
    ID26: "¡Registro exitoso!",
    ID27:
      "Esta plataforma aún está en construcción. Si quieres ser uno de los primeros en probar todo, regístrate para la primera prueba alfa pública, próximamente",
    ID28: "Dirección de correo electrónico:",
    ID29: "nombre@ejampolo.de",
    ID30: "Justifica por qué quieres participar",
    ID31: "Estoy de acuerdo con la",
    ID32: "política de privacidad",
    ID33: "¡Quiero que me informen cuando Alpha se haga público y participar!",
    ID34: "¡Quiero ayudar a desarrollar esta plataforma!",
    ID35: "cerrar política de privacidad"
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "TEST":
      return {
        ...state,
        lang: action.newlang
      };
    default:
      return state;
  }
}
