import React, { Component } from "react";
import { connect } from "react-redux";
import CurrentTime from "./CurrentTime";
import TRANS from "../lib/translation";
import { changeLanguage } from "../actions/changelanguage";
import { ReactComponent as LogoDE } from "../media/de.svg";
import { ReactComponent as LogoENG } from "../media/gb.svg";
import { ReactComponent as LogoES } from "../media/es.svg";
import ReactGA from "react-ga";

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);

    var lang = navigator.language || navigator.userLanguage;

    if (lang.includes("de")) {
      this.props.changeLanguage("de");
    } else if (lang.includes("es")) {
      this.props.changeLanguage("es");
    } else {
      this.props.changeLanguage("en");
    }
    // EN override
    // this.props.changeLanguage("en");
  }

  english = e => {
    e.preventDefault();
    this.props.changeLanguage("en");
    this.track("en");
  };
  german = e => {
    e.preventDefault();
    this.props.changeLanguage("de");
    this.track("de");
  };
  spanish = e => {
    e.preventDefault();
    this.props.changeLanguage("es");
    this.track("es");
  };

  track(lang) {
    ReactGA.event({
      category: "User Action",
      action: "Language switched",
      label: lang
    });
  }
  render() {
    return (
      <div>
        <div className="d-flex justify-content-end">
          <div className="current_time text-nowrap">
            <small>
              <TRANS field="ID15" /> :
              <CurrentTime />
            </small>
          </div>
          <div className="lang-flags text-right">
            <a href="/abc" onClick={this.english}>
              <LogoENG />
            </a>
          </div>
          <div className="lang-flags text-right">
            <a href="/abc" onClick={this.german}>
              <LogoDE />
            </a>
          </div>
          <div className="lang-flags text-right">
            <a href="/abc" onClick={this.spanish}>
              <LogoES />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languages
});

export default connect(mapStateToProps, { changeLanguage })(LanguageSwitcher);
