import React, { Component } from "react";
import axios from "axios";

import TRANS from "../lib/translation";

import LetterAnimator from "./LetterAnimation";
const myFuncs = require("../lib/myFuncs.js");

export default class Monitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          flight: "",
          pilot: "",
          origin: "",
          destination: "",
          day: "",
          time: "",
          information: ""
        }
      ]
    };

    this.timer = this.timer.bind(this);
  }
  componentDidMount() {
    const myTimer = setInterval(this.timer, 10000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ myTimer: myTimer });
    this.timer();
  }
  componentDidUpdate() {}

  componentWillUnmount() {
    clearInterval(this.state.myTimer);
  }
  timer() {
    console.log("loading Data for " + this.props.name);
    axios
      .get("/api/flight-monitor/" + this.props.name)
      .then(res => {
        console.log("success loading Data for " + this.props.name);
        this.setState({ data: res.data.my_data });
      })
      .catch(err => console.log(err));
  }

  annimator_td(x, min, max) {
    return (
      <td className="flight-monitor-td">
        <LetterAnimator letter={x} repeat={myFuncs.randomNumber(min, max)} />
      </td>
    );
  }

  newRow(flight, index) {
    const min = 2;
    const max = 30;
    const number = flight.flight.split("");
    const pilot = flight.pilot.split("");
    const origin = flight.origin.split("");
    const day = flight.day.split("");
    const destination = flight.destination.split("");
    const time = flight.time.split("");
    const information = flight.information.split("");
    return (
      <tr key={index}>
        {this.annimator_td(number[0], min, max)}
        {this.annimator_td(number[1], min, max)}
        {this.annimator_td(number[2], min, max)}
        {this.annimator_td(number[3], min, max)}
        {this.annimator_td(number[4], min, max)}
        {this.annimator_td(number[5], min, max)}
        {this.annimator_td(number[6], min, max)}
        <td className="flight-monitor-td-empty"></td>
        {this.annimator_td(pilot[0], min, max)}
        {this.annimator_td(pilot[1], min, max)}
        {this.annimator_td(pilot[2], min, max)}
        {this.annimator_td(pilot[3], min, max)}
        {this.annimator_td(pilot[4], min, max)}
        {this.annimator_td(pilot[5], min, max)}
        {this.annimator_td(pilot[6], min, max)}
        {this.annimator_td(pilot[7], min, max)}
        <td className="flight-monitor-td-empty"></td>
        {this.annimator_td(origin[0], min, max)}
        {this.annimator_td(origin[1], min, max)}
        {this.annimator_td(origin[2], min, max)}
        <td className="flight-monitor-td-empty"></td>
        {this.annimator_td(destination[0], min, max)}
        {this.annimator_td(destination[1], min, max)}
        {this.annimator_td(destination[2], min, max)}
        <td className="flight-monitor-td-empty"></td>
        {this.annimator_td(day[0], min, max)}
        {this.annimator_td(day[1], min, max)}
        <td className="flight-monitor-td-empty"></td>
        {this.annimator_td(time[0], min, max)}
        {this.annimator_td(time[1], min, max)}
        {this.annimator_td(time[2], min, max)}
        {this.annimator_td(time[3], min, max)}
        {this.annimator_td(time[4], min, max)}
        <td className="flight-monitor-td-empty"></td>
        {this.annimator_td(information[0], min, max)}
        {this.annimator_td(information[1], min, max)}
        {this.annimator_td(information[2], min, max)}
        {this.annimator_td(information[3], min, max)}
        {this.annimator_td(information[4], min, max)}
        {this.annimator_td(information[5], min, max)}
        {this.annimator_td(information[6], min, max)}
        {this.annimator_td(information[7], min, max)}
        {this.annimator_td(information[8], min, max)}
        {this.annimator_td(information[9], min, max)}
        <td className="flight-monitor-td-empty"></td>
      </tr>
    );
  }
  render() {
    const { data } = this.state;
    return (
      <div>
        <table className="table-flight-monitor text-light">
          <tbody>
            <tr>
              <td></td>
              <td colSpan="43" className="flight-monitor-head">
                {this.props.name === "depature" ? (
                  <TRANS field="ID1" />
                ) : (
                  <TRANS field="ID2" />
                )}
              </td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="7">
                <TRANS field="ID3" />
              </td>
              <td></td>
              <td colSpan="8">
                <TRANS field="ID4" />
              </td>
              <td></td>
              <td colSpan="3">
                <TRANS field="ID5" />
              </td>
              <td></td>
              <td colSpan="3">
                <TRANS field="ID6" />
              </td>
              <td></td>
              <td colSpan="2">
                <TRANS field="ID9" />
              </td>
              <td></td>
              <td colSpan="5">
                <TRANS field="ID7" />
              </td>
              <td></td>
              <td colSpan="10">
                {/* {this.props.language.ID8} */}
                {/* {this.props.languages[this.props.languages.lang].ID8} */}
                <TRANS field="ID8" />
              </td>
              <td></td>
            </tr>
            {data.map((flight, index) => {
              return this.newRow(flight, index);
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
