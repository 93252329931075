import React, { Component } from "react";
import { connect } from "react-redux";

class CoolTextBlock extends Component {
  list(list) {
    return (
      <div>
        <ul>
          {list.map((entry, i) => {
            return <li key={i}>{entry}</li>;
          })}
        </ul>
      </div>
    );
  }

  text(text) {
    return (
      <div>
        {text.map((entry, i) => {
          return <p key={i}>{entry}</p>;
        })}
      </div>
    );
  }

  render() {
    return (
      <div className="cooltextblockwrap">
        <div className="cooltextblocktitle">
          {this.props.languages[this.props.languages.lang][this.props.ID].title}
        </div>
        <div className="cooltextblocktext">
          {this.props.languages[this.props.languages.lang][this.props.ID].list
            ? this.list(
                this.props.languages[this.props.languages.lang][this.props.ID]
                  .list
              )
            : ""}
        </div>
        <div className="cooltextblocktext">
          {this.props.languages[this.props.languages.lang][this.props.ID].text
            ? this.text(
                this.props.languages[this.props.languages.lang][this.props.ID]
                  .text
              )
            : ""}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languages
});

export default connect(mapStateToProps)(CoolTextBlock);
