import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../media/logo-entwurf.svg";
import TRANS from "../lib/translation";

export default function Navbar(props) {
  return (
    <nav className="navbar navbar-expand-md navbar navbar-dark my-nav-bg-color my-nav-bar sticky-top">
      <Link className="navbar-brand abc logo-width" to="/">
        <Logo />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="text-grey">
        <small>
          <TRANS field="ID13" />{" "}
          <Link to="/our-mission">
            <TRANS field="ID14" />
          </Link>
        </small>
      </div>
      <div
        className="collapse navbar-collapse my-nav-div-wrapper"
        id="navbarNavAltMarkup"
      >
        <div className="navbar-nav ">
          <div>
            <Link className="nav-item nav-link active" to="/flight-monitor">
              <TRANS field="ID10" />
              <span className="sr-only">(current)</span>
            </Link>
          </div>
          {/* <div>
            <Link className="nav-item nav-link disabled" to="/live-acars">
              <TRANS field="ID11" />
            </Link>
          </div> */}
          <div>
            <Link className="nav-item nav-link disabled" to="/live-acars">
              <TRANS field="ID23" />
            </Link>
          </div>
          <div className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <TRANS field="ID12" />
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <Link className="dropdown-item text-light" to="/reg-log">
                <div>
                  <TRANS field="ID19" />
                </div>
                {/* <img
                      src="https://img.icons8.com/dotty/80/000000/prop-plane.png"
                      alt=""
                      height="30px"
                    /> */}
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item text-light" to="/our-mission">
                <div>
                  <TRANS field="ID20" />
                </div>
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item text-light" to="/contact">
                <div>
                  <TRANS field="ID21" />
                </div>
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item text-light" to="/contact">
                <div>
                  <TRANS field="ID22" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
