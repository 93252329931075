import React, { Component } from "react";
import LetterAnimator from "./LetterAnimation";

const myFuncs = require("../lib/myFuncs.js");

export default class CurrentTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curH: "X",
      curM: "X",
      curS: "X",
      curD: "X"
    };
  }
  componentDidMount() {
    setInterval(() => {
      this.setState({
        curD: new Date().getUTCDate(),
        curH: new Date().getUTCHours(),
        curM: new Date().getUTCMinutes(),
        curS: new Date().getUTCSeconds()
      });
      // console.log(this.state.curS.toString()[0]);
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.state.curTime);
  }
  render() {
    return (
      <span className="wrap-time">
        <span className="flight-monitor-td-empty">&nbsp;&nbsp;&nbsp;</span>
        <span className="flight-monitor-td">
          <LetterAnimator
            letter={this.state.curD < 10 ? "0" : this.state.curD.toString()[0]}
            repeat={myFuncs.randomNumber(2, 10)}
          />
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator
            letter={
              this.state.curD < 10
                ? this.state.curD.toString()[0]
                : this.state.curD.toString()[1]
            }
            repeat={myFuncs.randomNumber(2, 10)}
          />
        </span>
        <span className="flight-monitor-td-empty">
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator
            letter={this.state.curH < 10 ? "0" : this.state.curH.toString()[0]}
            repeat={myFuncs.randomNumber(2, 10)}
          />
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator
            letter={
              this.state.curH < 10
                ? this.state.curH.toString()[0]
                : this.state.curH.toString()[1]
            }
            repeat={myFuncs.randomNumber(2, 10)}
          />
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator letter=":" repeat={myFuncs.randomNumber(2, 10)} />
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator
            letter={this.state.curM < 10 ? "0" : this.state.curM.toString()[0]}
            repeat={myFuncs.randomNumber(2, 10)}
          />
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator
            letter={
              this.state.curM < 10
                ? this.state.curM.toString()[0]
                : this.state.curM.toString()[1]
            }
            repeat={myFuncs.randomNumber(2, 10)}
          />
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator letter=":" repeat={myFuncs.randomNumber(2, 10)} />
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator
            letter={this.state.curS < 10 ? "5" : this.state.curS.toString()[0]}
            repeat={myFuncs.randomNumber(2, 10)}
          />
        </span>
        <span className="flight-monitor-td">
          <LetterAnimator
            letter={
              this.state.curS < 10
                ? this.state.curS.toString()[0]
                : this.state.curS.toString()[1]
            }
            repeat="0"
          />
        </span>
      </span>
    );
  }
}
