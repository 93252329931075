import React, { Component } from "react";
import { connect } from "react-redux";

class translation extends Component {
  render() {
    // console.log(this.props.field);
    return (
      <span>
        {this.props.languages[this.props.languages.lang][this.props.field]}
      </span>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languages
});

export default connect(mapStateToProps)(translation);
