import React, { Component } from "react";

export default class datenschutz extends Component {
  en() {
    return (
      <div>
        <br />
        <br />
        <h1 className="adsimple-111240294">Privacy Policy</h1>
        <h2 className="adsimple-111240294">data protection</h2>
        <p>
          We have this data protection declaration (version
          19.01.2020-111240294)            written to serve you according to the
          requirements of {""}
          <a
            className="adsimple-111240294"
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutz-Grundverordnung (EU) 2016/679
          </a>
          {""}explain what information we collect, how we use data and what
          decision options you as a visitor of this website to have.
        </p>
        <p>
          Unfortunately, it is in the nature of things that these explanations
          are very sound technical, but we tried to create the describe the most
          important things as simply and clearly as possible.
        </p>
        <h2 className="adsimple-111240294">Automatische Datenspeicherung</h2>
        <p>
          Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
          automatisch erstellt und gespeichert, so auch auf dieser Webseite.
        </p>
        <p>
          Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
          Webserver (Computer auf dem diese Webseite gespeichert ist)
          automatisch Daten wie
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            die Adresse (URL) der aufgerufenen Webseite
          </li>
          <li className="adsimple-111240294">Browser und Browserversion</li>
          <li className="adsimple-111240294">das verwendete Betriebssystem</li>
          <li className="adsimple-111240294">
            die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
          </li>
          <li className="adsimple-111240294">
            den Hostname und die IP-Adresse des Geräts von welchem aus
            zugegriffen wird
          </li>
          <li className="adsimple-111240294">Datum und Uhrzeit</li>
        </ul>
        <p>in Dateien (Webserver-Logfiles).</p>
        <p>
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
          danach automatisch gelöscht. Wir geben diese Daten nicht weiter,
          können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
          rechtswidrigem Verhalten eingesehen werden.
        </p>
        <h2 className="adsimple-111240294">Speicherung persönlicher Daten</h2>
        <p>
          Persönliche Daten, die Sie uns auf dieser Website elektronisch
          übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
          andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars
          oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt
          und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher
          verwahrt und nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit
          jenen Besuchern, die Kontakt ausdrücklich wünschen und für die
          Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
          Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht
          weiter, können jedoch nicht ausschließen, dass diese Daten beim
          Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        </p>
        <p>
          Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits
          dieser Webseite – können wir keine sichere Übertragung und den Schutz
          Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
          niemals unverschlüsselt per E-Mail zu übermitteln.
        </p>
        <h2 className="adsimple-111240294">
          Rechte laut Datenschutzgrundverordnung
        </h2>
        <p>
          Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen{" "}
          <a
            className="adsimple-111240294"
            href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzgesetzes (DSG)
          </a>{" "}
          grundsätzlich die folgende Rechte zu:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Recht auf Berichtigung (Artikel 16 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit
            der Berichtigung oder Löschung personenbezogener Daten oder der
            Einschränkung der Verarbeitung (Artikel 19 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Widerspruchsrecht (Artikel 21 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht, nicht einer ausschließlich auf einer automatisierten
            Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
            unterworfen zu werden (Artikel 22 DSGVO)
          </li>
        </ul>
        <p>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
          Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
          sonst in einer Weise verletzt worden sind, können Sie sich bei der
          Aufsichtsbehörde beschweren, welche in Österreich die
          Datenschutzbehörde ist, deren Webseite Sie unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.dsb.gv.at/?tid=111240294"
          >
            https://www.dsb.gv.at/
          </a>{" "}
          finden.
        </p>
        <h2 className="adsimple-111240294">
          Auswertung des Besucherverhaltens
        </h2>
        <p>
          In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob
          und wie wir Daten Ihres Besuchs dieser Website auswerten. Die
          Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir
          können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person
          schließen.
        </p>
        <p>
          Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu
          widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
        </p>
        <h2 className="adsimple-111240294">TLS-Verschlüsselung mit https</h2>
        <p>
          Wir verwenden https um Daten abhörsicher im Internet zu übertragen
          (Datenschutz durch Technikgestaltung{" "}
          <a
            className="adsimple-111240294"
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Artikel 25 Absatz 1 DSGVO
          </a>
          ). Durch den Einsatz von TLS (Transport Layer Security), einem
          Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
          können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen
          die Benutzung dieser Absicherung der Datenübertragung am kleinen
          Schlosssymbol links oben im Browser und der Verwendung des Schemas
          https (anstatt http) als Teil unserer Internetadresse.
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf unserer Website das Analyse-Tracking Tool Google
          Analytics (GA) des amerikanischen Unternehmens Google LLC (1600
          Amphitheatre Parkway Mountain View, CA 94043, USA). Google Analytics
          sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie
          beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie
          gespeichert und an Google Analytics versandt. Mithilfe der Berichte,
          die wir von Google Analytics erhalten, können wir unsere Website und
          unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir
          näher auf das Tracking Tool ein und informieren Sie vor allem darüber,
          welche Daten gespeichert werden und wie Sie das verhindern können.
        </p>
        <h3 className="adsimple-111240294">Was ist Google Analytics?</h3>
        <p>
          Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse
          unserer Website dient. Damit Google Analytics funktioniert, wird ein
          Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
          Website besuchen, zeichnet dieser Code verschiedene Handlungen auf,
          die Sie auf unserer Website ausführen. Sobald Sie unsere Website
          verlassen, werden diese Daten an die Google-Analytics-Server gesendet
          und dort gespeichert.
        </p>
        <p>
          Google verarbeitet die Daten und wir bekommen Berichte über Ihr
          Userverhalten. Dabei kann es sich unter anderem um folgende Berichte
          handeln:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User
            besser kennen und wissen genauer, wer sich für unser Service
            interessiert.
          </li>
          <li className="adsimple-111240294">
            Anzeigeberichte: Durch Anzeigeberichte können wir unsere
            Onlinewerbung leichter analysieren und verbessern.
          </li>
          <li className="adsimple-111240294">
            Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche
            Informationen darüber, wie wir mehr Menschen für unser Service
            begeistern können.
          </li>
          <li className="adsimple-111240294">
            Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website
            interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer
            Seite zurücklegen und welche Links Sie anklicken.
          </li>
          <li className="adsimple-111240294">
            Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie
            aufgrund einer Marketing-Botschaft eine gewünschte Handlung
            ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher
            zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser
            Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen
            bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
          </li>
          <li className="adsimple-111240294">
            Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf
            unserer Website passiert. Zum Beispiel sehen wir wie viele User
            gerade diesen Text lesen.
          </li>
        </ul>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google Analytics auf unserer Webseite?
        </h3>
        <p>
          Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
          bestmögliche Service bieten. Die Statistiken und Daten von Google
          Analytics helfen uns dieses Ziel zu erreichen.
        </p>
        <p>
          Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den
          Stärken und Schwächen unserer Website. Einerseits können wir unsere
          Seite so optimieren, dass sie von interessierten Menschen auf Google
          leichter gefunden wird. Andererseits helfen uns die Daten, Sie als
          Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
          unserer Website verbessern müssen, um Ihnen das bestmögliche Service
          zu bieten. Die Daten dienen uns auch, unsere Werbe- und
          Marketing-Maßnahmen individueller und kostengünstiger durchzuführen.
          Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen
          Menschen zu zeigen, die sich dafür interessieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Google Analytics gespeichert?
        </h3>
        <p>
          Google Analytics erstellt mithilfe eines Tracking-Codes eine
          zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist.
          So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste
          Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User
          erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID
          gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile
          auszuwerten.
        </p>
        <p>
          Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre
          Interaktionen auf unserer Website gemessen. Interaktionen sind alle
          Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
          auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können
          über Google Analytics generierte Daten mit Drittanbieter-Cookies
          verknüpft werden. Google gibt keine Google Analytics-Daten weiter,
          außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es
          kommen, wenn es gesetzlich erforderlich ist.
        </p>
        <p>Folgende Cookies werden von Google Analytics verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _ga
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          2.1326744211.152111240294-5
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu
          speichern. Grundsätzlich dient es zur Unterscheidung der
          Webseitenbesucher.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          2.1687193234.152111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie dient auch zur Unterscheidung der Webseitenbesucher
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 24
          Stunden
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gat_gtag_UA_
          {"<property-id>"}
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Wird
          zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über
          den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den
          Namen _dc_gtm_ {"<property-id>"}.<br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 1
          Minute
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> AMP_TOKEN
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angaben
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst
          abgerufen werden kann. Andere mögliche Werte weisen auf eine
          Abmeldung, eine Anfrage oder einen Fehler hin.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Sekunden bis zu einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utma
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          1564498958.1564498958.1564498958.1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Mit
          diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und sie
          Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn
          Informationen an Google Analytics gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmt
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird wie _gat_gtag_UA_{"<property-id>"} zum Drosseln der
          Anforderungsrate verwendet.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 10
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmb
          <br />
          <strong className="adsimple-111240294">Wert:</strong>3.10.1564498958
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird
          jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics
          gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmc
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 167421564
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende
          Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und
          wird nur solange gespeichert, bis Sie den Browser wieder schließen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> Nach
          Schließung des Browsers
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmz
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          m|utmccn=(referral)|utmcmd=referral|utmcct=/
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird verwendet, um die Quelle des Besucheraufkommens auf
          unserer Website zu identifizieren. Das heißt, das Cookie speichert,
          von wo Sie auf unsere Website gekommen sind. Das kann eine andere
          Seite bzw. eine Werbeschaltung gewesen sein.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 6
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmv
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern.
          Es wird immer aktualisiert, wenn Informationen an Google Analytics
          gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <p>
          Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die
          mit Google Analytics erhoben werden:
        </p>
        <p>
          <strong className="adsimple-111240294">Heatmaps:</strong> Google legt
          sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche,
          die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer
          Seite „unterwegs“ sind.
        </p>
        <p>
          <strong className="adsimple-111240294">Sitzungsdauer:</strong> Als
          Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite
          verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv
          waren, endet die Sitzung automatisch.
        </p>
        <p>
          <strong className="adsimple-111240294">Absprungrate</strong> (engl.
          Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer
          Website nur eine Seite ansehen und dann unsere Website wieder
          verlassen.
        </p>
        <p>
          <strong className="adsimple-111240294">Kontoerstellung:</strong> Wenn
          Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung
          machen, erhebt Google Analytics diese Daten.
        </p>
        <p>
          <strong className="adsimple-111240294">IP-Adresse:</strong> Die
          IP-Adresse wird nur in gekürzter Form dargestellt, damit keine
          eindeutige Zuordnung möglich ist.
        </p>
        <p>
          <strong className="adsimple-111240294">Standort:</strong> Über die
          IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden.
          Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.
        </p>
        <p>
          <strong className="adsimple-111240294">
            Technische Informationen:
          </strong>{" "}
          Zu den technischen Informationen zählen unter anderem Ihr Browsertyp,
          Ihr Internetanbieter oder Ihre Bildschirmauflösung.
        </p>
        <p>
          <strong className="adsimple-111240294">Herkunftsquelle:</strong>{" "}
          Google Analytics beziehungsweise uns interessiert natürlich auch über
          welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.
        </p>
        <p>
          Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen
          von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das
          Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren
          Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und
          dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch
          Google Analytics.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten
          Server befinden sich in Amerika und folglich werden Ihr Daten meist
          auf amerikanischen Servern gespeichert. Hier können Sie genau
          nachlesen wo sich die Google-Rechenzentren befinden:{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>
        </p>
        <p>
          Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt.
          Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor
          Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt
          es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise
          die Hardware bei Google ausfällt oder Naturkatastrophen Server
          lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google
          dennoch gering.
        </p>
        <p>
          Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer
          Userdaten von 26 Monaten eingestellt. Dann werden Ihre Userdaten
          gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer
          von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur
          Verfügung:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Löschung nach 14 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 26 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 38 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 50 Monaten</li>
          <li className="adsimple-111240294">Keine automatische Löschung</li>
        </ul>
        <p>
          Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat
          die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die
          mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
          DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf
          aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
          Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu
          einer größeren Einheit.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht,
          Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen
          oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung
          von Google Analytics-JavaScript (ga.js, analytics.js, dc.js)
          verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das
          Browser-Add-on können Sie unter{" "}
          <a
            className="adsimple-111240294"
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>{" "}
          runterladen und installieren. Beachten Sie bitte, dass durch dieses
          Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
        </p>
        <p>
          Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics)
          deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser
          eine eigene Anleitung:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
          Framework, wodurch der korrekte und sichere Datentransfer persönlicher
          Daten geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=111240294
          </a>
          . Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um
          die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr
          über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden
          Links:{" "}
          <a
            className="adsimple-111240294"
            href="http://www.google.com/analytics/terms/de.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.google.com/analytics/terms/de.html
          </a>{" "}
          und{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics IP-Anonymisierung
        </h2>
        <p>
          Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von
          Google Analytics implementiert. Diese Funktion wurde von Google
          entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen
          und Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn
          diese eine Speicherung der vollständigen IP-Adresse untersagen. Die
          Anonymisierung bzw. Maskierung der IP findet statt, sobald die
          IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und
          bevor eine Speicherung oder Verarbeitung der Daten stattfindet.
        </p>
        <p>
          Mehr Informationen zur IP-Anonymisierung finden Sie auf 
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/2763052?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/2763052?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Berichte zu demografischen Merkmalen und Interessen
        </h2>
        <p>
          Wir haben in Google Analytics die Funktionen für Werbeberichte
          eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen
          enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können
          wir uns – ohne diese Daten einzelnen Personen zuordnen zu können – ein
          besseres Bild von unseren Nutzern machen. Mehr über die
          Werbefunktionen erfahren Sie{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad"
            target="_blank"
            rel="noopener noreferrer"
          >
            auf https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad
          </a>
          .
        </p>
        <p>
          Sie können die Nutzung der Aktivitäten und Informationen Ihres Google
          Kontos unter “Einstellungen für Werbung” auf{" "}
          <a
            className="adsimple-111240294"
            href="https://adssettings.google.com/authenticated"
          >
            https://adssettings.google.com/authenticated
          </a>{" "}
          per Checkbox beenden.
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Deaktivierungslink
        </h2>
        <p>
          Wenn Sie auf folgenden{" "}
          <strong className="adsimple-111240294">Deaktivierungslink</strong>{" "}
          klicken, können Sie verhindern, dass Google weitere Besuche auf dieser
          Webseite erfasst. Achtung: Das Löschen von Cookies, die Nutzung des
          Inkognito/Privatmodus ihres Browsers, oder die Nutzung eines anderen
          Browsers führt dazu, dass wieder Daten erhoben werden.
        </p>
        <span
          className="adsimple-111240294"
          //   style="cursor: help; border: 0 none; border-bottom-width: 1px; border-style: dashed;"
          title="Es wurde kein UA-Code eingegeben. Bitten Sie den Administrator, dieses Problem zu beheben!"
        >
          Google Analytics deaktivieren
        </span>
        <p> </p>
        <h2 className="adsimple-111240294">
          Google Analytics Zusatz zur Datenverarbeitung
        </h2>
        <p>
          Wir haben mit Google einen Direktkundenvertrag zur Verwendung von
          Google Analytics abgeschlossen, indem wir den “Zusatz zur
          Datenverarbeitung” in Google Analytics akzeptiert haben.
        </p>
        <p>
          Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden
          Sie hier:{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad
          </a>
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Google-Signale Datenschutzerklärung
        </h2>
        <p>
          Wir haben in Google Analytics die Google-Signale aktiviert. So werden
          die bestehenden Google-Analytics-Funktionen (Werbeberichte,
          Remarketing, gerätübergreifende Berichte und Berichte zu Interessen
          und demografische Merkmale) aktualisiert, um zusammengefasste und
          anonymisierte Daten von Ihnen zu erhalten, sofern Sie personalisierte
          Anzeigen in Ihrem Google-Konto erlaubt haben.
        </p>
        <p>
          Das besondere daran ist, dass es sich dabei um ein
          Cross-Device-Tracking handelt. Das heißt Ihre Daten können
          geräteübergreifend analysiert werden. Durch die Aktivierung von
          Google-Signale werden Daten erfasst und mit dem Google-Konto
          verknüpft. Google kann dadurch zum Beispiel erkennen, wenn Sie auf
          unsere Webseite über ein Smartphone ein Produkt ansehen und erst
          später über einen Laptop das Produkt kaufen. Dank der Aktivierung von
          Google-Signale können wir gerätübergreifende Remarketing-Kampagnen
          starten, die sonst in dieser Form nicht möglich wären. Remarketing
          bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot
          zeigen können.
        </p>
        <p>
          In Google Analytics werden zudem durch die Google-Signale weitere
          Besucherdaten wie Standort, Suchverlauf, YouTube-Verlauf und Daten
          über Ihre Handlungen auf unserer Webseite, erfasst. Wir erhalten
          dadurch von Google bessere Werbeberichte und nützlichere Angaben zu
          Ihren Interessen und demografischen Merkmalen. Dazu gehören Ihr Alter,
          welche Sprache sie sprechen, wo Sie wohnen oder welchem Geschlecht Sie
          angehören. Weiters kommen auch noch soziale Kriterien wie Ihr Beruf,
          Ihr Familienstand oder Ihr Einkommen hinzu. All diese Merkmal helfen
          Google Analytics Personengruppen bzw. Zielgruppen zu definieren.
        </p>
        <p>
          Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und
          Interessen besser einschätzen zu können. Dadurch können wir unsere
          Dienstleistungen und Produkte für Sie optimieren und anpassen. Diese
          Daten laufen standardmäßig nach 26 Monaten ab. Bitte beachten Sie,
          dass diese Datenerfassung nur erfolgt, wenn Sie personalisierte
          Werbung in Ihrem Google-Konto zugelassen haben. Es handelt sich dabei
          immer um zusammengefasste und anonyme Daten und nie um Daten einzelner
          Personen. In Ihrem Google-Konto können Sie diese Daten verwalten bzw.
          auch löschen.
        </p>
        <h2 className="adsimple-111240294">
          Facebook-Pixel Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook.
          Dafür haben wir einen Code auf unserer Webseite implementiert. Der
          Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine
          Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen
          verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite
          gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer
          Webseite erwerben, wird das Facebook-Pixel ausgelöst und speichert
          Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies.
          Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie
          IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen.
          Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für
          uns anonym und nicht einsehbar und werden nur im Rahmen von
          Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind
          und angemeldet sind, wird der Besuch unserer Webseite automatisch
          Ihrem Facebook-Benutzerkonto zugeordnet.
        </p>
        <p>
          Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen
          zeigen, die sich auch wirklich dafür interessieren. Mithilfe von
          Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche
          und Interessen abgestimmt werden. So bekommen Facebook-User (sofern
          sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen.
          Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und
          eigenen Werbeanzeigen.
        </p>
        <p>
          Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden
          von Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachten
          Sie, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf
          unserer Webseite werden unterschiedliche Cookies gesetzt.
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _fbp
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          fb.1.1568287647279.257405483-6111240294-7
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie verwendet Facebook, um Werbeprodukte anzuzeigen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0aPf312HOS5Pboo2r..Bdeiuf…1.0.Bdeiuf.
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit Facebook-Pixel auch ordentlich
          funktioniert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_50ae8267e2bdf1253ec1a5769f48e062111240294-3
          <br />
          <strong className="adsimple-111240294">Wert:</strong> Name des Autors
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert den Text und den Namen eines Users, der
          beispielsweise einen Kommentar hinterlässt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_url_50ae8267e2bdf1253ec1a5769f48e062
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          https%3A%2F%2Fwww.testseite…%2F (URL des Autors)
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert die URL der Website, die der User in einem
          Textfeld auf unserer Webseite eingibt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_email_50ae8267e2bdf1253ec1a5769f48e062
          <br />
          <strong className="adsimple-111240294">Wert:</strong> E-Mail-Adresse
          des Autors
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert die E-Mail-Adresse des Users, sofern er sie
          auf der Website bekannt gegeben hat.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung: </strong>Die oben
          genannten Cookies beziehen sich auf ein individuelles Userverhalten.
          Speziell bei der Verwendung von Cookies sind Veränderungen bei
          Facebook nie auszuschließen.
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>{" "}
          grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
          haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
          aktivieren.
        </p>
        <p>
          Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
          empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Facebook Automatischer erweiterter Abgleich Datenschutzerklärung
        </h2>
        <p>
          Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen
          erweiterten Abgleich (engl. Automatic Advanced Matching) aktiviert.
          Diese Funktion des Pixels ermöglicht uns, gehashte E-Mails, Namen,
          Geschlecht, Stadt, Bundesland, Postleitzahl und Geburtsdatum oder
          Telefonnummer als zusätzliche Informationen an Facebook zu senden,
          sofern Sie uns diese Daten zur Verfügung gestellt haben. Diese
          Aktivierung ermöglicht uns Werbekampagnen auf Facebook noch genauer
          auf Menschen, die sich für unsere Dienstleistungen oder Produkte
          interessieren, anzupassen.
        </p>
        <h2 className="adsimple-111240294">Newsletter Datenschutzerklärung</h2>
        <p>
          <span className="adsimple-111240294">
            Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die
            oben genannten persönlichen Daten und geben uns das Recht Sie per
            E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter
            gespeicherten Daten nutzen wir ausschließlich für unseren Newsletter
            und geben diese nicht weiter.
          </span>
        </p>
        <p>
          <span className="adsimple-111240294">
            Sollten Sie sich vom Newsletter abmelden – Sie finden den Link dafür
            in jedem Newsletter ganz unten – dann löschen wir alle Daten die mit
            der Anmeldung zum Newsletter gespeichert wurden.
          </span>
        </p>
        <h2 className="adsimple-111240294">
          Google AdSense Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf dieser Webseite Google AdSense. Das ist ein
          Anzeigenprogramm der Firma Google Inc. (1600 Amphitheatre Parkway
          Mountain View, CA 94043, USA). Mit Google AdSense können wir auf
          dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema passen.
          So bieten wir Ihnen Anzeigen, die im Idealfall einen richtigen
          Mehrwert für Sie darstellen. Im Zuge dieser Datenschutzerklärung über
          Google AdSense erklären wir Ihnen, warum wir Google AdSense auf
          unserer Webseite verwenden, welche Daten von Ihnen verarbeitet und
          gespeichert werden und wie Sie diese Datenspeicherung unterbinden
          können.
        </p>
        <h3 className="adsimple-111240294">Was ist Google AdSense?</h3>
        <p>
          Das Werbeprogramm Google AdSense gibt es mittlerweile seit 2003. Im
          Gegensatz zu Google Ads (früher: Google AdWords) kann man hier nicht
          selbst Werbung schalten. Über Google AdSense werden Werbeanzeigen auf
          Webseiten, wie zum Beispiel auf unserer, ausgespielt. Der größte
          Vorteil dieses Werbedienstes im Vergleich zu manch anderen ist, dass
          Ihnen Google AdSense nur Anzeigen zeigt, die zu unseren Inhalten
          passen. Google hat einen eigenen Algorithmus, der berechnet, welche
          Werbeanzeigen Sie zu Gesicht bekommen. Natürlich wollen wir Ihnen nur
          Werbung bieten, die Sie auch interessiert und Ihnen einen Mehrwert
          bietet. Google überprüft anhand Ihrer Interessen bzw. Ihres
          Userverhaltens und anhand unseres Angebots, welche Werbeanzeigen für
          unsere Webseite und für unserer User geeignet sind. An dieser Stelle
          wollen wir auch gleich erwähnen, dass wir für die Auswahl der
          Werbeanzeigen nicht verantwortlich sind. Wir bieten mit unserer
          Webseite lediglich die Werbefläche an. Die Auswahl der angezeigten
          Werbung trifft Google. Seit August 2013 werden die Anzeigen auch an
          die jeweilige Benutzeroberfläche angepasst. Das heißt, egal ob Sie von
          Ihrem Smartphone, Ihrem PC oder Laptop unsere Webseite besuchen, die
          Anzeigen passen sich an Ihr Endgerät an.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google AdSense auf unserer Webseite?
        </h3>
        <p>
          Das Betreiben einer hochwertigen Webseite erfordert viel Hingabe und
          großen Einsatz. Im Grunde sind wir mit der Arbeit an unserer Webseite
          nie fertig. Wir versuchen stets unsere Seite zu pflegen und so aktuell
          wie möglich zu halten. Natürlich wollen wir mit dieser Arbeit auch
          einen wirtschaftlichen Erfolg erzielen. Darum haben wir uns für
          Werbeanzeigen als Einnahmequelle entschieden. Das Wichtigste für uns
          ist allerdings, Ihren Besuch auf unserer Webseite durch diese Anzeigen
          nicht zu stören. Mithilfe von Google AdSense wird Ihnen nur Werbung
          angeboten, die zu unseren Themen und Ihren Interessen passt.
        </p>
        <p>
          Ähnlich wie bei der Google-Indexierung für eine Webseite, untersucht
          ein Bot den entsprechenden Content und die entsprechenden Angebote
          unserer Webseite. Dann werden die Werbeanzeigen inhaltlich angepasst
          und auf der Webseite präsentiert. Neben den inhaltlichen
          Überschneidungen zwischen Anzeige und Webseiten-Angebot unterstützt
          AdSense auch interessensbezogenes Targeting. Das bedeutet, dass Google
          auch Ihre Daten dazu verwendet, um auf Sie zugeschnittene Werbung
          anzubieten. So erhalten Sie Werbung, die Ihnen im Idealfall einen
          echten Mehrwert bietet und wir haben eine höhere Chance ein bisschen
          etwas zu verdienen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Google AdSense gespeichert?
        </h3>
        <p>
          Damit Google AdSense eine maßgeschneiderte, auf Sie angepasste Werbung
          anzeigen kann, werden unter anderem Cookies verwendet. Cookies sind
          kleine Textdateien, die bestimmte Informationen auf Ihrem Computer
          speichern.
        </p>
        <p>
          In AdSense sollen Cookies bessere Werbung ermöglichen. Die Cookies
          enthalten keine personenidentifizierbaren Daten. Hierbei ist
          allerdings zu beachten, dass Google Daten wie zum Beispiel “Pseudonyme
          Cookie-IDs” (Name oder anderes Identifikationsmerkmal wird durch ein
          Pseudonym ersetzt) oder IP-Adressen als nicht personenidentifizierbare
          Informationen ansieht. Im Rahmen der DSGVO können diese Daten
          allerdings als personenbezogene Daten gelten. Google AdSense sendet
          nach jeder Impression (das ist immer dann der Fall, wenn Sie eine
          Anzeige sehen), jedem Klick und jeder anderen Aktivität, die zu einem
          Aufruf der Google AdSense-Server führt, ein Cookie an den Browser.
          Sofern der Browser das Cookie akzeptiert, wird es dort gespeichert.
        </p>
        <p>
          Drittanbieter können im Rahmen von AdSense unter Umständen Cookies in
          Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um
          Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der
          Webseite erhalten. Als Web-Beacons bezeichnet man kleine Grafiken, die
          eine Logdatei-Analyse und eine Aufzeichnung der Logdatei machen. Diese
          Analyse ermöglicht eine statistische Auswertung für das
          Online-Marketing.
        </p>
        <p>
          Google kann über diese Cookies bestimmte Informationen über Ihr
          Userverhalten auf unserer Webseite sammeln. Dazu zählen:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Informationen wie Sie mit einer Anzeige umgehen (Klicks, Impression,
            Mausbewegungen)
          </li>
          <li className="adsimple-111240294">
            Informationen, ob in Ihrem Browser schon eine Anzeige zu einem
            früheren Zeitpunkt erschienen ist. Diese Daten helfen dabei, Ihnen
            eine Anzeige nicht öfter anzuzeigen.
          </li>
        </ul>
        <p>
          Dabei analysiert Google die Daten zu den angezeigten Werbemitteln und
          Ihre IP-Adresse und wertet diese aus. Google verwendet die Daten in
          erster Linie, um die Effektivität einer Anzeige zu messen und das
          Werbeangebot zu verbessern. Diese Daten werden nicht mit
          personenbezogenen Daten, die Google möglicherweise über andere
          Google-Dienste von Ihnen hat, verknüpft.
        </p>
        <p>
          Im Folgenden stellen wir Ihnen Cookies vor, die Google AdSense für
          Trackingzwecke verwendet. Hierbei beziehen wir uns auf eine
          Test-Webseite, die ausschließlich Google AdSense installiert hat:
          <strong className="adsimple-111240294"> </strong>
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> uid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          891269189111240294-8
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird unter der Domain adform.net gespeichert. Es stellt eine
          eindeutig zugewiesene, maschinell generierte User-ID bereit und
          sammelt Daten über die Aktivität auf unserer Webseite.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> C<br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie identifiziert, ob Ihrer Browser Cookies akzeptiert. Das
          Cookie wird unter der Domain track.adform.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> cid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          8912691894970695056,0,0,0,0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird unter der Domain track.adform.net gespeichert,
          steht für Client-ID und wird verwendet, um die Werbung für Sie zu
          verbessern. Es kann relevantere Werbung an den Besucher weiterleiten
          und hilft, die Berichte über die Kampagnenleistung zu verbessern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> IDE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          zOtj4TWxwbFDjaATZ2TzNaQmxrU111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird unter der Domain doubkeklick.net gespeichert. Es dient
          dazu, Ihre Aktionen nach der Anzeige bzw. nach dem Klicken der Anzeige
          zu registrieren. Dadurch kann man messen, wie gut eine Anzeige bei
          unseren Besuchern ankommt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> test_cookie
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Mithilfe des „test_cookies“ kann man überprüfen, ob Ihr Browser
          überhaupt Cookies unterstützt. Das Cookie wird unter der Domain
          doubleklick.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CT592996
          <br />
          <strong className="adsimple-111240294">Wert:</strong>733366
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Wird
          unter der Domain adform.net gespeichert. Das Cookie wird gesetzt
          sobald Sie auf eine Werbeanzeige klicken. Genauere Informationen über
          die Verwendung dieses Cookies konnten wir nicht in Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einer Stunde
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Google erfasst Ihre IP-Adresse und verschiedene Aktivitäten, die Sie
          auf der Webseite ausführen. Cookies speichern diese Informationen zu
          den Interaktionen auf unsere Webseite. Laut Google sammelt und
          speichert das Unternehmen die angegebenen Informationen auf sichere
          Weise auf den hauseigenen Google-Servern in den USA.
        </p>
        <p>
          Wenn Sie kein Google-Konto haben bzw. nicht angemeldet sind, speichert
          Google die erhobenen Daten mit einer eindeutigen Kennung (ID) meist
          auf Ihrem Browser. Die in Cookies gespeicherten eindeutigen IDs dienen
          beispielsweise dazu, personalisierte Werbung zu gewährleisten. Wenn
          Sie in einem Google-Konto angemeldet sind, kann Google auch
          personenbezogene Daten erheben.
        </p>
        <p>
          Einige der Daten, die Google speichert, können Sie jederzeit wieder
          löschen (siehe nächsten Abschnitt). Viele Informationen, die in
          Cookies gespeichert sind, werden automatisch nach einer bestimmten
          Zeit wieder gelöscht. Es gibt allerdings auch Daten, die von Google
          über einen längeren Zeitraum gespeichert werden. Dies ist dann der
          Fall, wenn Google aus wirtschaftlichen oder rechtlichen
          Notwendigkeiten, gewisse Daten über einen unbestimmten, längeren
          Zeitraum speichern muss.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben immer die Möglichkeit Cookies, die sich auf Ihrem Computer
          befinden, zu löschen oder zu deaktivieren. Wie genau das funktioniert
          hängt von Ihrem Browser ab.
        </p>
        <p>
          Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
          verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
          Herunterladen und Installieren dieses Browser-Plug-ins auf{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/ads/answer/7395996"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/ads/answer/7395996
          </a>{" "}
          werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass
          Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
          verhindern, sondern nur die personalisierte Werbung.
        </p>
        <p>
          Wenn Sie ein Google Konto besitzen, können Sie auf der Webseite{" "}
          <a
            className="adsimple-111240294"
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://adssettings.google.com/authenticated
          </a>{" "}
          personalisierte Werbung deaktivieren. Auch hier sehen Sie dann weiter
          Anzeigen, allerdings sind diese nicht mehr an Ihre Interessen
          angepasst. Dennoch werden die Anzeigen auf der Grundlage von ein paar
          Faktoren, wie Ihrem Standort, dem Browsertyp und der verwendeten
          Suchbegriffe, angezeigt.
        </p>
        <p>
          Welche Daten Google grundsätzlich erfasst und wofür sie diese Daten
          verwenden, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>{" "}
          nachlesen.
        </p>
        <h2 className="adsimple-111240294">
          Amazon-Partnerprogramm Datenschutzerklärung
        </h2>
        <p>
          Wir sind Teilnehmer am Amazon Partnerprogramm welches die Platzierung
          von Amazon-Werbeanzeigen und Partnerlinks auf Webseiten ermöglicht.
          Durch diese Platzierung von Amazon Werbung wird eine
          Werbekostenerstattung erzielt.
          <br />
          Um die Herkunft von Bestellungen nachvollziehen zu können, setzt
          Amazon Cookies ein.
          <br />
          Verantwortliche Stelle im Sinne der Datenschutzgesetze sind die Amazon
          Europe Core S.à.r.l., die Amazon EU S.à.r.l, die Amazon Services
          Europe S.à.r.l. und die Amazon Media EU S.à.r.l., alle vier ansässig
          5, Rue Plaetis, L-2338 Luxemburg sowie Amazon Instant Video Germany
          GmbH, Domagkstr. 28, 80807 München. Als Datenverarbeiter wird in ihrem
          Auftrag die Amazon Deutschland Services GmbH, Marcel-Breuer-Str. 12,
          80807 München, tätig.
          <br />
          Die Datenschutzrichtlinien, welche Informationen Amazon sammelt und
          wie sie diese verwenden finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401"
          >
            https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden als Online-Marketing-Maßnahme Google Ads (früher Google
          AdWords), um unsere Produkte und Dienstleistungen zu bewerben. So
          wollen wir im Internet mehr Menschen auf die hohe Qualität unserer
          Angebote aufmerksam machen. Im Rahmen unserer Werbe-Maßnahmen durch
          Google Ads verwenden wir auf unserer Webseite das Conversion-Tracking
          der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
          USA (“Google”). Mithilfe dieses kostenlosen Tracking-Tools können wir
          unser Werbeangebot an Ihre Interessen und Bedürfnisse  besser
          anpassen. Im Folgenden Artikel wollen wir genauer darauf eingehen,
          warum wir Conversion-Tracking benutzen, welche Daten dabei gespeichert
          werden und wie Sie diese Datenspeicherung verhindern.
        </p>
        <h3 className="adsimple-111240294">
          Was ist Google Ads Conversion-Tracking?
        </h3>
        <p>
          Google Ads (früher Google AdWords) ist das hauseigene
          Online-Werbesystem der Firma Google LLC. Wir sind von der Qualität
          unseres Angebots überzeugt und wollen, dass so viele Menschen wie
          möglich unsere Webseite kennenlernen. Im Onlinebereich bietet Google
          Ads dafür die beste Plattform. Natürlich wollen wir auch einen genauen
          Überblick über den Kosten-Nutzen-Faktor unsere Werbeaktionen gewinnen.
          Darum verwenden wir das Conversion-Tracking-Tool von Google Ads.
        </p>
        <p>
          Doch was ist eine Conversion eigentlich? Eine Conversion entsteht,
          wenn Sie von einem rein interessierten Webseitenbesucher zu einem
          handelnden Besucher werden. Dies passiert immer dann, wenn Sie auf
          unsere Anzeige klicken und im Anschluss eine andere Aktion ausführen,
          wie zum Beispiel unsere Webseite besuchen. Mit dem
          Conversion-Tracking-Tool von Google erfassen wir, was nach einem Klick
          eines Users auf unsere Google Ads-Anzeige geschieht. Zum Beispiel
          können wir so sehen, ob Produkte gekauft werden, Dienstleistungen in
          Anspruch genommen werden oder ob sich User für unseren Newsletter
          angemeldet haben.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google Ads Conversion-Tracking auf unserer
          Webseite?
        </h3>
        <p>
          Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser
          Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen
          wirklich auch nur jene Menschen erreichen, die sich für unsere
          Angebote interessieren. Mit dem Conversion-Tracking Tool sehen wir
          welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den
          gewünschten Kundenaktionen führen. Wir sehen wie viele Kunden mit
          unseren Anzeigen auf einem Gerät interagieren und dann eine Conversion
          durchführen. Durch diese Daten können wir unseren Kosten-Nutzen-Faktor
          berechnen, den Erfolg einzelner Werbemaßnahmen messen und folglich
          unsere Online-Marketing-Maßnahmen optimieren. Wir können weiters
          mithilfe der gewonnenen Daten unsere Webseite für Sie interessanter
          gestalten und unser Werbeangebot noch individueller an Ihre
          Bedürfnisse anpassen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?
        </h3>
        <p>
          Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer
          Webseite eingebunden, um gewisse User-Aktionen besser analysieren zu
          können. Wenn Sie nun eine unserer Google Ads-Anzeigen anklicken, wird
          auf Ihrem Computer (meist im Browser) oder Mobilgerät das Cookie
          „Conversion“ von einer Google-Domain gespeichert. Cookies sind kleine
          Textdateien, die Informationen auf Ihrem Computer speichern.
        </p>
        <p>
          Hier die Daten der wichtigsten Cookies für das Conversion-Tracking von
          Google:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> Conversion
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ111240294-3
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert jede Conversion, die Sie auf unserer Seite
          machen, nachdem Sie über eine Google Ads zu uns gekommen sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gac
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Dies
          ist ein klassisches Google Analytics-Cookie und dient dem Erfassen
          verschiedener Handlungen auf unserer Webseite.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Das Cookie
          _gac scheint nur in Verbindung mit Google Analytics auf. Die oben
          angeführte Aufzählung hat keinen Anspruch auf Vollständigkeit, da
          Google für analytische Auswertung immer wieder auch andere Cookies
          verwendet.
        </p>
        <p>
          Sobald Sie eine Aktion auf unserer Webseite abschließen, erkennt
          Google das Cookie und speichert Ihre Handlung als sogenannte
          Conversion. Solange Sie auf unserer Webseite surfen und das Cookie
          noch nicht abgelaufen ist, erkennen wir und Google, dass Sie über
          unsere Google-Ads-Anzeige zu uns gefunden haben. Das Cookie wird
          ausgelesen und mit den Conversion-Daten zurück an Google Ads gesendet.
          Es ist auch möglich, dass noch andere Cookies zur Messung von
          Conversions verwendet werden. Das Conversion-Tracking von Google Ads
          kann mithilfe von Google Analytics noch verfeinert und verbessert
          werden. Bei Anzeigen, die Google an verschiedenen Orten im Web
          anzeigt, werden unter unserer Domain möglicherweise Cookies mit dem
          Namen “__gads” oder “_gac” gesetzt. Seit September 2017 werden diverse
          Kampagneninformationen von analytics.js mit dem _gac-Cookie
          gespeichert. Das Cookie speichert diese Daten, sobald Sie eine unserer
          Seiten aufrufen, für die die automatische Tag-Kennzeichnung von Google
          Ads eingerichtet wurde. Im Gegensatz zu Cookies, die für
          Google-Domains gesetzt werden, kann Google diese Conversion-Cookies
          nur lesen, wenn Sie sich auf unserer Webseite befinden. Wir erheben
          und erhalten keine personenbezogenen Daten. Wir bekommen von Google
          einen Bericht mit statistischen Auswertungen. So erfahren wir
          beispielsweise die Gesamtanzahl der User, die unsere Anzeige
          angeklickt haben und wir sehen, welche Werbemaßnahmen gut ankamen.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss
          darauf haben, wie Google die erhobenen Daten weiterverwendet. Laut
          Google werden die Daten verschlüsselt und auf sicheren Servern
          gespeichert. In den meisten Fällen laufen Conversion-Cookies nach 30
          Tagen ab und übermitteln keine personenbezogenen Daten. Die Cookies
          mit dem Namen „Conversion“ und „_gac“ (das in Verbindung mit Google
          Analytics zum Einsatz kommt) haben ein Ablaufdatum von 3 Monaten.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben die Möglichkeit am Conversion-Tracking von Google Ads nicht
          teilzunehmen. Wenn Sie das Cookie des Google Conversion-Tracking über
          Ihren Browser deaktivieren, blockieren Sie das Conversion-Tracking. In
          diesem Fall werden Sie in der Statistik des Tracking-Tools nicht
          berücksichtigt. Sie können die Cookie-Einstellungen in Ihrem Browser
          jederzeit verändern. Bei jedem Browser funktioniert dies etwas anders.
          Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
          verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
          Herunterladen und Installieren dieses Browser-Plug-ins auf{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/ads/answer/7395996"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/ads/answer/7395996
          </a>{" "}
          werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass
          Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
          verhindern, sondern nur die personalisierte Werbung.
        </p>
        <p>
          Durch die Zertifizierung für das amerikanische-europäische
          Datenschutzübereinkommen “Privacy Shield”, muss der amerikanische
          Konzern Google LLC die in der EU geltenden Datenschutzgesetze
          einhalten. Wenn Sie Näheres über den Datenschutz bei Google erfahren
          möchten, empfehlen wir die allgemeine Datenschutzerklärung von Google:{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Eingebettete Social Media Elemente Datenschutzerklärung
        </h2>
        <p>
          Wir binden auf unserer Webseite Elemente von Social Media Diensten ein
          um Bilder, Videos und Texte anzuzeigen.
          <br />
          Durch den Besuch von Seiten die diese Elemente darstellen, werden
          Daten von Ihrem Browser zum jeweiligen Social Media Dienst übertragen
          und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
          <br />
          Die folgenden Links führen Sie zu den Seiten der jeweiligen Social
          Media Dienste wo erklärt wird, wie diese mit Ihren Daten umgehen:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Instagram-Datenschutzrichtlinie:{" "}
            <a
              className="adsimple-111240294"
              href="https://help.instagram.com/519522125107875?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://help.instagram.com/519522125107875
            </a>
          </li>
          <li className="adsimple-111240294">
            Für YouTube gilt die Google Datenschutzerklärung:{" "}
            <a
              className="adsimple-111240294"
              href="https://policies.google.com/privacy?hl=de&tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://policies.google.com/privacy?hl=de
            </a>
          </li>
          <li className="adsimple-111240294">
            Facebook-Datenrichtline: 
            <a
              className="adsimple-111240294"
              href="https://www.facebook.com/about/privacy?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/about/privacy
            </a>
          </li>
          <li className="adsimple-111240294">
            Twitter Datenschutzrichtlinie: 
            <a
              className="adsimple-111240294"
              href="https://twitter.com/de/privacy?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://twitter.com/de/privacy
            </a>
          </li>
        </ul>
        <h2 className="adsimple-111240294">Facebook Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf unserer Webseite ausgewählte Facebook Tools von
          Facebook. Facebook ist ein Social Media Network des Unternehmens
          Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
          Dublin 2 Ireland. Mithilfe dieser Tools können wir Ihnen und Menschen,
          die sich für unsere Produkte und Dienstleistungen interessieren, das
          bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick
          über die verschiedenen Facebook Tools, welche Daten an Facebook
          gesendet werden und wie Sie diese Daten löschen können.
        </p>
        <h3 className="adsimple-111240294">Was sind Facebook-Tools?</h3>
        <p>
          Neben vielen anderen Produkten bietet Facebook auch die sogenannten
          “Facebook Business Tools” an. Das ist die offizielle Bezeichnung von
          Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür
          entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden
          sich unter anderem:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Facebook-Pixel</li>
          <li className="adsimple-111240294">
            soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)
          </li>
          <li className="adsimple-111240294">Facebook Login</li>
          <li className="adsimple-111240294">Account Kit</li>
          <li className="adsimple-111240294">
            APIs (Programmierschnittstelle)
          </li>
          <li className="adsimple-111240294">
            SDKs (Sammlung von Programmierwerkzeugen)
          </li>
          <li className="adsimple-111240294">Plattform-Integrationen</li>
          <li className="adsimple-111240294">Plugins</li>
          <li className="adsimple-111240294">Codes</li>
          <li className="adsimple-111240294">Spezifikationen</li>
          <li className="adsimple-111240294">Dokumentationen</li>
          <li className="adsimple-111240294">
            Technologien und Dienstleistungen
          </li>
        </ul>
        <p>
          Durch diese Tools erweitert Facebook Dienstleistungen und hat die
          Möglichkeit, Informationen über User-Aktivitäten außerhalb von
          Facebook zu erhalten.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Facebook-Tools auf unserer Webseite?
        </h3>
        <p>
          Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen,
          die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen
          (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den
          Usern passende Werbung gezeigt werden kann, benötigt Facebook
          allerdings Informationen über die Wünsche und Bedürfnisse der
          Menschen. So werden dem Unternehmen Informationen über das
          Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung
          gestellt. Dadurch sammelt Facebook bessere User-Daten und kann
          interessierten Menschen die passende Werbung über unsere Produkte bzw.
          Dienstleistungen anzeigen. Die Tools ermöglichen somit
          maßgeschneiderte Werbekampagnen auf Facebook.
        </p>
        <p>
          Daten über Ihr Verhalten auf unserer Webseite nennt Facebook
          „Event-Daten“. Diese werden auch für Messungs- und Analysedienste
          verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“
          über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen
          wir durch Analysen einen besseren Einblick, wie Sie unsere
          Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren
          wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer
          Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte
          auf unserer Seite direkt auf Facebook teilen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Facebook-Tools gespeichert?
        </h3>
        <p>
          Durch die Nutzung einzelner Facebook-Tools können personenbezogene
          Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den
          benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer
          und IP-Adresse versandt werden.
        </p>
        <p>
          Facebook verwendet diese Informationen, um die Daten mit den ihren
          eigenen Daten, die es von Ihnen hat (sofern Sie Facebook-Mitglied
          sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden,
          erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig
          großer Datensatz in eine Zeichenkette transformiert wird. Dies dient
          auch der Verschlüsselung von Daten.
        </p>
        <p>
          Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter
          „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf
          unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie
          besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die
          erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise
          Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung
          oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit
          Kontaktdaten verbunden werden. Dadurch kann Facebook bessere
          personalisierte Werbung anbieten. Nach dem bereits erwähnten
          Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.
        </p>
        <p>
          Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook
          die Event-Daten nur, wenn diese mit anderen Daten (die auf andere
          Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese
          Event-Daten nützt Facebook auch für Sicherheits-, Schutz-,
          Entwicklungs- und Forschungszwecken. Viele dieser Daten werden über
          Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die
          zum Speichern von Daten bzw. Informationen in Browsern verwendet
          werden. Je nach verwendeten Tools und abhängig, ob Sie
          Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem
          Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools
          gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine
          Informationen über die Verwendung von Facebook-Cookies erfahren Sie
          auch auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policies/cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policies/cookies
          </a>
          .
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die
          eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat
          auf der ganzen Welt Server verteilt, wo Ihre Daten gespeichert werden.
          Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten
          abgeglichen wurden, innerhalb von 48 Stunden gelöscht.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf
          Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.
        </p>
        <p>
          Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr
          Facebook-Konto vollständig löschen. Und so funktioniert das Löschen
          Ihres Facebook-Kontos:
        </p>
        <p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
        <p>
          2) Anschließend klicken Sie in der linken Spalte auf „Deine
          Facebook-Informationen“.
        </p>
        <p>3) Nun klicken Sie “Deaktivierung und Löschung”.</p>
        <p>
          4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter
          und Konto löschen“
        </p>
        <p>
          5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann
          auf „Konto löschen“
        </p>
        <p>
          Die Speicherung der Daten, die Facebook über unsere Seite erhält,
          erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In
          Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren,
          löschen oder verwalten. Je nach dem welchen Browser Sie verwenden,
          funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
          Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie es erlauben oder nicht.
        </p>
        <p>
          Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework,
          wodurch der korrekte und sichere Datentransfer persönlicher Daten
          geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC
          </a>
          . Wir hoffen wir haben Ihnen die wichtigsten Informationen über die
          Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht.
          Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten
          verwendet, empfehlen wir Ihnen die Datenrichtlinien auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/update
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Facebook Login Datenschutzerklärung
        </h2>
        <p>
          Wir haben auf unserer Seite das praktische Facebook Login integriert.
          So können Sie sich bei uns ganz einfach mit Ihrem Facebook-Konto
          einloggen, ohne ein weiteres Benutzerkonto anlegen zu müssen. Wenn Sie
          sich entscheiden, Ihre Registrierung über das Facebook Login zu
          machen, werden Sie auf das Social Media Network Facebook
          weitergeleitet. Dort erfolgt die Anmeldung über Ihre Facebook
          Nutzerdaten. Durch dieses Login-Verfahren werden Daten über Sie bzw.
          Ihr Userverhalten gespeichert und an Facebook übermittelt.
        </p>
        <p>
          Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im
          Folgenden zeigen wir Ihnen die wichtigsten Cookies, die in Ihrem
          Browser gesetzt werden bzw. schon bestehen, wenn Sie sich über das
          Facebook Login auf unserer Seite anmelden:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0jieyh4c2GnlufEJ9..Bde09j…1.0.Bde09j
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit das sozialen Plugin auf unserer
          Webseite bestmöglich funktioniert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> datr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          4Jh7XUA2111240294SEmPsSfzCOO4JFFl
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Facebook setzt das “datr”-Cookie, wenn ein Webbrowser auf facebook.com
          zugreift, und das Cookie hilft, Anmeldeaktivitäten zu identifizieren
          und die Benutzer zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _js_datr
          <br />
          <strong className="adsimple-111240294">Wert:</strong> deleted
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Session-Cookie setzt Facebook zu Trackingzwecken, auch wenn Sie
          kein Facebook-Konto haben oder ausgeloggt sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Die
          angeführten Cookies sind nur eine kleine Auswahl der Cookies, die
          Facebook zur Verfügung stehen. Weitere Cookies sind beispielsweise _
          fbp, sb oder wd. Eine vollständige Aufzählung ist nicht möglich, da
          Facebook über eine Vielzahl an Cookies verfügt und diese variabel
          einsetzt.
        </p>
        <p>
          Der Facebook Login bietet Ihnen einerseits einen schnellen und
          einfachen Registrierungsprozess, andererseits haben wir so die
          Möglichkeit Daten mit Facebook zu teilen. Dadurch können wir unser
          Angebot und unsere Werbeaktionen besser an Ihre Interessen und
          Bedürfnisse anpassen. Daten, die wir auf diese Weise von Facebook
          erhalten, sind öffentliche Daten wie
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Ihr Facebook-Name</li>
          <li className="adsimple-111240294">Ihr Profilbild</li>
          <li className="adsimple-111240294">
            eine hinterlegte E-Mail-Adresse
          </li>
          <li className="adsimple-111240294">Freundeslisten</li>
          <li className="adsimple-111240294">
            Buttons-Angaben (z.B. „Gefällt mir“-Button)
          </li>
          <li className="adsimple-111240294">Geburtstagsdatum</li>
          <li className="adsimple-111240294">Sprache</li>
          <li className="adsimple-111240294">Wohnort</li>
        </ul>
        <p>
          Im Gegenzug stellen wir Facebook Informationen über Ihre Aktivitäten
          auf unserer Webseite bereit. Das sind unter anderem Informationen über
          Ihr verwendetes Endgerät, welche Unterseiten Sie bei uns besuchen oder
          welche Produkte Sie bei uns erworben haben.
        </p>
        <p>
          Durch die Verwendung von Facebook Login willigen Sie der
          Datenverarbeitung ein. Sie können diese Vereinbarung jederzeit
          widerrufen. Wenn Sie mehr Informationen über die Datenverarbeitung
          durch Facebook erfahren wollen, empfehlen wir Ihnen die
          Facebook-Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://de-de.facebook.com/policy.php?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/policy.php
          </a>
          .
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern.
        </p>
        <h2 className="adsimple-111240294">
          Facebook Soziale Plug-ins Datenschutzerklärung
        </h2>
        <p>
          Auf unserer Webseite sind sogenannte soziale Plug-ins des Unternehmens
          Facebook Inc. eingebaut. Sie erkennen diese Buttons am klassischen
          Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand mit erhobenem
          Daumen) oder an einer eindeutigen „Facebook Plug-in“-Kennzeichnung.
          Ein soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere
          Seite integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am
          meisten verwendeten Funktionen sind die bekannten “Gefällt mir”- und
          “Teilen”-Buttons.
        </p>
        <p>Folgende soziale Plug-ins werden von Facebook angeboten:</p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">“Speichern”-Button</li>
          <li className="adsimple-111240294">
            “Gefällt mir”-Button, Teilen, Senden und Zitat
          </li>
          <li className="adsimple-111240294">Seiten-Plug-in</li>
          <li className="adsimple-111240294">Kommentare</li>
          <li className="adsimple-111240294">Messenger-Plug-in</li>
          <li className="adsimple-111240294">
            Eingebettete Beiträge und Videoplayer
          </li>
          <li className="adsimple-111240294">Gruppen-Plug-in</li>
        </ul>
        <p>
          Auf{" "}
          <a
            className="adsimple-111240294"
            href="https://developers.facebook.com/docs/plugins"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.facebook.com/docs/plugins
          </a>{" "}
          erhalten Sie nähere Informationen, wie die einzelnen Plug-ins
          verwendet werden. Wir nützen die sozialen Plug-ins einerseits, um
          Ihnen ein besseres Usererlebnis auf unserer Seite zu bieten,
          andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren
          kann.
        </p>
        <p>
          Sofern Sie ein Facebook-Konto haben oder{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            facebook.com
          </a>{" "}
          schon mal besucht haben, hat Facebook bereits mindestens ein Cookie in
          Ihrem Browser gesetzt. In diesem Fall sendet Ihr Browser über dieses
          Cookie Informationen an Facebook, sobald Sie unsere Seite besuchen
          bzw. mit sozialen Plug-ins (z.B. dem „Gefällt mir“-Button)
          interagieren.
        </p>
        <p>
          Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder
          gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten Ihre
          IP-Adresse, welche Webseite Sie besucht haben, das Datum, die Uhrzeit
          und weitere Informationen, die Ihren Browser betreffen.
        </p>
        <p>
          Um zu verhindern, dass Facebook während Ihres Besuches auf unserer
          Webseite viele Daten sammelt und mit den Facebook-Daten verbindet,
          müssen Sie sich während des Webseitenbesuchs von Facebook abmelden
          (ausloggen).
        </p>
        <p>
          Falls Sie bei Facebook nicht angemeldet sind oder kein Facebook-Konto
          besitzen, sendet Ihr Browser weniger Informationen an Facebook, weil
          Sie weniger Facebook-Cookies haben. Dennoch können Daten wie
          beispielsweise Ihre IP-Adresse oder welche Webseite Sie besuchen an
          Facebook übertragen werden. Wir möchten noch ausdrücklich darauf
          hinweisen, dass wir über die genauen Inhalte der Daten nicht exakt
          Bescheid wissen. Wir versuchen aber Sie nach unserem aktuellen
          Kenntnisstand so gut als möglich über die Datenverarbeitung
          aufzuklären. Wie Facebook die Daten nutzt, können Sie auch in den
          Datenrichtline des Unternehmens unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/update
          </a>{" "}
          nachlesen.
        </p>
        <p>
          Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn Sie
          eine Webseite mit sozialen Plug-ins von Facebook besuchen:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> dpr
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit die sozialen Plug-ins auf unserer
          Webseite funktionieren.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0jieyh4111240294c2GnlufEJ9..Bde09j…1.0.Bde09j
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Auch
          das Cookie ist nötig, dass die Plug-ins einwandfrei funktionieren.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum::</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Cookies wurden nach einem Test gesetzt, auch wenn Sie nicht
          Facebook-Mitglied sind.
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>
          grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
          haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
          aktivieren.
        </p>
        <p>
          Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
          empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policy.php?tip=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">Instagram Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Webseite Funktionen von Instagram eingebaut.
          Instagram ist eine Social Media Plattform des Unternehmens Instagram
          LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012
          ein Tochterunternehmen von Facebook Inc. und gehört zu den
          Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer
          Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie
          Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite
          zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine
          Instagram-Funktion integriert haben, werden Daten an Instagram
          übermittelt, gespeichert und verarbeitet. Instagram verwendet
          dieselben Systeme und Technologien wie Facebook. Ihre Daten werden
          somit über alle Facebook-Firmen hinweg verarbeitet.
        </p>
        <p>
          Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum
          Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie
          die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu
          Facebook Inc. gehört, beziehen wir unsere Informationen einerseits von
          den Instagram-Richtlinien, andererseits allerdings auch von den
          Facebook-Datenrichtlinien selbst.
        </p>
        <h3 className="adsimple-111240294">Was ist Instagram?</h3>
        <p>
          Instagram ist eines der bekanntesten Social Media Netzwerken weltweit.
          Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von
          audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf
          „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und
          kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch
          in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht
          aktiv sein wollen, können Sie auch nur anderen interessante Users
          folgen.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Instagram auf unserer Webseite?
        </h3>
        <p>
          Instagram ist jene Social Media Plattform, die in den letzten Jahren
          so richtig durch die Decke ging. Und natürlich haben auch wir auf
          diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite
          so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche
          Aufbereitung unserer Inhalte selbstverständlich. Durch die
          eingebetteten Instagram-Funktionen können wir unseren Content mit
          hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt
          bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist,
          können uns die erhobenen Daten auch für personalisierte Werbung auf
          Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen,
          die sich wirklich für unsere Produkte oder Dienstleistungen
          interessieren.
        </p>
        <p>
          Instagram nützt die gesammelten Daten auch zu Messungs- und
          Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr
          Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen,
          dass diese Berichte Sie nicht persönlich identifizieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Instagram gespeichert?
        </h3>
        <p>
          Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie
          Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser
          automatisch mit den Servern von Instagram in Verbindung. Dabei werden
          Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar
          unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen
          Informationen über unserer Webseite, über Ihren Computer, über
          getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser
          Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer
          Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto
          haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten
          über Sie.
        </p>
        <p>
          Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen
          davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten
          sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese
          Kundendaten werden erst an Instagram übermittelt werden, wenn Sie
          zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine
          Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten
          verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“
          übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch
          Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass
          Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen
          Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen
          hat, abgeglichen.
        </p>
        <p>
          Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt
          werden, werden die gesammelten Daten an Facebook übermittelt. Je nach
          verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto
          haben, werden unterschiedlich viele Daten gespeichert.
        </p>
        <p>
          Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich
          funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein
          Instagram-Konto haben oder{" "}
          <a
            className="adsimple-111240294"
            href="http://www.instagram.com?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.instagram.com
          </a>{" "}
          besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das
          der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram,
          sobald Sie mit einer Instagram-Funktion in Berührung kommen.
          Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder
          gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der
          Datenverarbeitung von Instagram beschäftigt haben, können wir nicht
          ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.
        </p>
        <p>
          Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens
          gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button
          oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus,
          dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt
          sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.
        </p>
        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>csrftoken
          <br />
          <strong className="adsimple-111240294">Wert: </strong>“”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen
          gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten
          wir das allerdings nicht in Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>mid
          <br />
          <strong className="adsimple-111240294">Wert: </strong>“”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und
          Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt
          eine eindeutige User-ID fest.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          fbsr_111240294124024
          <br />
          <strong className="adsimple-111240294">Wert: </strong>keine Angaben
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.
          <strong className="adsimple-111240294">
            <br />
          </strong>
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> rur
          <br />
          <strong className="adsimple-111240294">Wert: </strong>ATN
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität
          auf Instagram gewährleistet.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> urlgen
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          {"“{”194.96.75.33”: 1901}"}:1iEtYv:Y833k2_UjKvXgYe111240294”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie dient den Marketingzwecken von Instagram.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Wir können
          hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im
          individuellen Fall gesetzt werden, hängt von den eingebetteten
          Funktionen und Ihrer Verwendung von Instagram ab.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Instagram teilt die erhaltenen Informationen zwischen den
          Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen
          Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter
          Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem
          aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt
          verteilt. Die meisten dieser Server stehen in den USA.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft,
          Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den
          Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre
          Daten auf Instagram völlig löschen wollen, müssen Sie Ihr
          Instagram-Konto dauerhaft löschen.
        </p>
        <p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
        <p>
          Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie
          nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf
          die Webseite des Unternehmens. Klicken Sie auf der Webseite auf
          „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.
        </p>
        <p>
          Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie
          beispielsweise Ihre Fotos und Status-Updates. Informationen, die
          andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto
          und werden folglich nicht gelöscht.
        </p>
        <p>
          Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster
          Linie über Cookies. Diese Cookies können Sie in Ihrem Browser
          verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser
          funktioniert die Verwaltung immer ein bisschen anders. Hier zeigen wir
          Ihnen die Anleitungen der wichtigsten Browser.
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie
          immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann
          können Sie immer individuell entscheiden, ob Sie das Cookie zulassen
          wollen oder nicht.
        </p>
        <p>
          Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook
          ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework. Dieses
          Framework stellt eine korrekte Datenübertragung zwischen den USA und
          der Europäischen Union sicher. Unter 
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC 
          </a>
           erfahren Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten
          Informationen über die Datenverarbeitung durch Instagram
          näherzubringen. Auf{" "}
          <a
            className="adsimple-111240294"
            href="https://help.instagram.com/519522125107875"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://help.instagram.com/519522125107875
          </a>
          <br />
          können Sie sich noch näher mit den Datenrichtlinien von Instagram
          auseinandersetzen.
        </p>
        <h2 className="adsimple-111240294">YouTube Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Webseite YouTube-Videos eingebaut. So können wir
          Ihnen interessante Videos direkt auf unserer Seite präsentieren.
          YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von
          Google LLC ist. Betrieben wird das Videoportal durch YouTube, LLC, 901
          Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Webseite
          eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet
          sich Ihr Browser automatisch mit den Servern von YouTube bzw. Google.
          Dabei werden (je nach Einstellungen) verschiedene Daten übertragen.
          Für die gesamte Datenverarbeitung ist Google verantwortlich und es
          gilt somit auch der Datenschutz von Google.
        </p>
        <p>
          Im Folgenden wollen wir Ihnen genauer erklären, welche Daten
          verarbeitet werden, warum wir YouTube-Videos eingebunden haben und wie
          Sie Ihre Daten verwalten oder löschen können.
        </p>
        <h3 className="adsimple-111240294">Was ist YouTube?</h3>
        <p>
          Auf YouTube können die User kostenlos Videos ansehen, bewerten,
          kommentieren und selbst hochladen. Über die letzten Jahre wurde
          YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit
          wir Videos auf unserer Webseite anzeigen können, stellt YouTube einen
          Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut
          haben.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir YouTube-Videos auf unserer Webseite?
        </h3>
        <p>
          YouTube ist die Videoplattform mit den meisten Besuchern und dem
          besten Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung
          auf unserer Webseite zu bieten. Und natürlich dürfen interessante
          Videos dabei nicht fehlen. Mithilfe unserer eingebetteten Videos
          stellen wir Ihnen neben unseren Texten und Bildern weiteren
          hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der
          Google-Suchmaschine durch die eingebetteten Videos leichter gefunden.
          Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google –
          dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen
          zeigen, die sich für unsere Angebote interessieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von YouTube gespeichert?
        </h3>
        <p>
          Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video
          eingebaut hat, setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse
          und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt
          sind, kann YouTube Ihre Interaktionen auf unserer Webseite meist
          mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie
          Sitzungsdauer, Absprungrate, ungefährer Standort, technische
          Informationen wie Browsertyp, Bildschirmauflösung oder Ihr
          Internetanbieter. Weitere Daten können Kontaktdaten, etwaige
          Bewertungen, das Teilen von Inhalten über Social Media oder das
          Hinzufügen zu Ihren Favoriten auf YouTube sein.
        </p>
        <p>
          Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto
          angemeldet sind, speichert Google Daten mit einer eindeutigen Kennung,
          die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt
          beispielsweise Ihre bevorzugte Spracheinstellung beibehalten. Aber
          viele Interaktionsdaten können nicht gespeichert werden, da weniger
          Cookies gesetzt werden.
        </p>
        <p>
          In der folgenden Liste zeigen wir Cookies, die in einem Test im
          Browser gesetzt wurden. Wir zeigen einerseits Cookies, die ohne
          angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir
          Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann
          keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von
          den Interaktionen auf YouTube abhängen.
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> YSC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          b9-CV6ojI5Y111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert eine eindeutige ID, um Statistiken des
          gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> PREF
          <br />
          <strong className="adsimple-111240294">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt
          über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
          verwenden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> GPS
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um
          den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 95Chz8bagyU
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten
          (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monaten
        </p>
        <p>
          Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto
          angemeldet sind:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> APISID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          zILlvClZSkqGsSwI/AU1aZI6HY7111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie speichert den Status der Zustimmung eines Users zur Nutzung
          unterschiedlicher Services von Google. CONSENT dient auch der
          Sicherheit, um User zu überprüfen und Userdaten vor unbefugten
          Angriffen zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 19
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> HSID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AcRwpgUik9Dveht0I
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu
          können.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> LOGIN_INFO
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AFmmF2swRQIhALl6aL…
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> In
          diesem Cookie werden Informationen über Ihre Login-Daten gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SAPISID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Gerät
          eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre
          Interessen zu erstellen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          oQfNKjAsI111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten
          Anmeldezeitpunkt in digital signierter und verschlüsselter Form.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SIDCC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AN0-TYuqub2JOcDTyL
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und
          welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise
          gesehen haben.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den
          Google-Servern gespeichert. Die meisten dieser Server befinden sich in
          Amerika. Unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>{" "}
           sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten
          sind auf den Servern verteilt. So sind die Daten schneller abrufbar
          und vor Manipulation besser geschützt.
        </p>
        <p>
          Die erhobenen Daten speichert Google unterschiedlich lang. Manche
          Daten können Sie jederzeit löschen, andere werden automatisch nach
          einer begrenzten Zeit gelöscht und wieder andere werden von Google
          über längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine
          Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto
          gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen.
          Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie
          einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
          löschen.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit
          der 2019 eingeführten automatische Löschfunktion von Standort- und
          Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung –
          entweder 3 oder 18 Monate gespeichert und dann gelöscht.
        </p>
        <p>
          Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren
          Browser so konfigurieren, dass Cookies von Google gelöscht bzw.
          deaktiviert werden. Je nach dem welchen Browser Sie verwenden,
          funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
          Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie es erlauben oder nicht. Da YouTube ein
          Tochterunternehmen von Google ist, gibt es eine gemeinsame
          Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten
          erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de.
          </a>
        </p>
        <h2 className="adsimple-111240294">
          YouTube Abonnieren Button Datenschutzerklärung
        </h2>
        <p>
          Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl.
          „Subscribe-Button“) eingebaut. Sie erkennen den Button meist am
          klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in
          weißer Schrift die Wörter „Abonnieren“ oder „YouTube“ und links davon
          das weiße „Play-Symbol“. Der Button kann aber auch in einem anderen
          Design dargestellt sein.
        </p>
        <p>
          Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante
          oder spannende Videos. Mit dem eingebauten „Abonnieren-Button“ können
          Sie unseren Kanal direkt von unserer Webseite aus abonnieren und
          müssen nicht eigens die YouTube-Webseite aufrufen. Wir wollen Ihnen
          somit den Zugang zu unserem umfassenden Content so einfach wie möglich
          machen. Bitte beachten Sie, dass YouTube dadurch Daten von Ihnen
          speichern und verarbeiten kann.
        </p>
        <p>
          Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt
          YouTube – laut Google – mindestens ein Cookie. Dieses Cookie speichert
          Ihre IP-Adresse und unsere URL. Auch Informationen über Ihren Browser,
          Ihren ungefähren Standort und Ihre voreingestellte Sprache kann
          YouTube so erfahren. Bei unserem Test wurden folgende vier Cookies
          gesetzt, ohne bei YouTube angemeldet zu sein:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> YSC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          b9-CV6ojI5111240294Y
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert eine eindeutige ID, um Statistiken des
          gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> PREF
          <br />
          <strong className="adsimple-111240294">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt
          über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
          verwenden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> GPS
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um
          den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          11124029495Chz8bagyU
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten
          (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Cookies wurden nach einem Test gesetzt und können nicht den Anspruch
          auf Vollständigkeit erheben.
        </p>
        <p>
          Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele
          Ihrer Handlungen/Interaktionen auf unserer Webseite mit Hilfe von
          Cookies speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt
          dadurch zum Beispiel Informationen wie lange Sie auf unserer Seite
          surfen, welchen Browsertyp Sie verwenden, welche Bildschirmauflösung
          Sie bevorzugen oder welche Handlungen Sie ausführen.
        </p>
        <p>
          YouTube verwendet diese Daten zum einen um die eigenen
          Dienstleistungen und Angebote zu verbessern, zum anderen um Analysen
          und Statistiken für Werbetreibende (die Google Ads verwenden)
          bereitzustellen.
        </p>
        <h2 className="adsimple-111240294">Twitter Datenschutzerklärung</h2>
        <p>
          Auf unserer Webseite haben wir Funktionen von Twitter eingebaut. Dabei
          handelt es sich zum Beispiel um eingebettete Tweets, Timelines,
          Buttons oder Hashtags. Twitter ist ein Kurznachrichtendienst und eine
          Social-Media-Plattform der Firma Twitter Inc., One Cumberland Place,
          Fenian Street, Dublin 2 D02 AX07, Irland.
        </p>
        <p>
          Nach unserer Kenntnis werden im Europäischen Wirtschaftsraum und in
          der Schweiz durch das bloße Einbinden von Twitter-Funktion noch keine
          personenbezogenen Daten oder Daten zu Ihrer Webaktivitäten an Twitter
          übertragen. Erst wenn Sie mit den Twitter-Funktionen interagieren, wie
          zum Beispiel auf einen Button klicken, können Daten an Twitter
          gesendet, dort gespeichert und verarbeitet werden. Auf diese
          Datenverarbeitung haben wir keinen Einfluss und tragen keine
          Verantwortung. Im Rahmen dieser Datenschutzerklärung wollen wir Ihnen
          einen Überblick geben, welche Daten Twitter speichert, was Twitter mit
          diesen Daten macht und wie Sie sich vor der Datenübertragung
          weitgehend schützen können.
        </p>
        <h3 className="adsimple-111240294">Was ist Twitter?</h3>
        <p>
          Für die einen ist Twitter ein Nachrichtendienst, für andere eine
          Social-Media-Plattform und wieder andere sprechen von einem
          Microblogging-Dienst. All diese Bezeichnungen haben ihre Berechtigung
          und meinen mehr oder weniger dasselbe.
        </p>
        <p>
          Sowohl Privatpersonen als auch Unternehmen nützen Twitter, um mit
          interessierten Personen über Kurznachrichten zu kommunizieren. Pro
          Nachricht erlaubt Twitter nur 280 Zeichen. Diese Nachrichten werden
          „Tweets“ genannt. Anders als beispielsweise bei Facebook fokussiert
          sich der Dienst nicht auf den Ausbau eines Netzwerks für “Freunde”,
          sondern will als weltweite und offene Nachrichten-Plattform verstanden
          werden. Bei Twitter kann man auch ein anonymes Konto führen und Tweets
          können einerseits vom Unternehmen, andererseits von den Usern selbst
          gelöscht werden.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Twitter auf unserer Webseite?
        </h3>
        <p>
          Wie viele andere Webseiten und Unternehmen versuchen wir unserer
          Services und Dienstleistungen über verschiedene Kanäle anzubieten und
          mit unseren Kunden zu kommunizieren. Speziell Twitter ist uns als
          nützlicher „kleiner“ Nachrichtendienst ans Herz gewachsen. Immer
          wieder tweeten oder retweeten wir spannende, lustige oder interessante
          Inhalte. Uns ist klar, dass Sie nicht jeden Kanal extra folgen können.
          Schließlich haben Sie auch noch etwas anderes zu tun. Darum haben wir
          auf unserer Webseite auch Twitter-Funktionen eingebunden. Sie können
          unsere Twitter-Aktivität „vor Ort“ miterleben oder über einen direkten
          Link zu unserer Twitter-Seite kommen. Durch die Einbindung wollen wir
          unser Service und die Nutzerfreundlichkeit auf unserer Webseite
          stärken.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Twitter gespeichert?
        </h3>
        <p>
          Auf manchen unserer Unterseiten finden Sie die eingebauten
          Twitter-Funktionen. Wenn Sie mit den Twitter-Inhalten interagieren,
          wie zum Beispiel auf einen Button klicken, kann Twitter Daten erfassen
          und speichern. Und zwar auch dann, wenn Sie selbst kein Twitter-Konto
          haben. Twitter nennt diese Daten “Log-Daten”. Dazu zählen
          demografische Daten, Browser-Cookie-IDs, die ID Ihres Smartphones,
          gehashte E-Mail-Adressen, und Informationen, welche Seiten Sie bei
          Twitter besucht haben und welche Handlungen Sie ausgeführt haben.
          Twitter speichert natürlich mehr Daten, wenn Sie ein Twitter-Konto
          haben und angemeldet sind. Meistens passiert diese Speicherung über
          Cookies. Cookies sind kleine Text-Dateien, die meist in Ihrem Browser
          gesetzt werden und unterschiedliche Information an Twitter
          übermitteln.
        </p>
        <p>
          Welche Cookies gesetzt werden, wenn Sie nicht bei Twitter angemeldet
          sind, aber eine Webseite mit eingebauten Twitter-Funktionen besuchen,
          zeigen wir Ihnen jetzt. Bitte betrachten Sie diese Aufzählung als
          Beispiel. Einen Anspruch auf Vollständigkeit können wir hier auf
          keinen Fall gewährleisten, da sich die Wahl der Cookies stets
          verändert und von Ihren individuellen Handlungen mit den
          Twitter-Inhalten abhängt.
        </p>
        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          personalization_id
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          “v1_cSJIsogU51SeE111240294”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und
          über welche Werbung Sie möglicherweise zu Twitter gekommen sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>lang
          <br />
          <strong className="adsimple-111240294">Wert: </strong>de
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Ihre voreingestellte bzw. bevorzugte Sprache.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>guest_id
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          111240294v1%3A157132626
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird gesetzt, um Sie als Gast zu identifizieren.
          <strong className="adsimple-111240294"> </strong>
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>fm
          <br />
          <strong className="adsimple-111240294">Wert: </strong>0<br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Zu
          diesem Cookie konnten wir leider den Verwendungszweck nicht in
          Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>external_referer
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          1112402942beTA0sf5lkMrlGt
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie sammelt anonyme Daten, wie zum Beispiel wie oft Sie
          Twitter besuchen und wie lange Sie Twitter besuchen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>Nach 6
          Tage
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>eu_cn
          <br />
          <strong className="adsimple-111240294">Wert: </strong>1<br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Useraktivität und dient diversen Werbezwecken
          von Twitter.{" "}
          <strong className="adsimple-111240294">
            <br />
            Ablaufdatum:{" "}
          </strong>
          Nach einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>ct0
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          c1179f07163a365d2ed7aad84c99d966
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Zu
          diesem Cookie haben wir leider keine Informationen gefunden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 6
          Stunden
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>_twitter_sess
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          53D%253D–dd0248111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Mit
          diesem Cookie können Sie Funktionen innerhalb der Twitter-Webseite
          nutzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Twitter
          arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unsrem
          Test auch die drei Google-Analytics-Cookies _ga, _gat, _gid erkannt.
        </p>
        <p>
          Twitter verwendet die erhobenen Daten einerseits, um das Userverhalten
          besser zu verstehen und somit Ihre eigenen Dienste und Werbeangebote
          zu verbessern, andererseits dienen die Daten auch internen
          Sicherheitsmaßnahmen.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Wenn Twitter Daten von anderen Webseiten erhebt, werden diese nach
          maximal 30 Tagen gelöscht, zusammengefasst oder auf andere Weise
          verdeckt. Die Twitter-Server liegen auf verschiedenen Serverzentren in
          den Vereinigten Staaten. Demnach ist davon auszugehen, dass erhobenen
          Daten in Amerika gesammelt und gespeichert werden. Nach unserer
          Recherche konnten wir nicht eindeutig feststellen, ob Twitter auch
          eigene Server in Europa hat. Grundsätzlich kann Twitter die erhobenen
          Daten speichern, bis sie dem Unternehmen nicht mehr dienlich sind, Sie
          die Daten löschen oder eine gesetzliche Löschfrist besteht.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Twitter betont in Ihren Datenschutzrichtlinien immer wieder, dass sie
          keine Daten von externen Webseitenbesuchen speichert, wenn Sie bzw.
          Ihr Browser sich im europäischen Wirtschaftsraum oder in der Schweiz
          befinden. Falls Sie allerdings mit Twitter direkt interagieren,
          speichert Twitter selbstverständlich auch Daten von Ihnen.
        </p>
        <p>
          Wenn Sie ein Twitter-Konto besitzen, können Sie Ihre Daten verwalten,
          indem Sie unter dem „Profil“-Button auf „Mehr“ klicken. Anschließend
          klicken Sie auf „Einstellungen und Datenschutz“. Hier können Sie die
          Datenverarbeitung individuell verwalten.
        </p>
        <p>
          Wenn Sie kein Twitter-Konto besitzen, können Sie auf{" "}
          <a className="adsimple-111240294" href="https://twitter.com/">
            twitter.com
          </a>
           gehen und dann auf „Individualisierung“ klicken. Unter dem Punkt
          „Individualisierung und Daten“ können Sie Ihre erhobenen Daten
          verwalten.
        </p>
        <p>
          Die meisten Daten werden, wie oben bereits erwähnt, über Cookies
          gespeichert und die können Sie in Ihrem Browser verwalten,
          deaktivieren oder löschen. Bitte beachten Sie, dass Sie die Cookies
          nur in dem von Ihnen gewählten Browser “bearbeiten”. Das heißt:
          verwenden Sie in Zukunft einen anderen Browser, müssen Sie dort Ihre
          Cookies erneut nach Ihren Wünschen verwalten. Hier gibt es die
          Anleitung zur Cookie-Verwaltung der bekanntesten Browser.
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Ihren Browser können Sie auch so verwalten, dass Sie bei jedem
          einzelnen Cookie informiert werden. Dann können Sie immer individuell
          entscheiden, ob Sie ein Cookie zulassen oder nicht.
        </p>
        <p>
          Twitter verwendet die Daten auch für personalisierte Werbung in- und
          außerhalb von Twitter. In den Einstellungen können Sie unter
          „Individualisierung und Daten“ die personalisierte Werbung abschalten.
          Wenn Sie Twitter auf einem Browser nutzen, können Sie die
          personalisierte Werbung unter{" "}
          <a
            className="adsimple-111240294"
            href="http://optout.aboutads.info/?c=2&lang=EN"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://optout.aboutads.info/?c=2&lang=EN
          </a>{" "}
          deaktivieren.
        </p>
        <p>
          Twitter ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework.
          Dieses Framework stellt eine korrekte Datenübertragung zwischen den
          USA und der Europäischen Union sicher. Unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO
          </a>
           erfahren Sie mehr darüber.
        </p>
        <p>
          Wir hoffen, wir haben Ihnen einen grundsätzlichen Überblick über die
          Datenverarbeitung durch Twitter gegeben. Wir erhalten keinen Daten von
          Twitter und tragen auch keine Verantwortung darüber, was Twitter mit
          Ihren Daten macht. Falls Sie noch weitere Fragen zu diesem Thema
          haben, empfehlen wir Ihnen die Twitter-Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://twitter.com/de/privacy"
          >
            https://twitter.com/de/privacy
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">Gravatar Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf dieser Webseite Gravatar der Firma Automattic Inc.
          (60 29th Street #343, San Francisco, CA 94110, USA) um Benutzerbilder
          (Avatar) auf Basis der E-Mail-Adresse bei Beiträgen und Kommentaren
          zuzuordnen.
        </p>
        <p>
          Ein Gravatar ist ein <strong className="adsimple-111240294">G</strong>
          lobally <strong className="adsimple-111240294">
            R
          </strong>ecognized{" "}
          <strong className="adsimple-111240294">Avatar </strong>– ein weltweit
          verfügbares Benutzerbild – welches einer E-Mail-Adresse zugeordnet und
          bei deren Verwendung in verschiedenen Onlinediensten zum Einsatz
          kommen kann.
          <br />
          Da nur beim Einsatz einer bei Gravatar registrierten E-Mail-Adresse
          ein Bild angezeigt wird und somit Daten zu Gravatar übertragen werden,
          können Sie die Übertragung Ihrer Daten zu Gravatar verhindern, indem
          Sie mit einer bei Gravatar nicht registrierten E-Mail-Adresse
          kommentieren oder Beiträge verfassen.
        </p>
        <p>
          Automattic ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
          Framework wodurch der korrekte und sichere Datentransfer persönlicher
          Daten geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC
          </a>{" "}
          .<br />
          Mehr Details zur Datenschutzrichtlinie und welche Daten auf welche Art
          durch Gravatar erfasst werden finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://automattic.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://automattic.com/privacy/
          </a>
          , allgemeine Informationen zu Gravatar auf{" "}
          <a
            className="adsimple-111240294"
            href="http://de.gravatar.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://de.gravatar.com/
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google reCAPTCHA Datenschutzerklärung
        </h2>
        <p>
          Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns
          bestmöglich zu sichern und zu schützen. Um das zu gewährleisten,
          verwenden wir Google reCAPTCHA der Firma Google Inc. (1600
          Amphitheatre Parkway Mountain View, CA 94043, USA). Mit reCAPTCHA
          können wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch
          und Blut sind und kein Roboter oder eine andere Spam-Software. Unter
          Spam verstehen wir jede, auf elektronischen Weg, unerwünschte
          Information, die uns ungefragter Weise zukommt. Bei den klassischen
          CAPTCHAS mussten Sie zur Überprüfung meist Text- oder Bildrätsel
          lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen
          Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie
          einfach ein Häkchen setzen und so bestätigen, dass Sie kein Bot sind.
          Mit der neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr
          ein Häkchen setzen. Wie das genau funktioniert und vor allem welche
          Daten dafür verwendet werden, erfahren Sie im Verlauf dieser
          Datenschutzerklärung.
        </p>
        <h3 className="adsimple-111240294">Was ist reCAPTCHA?</h3>
        <p>
          reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor
          Spam-Software und den Missbrauch durch nicht-menschliche Besucher
          schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie
          Formulare im Internet ausfüllen. Ein Captcha-Dienst ist eine Art
          automatischer Turing-Test, der sicherstellen soll, dass eine Handlung
          im Internet von einem Menschen und nicht von einem Bot vorgenommen
          wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan
          Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch
          fest. Bei Captchas übernimmt das auch der Computer bzw. ein
          Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben,
          die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche
          Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel
          mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen
          von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich
          bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst
          das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den
          Quelltext eingebunden und dann läuft das Tool im Hintergrund und
          analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet
          die Software einen sogenannten Captcha-Score. Google berechnet mit
          diesem Score schon vor der Captcha-Eingabe wie hoch die
          Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw.
          Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots
          gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.)
          manipulieren oder missbrauchen könnten.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir reCAPTCHA auf unserer Webseite?
        </h3>
        <p>
          Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite
          begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen
          getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns
          zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie
          anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma
          Google. So können wir uns ziemlich sicher sein, dass wir eine
          „botfreie“ Webseite bleiben. Durch die Verwendung von reCAPTCHA werden
          Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich
          ein Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite
          und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte
          es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot
          möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren
          oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA
          können wir solche Botangriffe vermeiden.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von reCAPTCHA gespeichert?
        </h3>
        <p>
          reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen,
          ob die Handlungen auf unserer Webseite auch wirklich von Menschen
          stammen. Es kann also die IP-Adresse und andere Daten, die Google für
          den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen
          werden innerhalb der Mitgliedstaaten der EU oder anderer
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          fast immer zuvor gekürzt, bevor die Daten auf einem Server in den USA
          landen. Die IP-Adresse wird nicht mit anderen Daten von Google
          kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit
          Ihrem Google-Konto angemeldet sind. Zuerst prüft der
          reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von
          anderen Google-Diensten (YouTube. Gmail usw.) platziert sind.
          Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser
          und erfasst einen Schnappschuss Ihres Browserfensters.
        </p>
        <p>
          Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht
          den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von
          Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Referrer URL (die Adresse der Seite von der der Besucher kommt)
          </li>
          <li className="adsimple-111240294">
            IP-Adresse (z.B. 256.123.123.1)
          </li>
          <li className="adsimple-111240294">
            Infos über das Betriebssystem (die Software, die den Betrieb Ihres
            Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS
            X oder Linux)
          </li>
          <li className="adsimple-111240294">
            Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
          </li>
          <li className="adsimple-111240294">
            Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder
            der Tastatur ausführen wird gespeichert)
          </li>
          <li className="adsimple-111240294">
            Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie
            auf Ihrem PC voreingestellt haben wird gespeichert)
          </li>
          <li className="adsimple-111240294">
            Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die
            Webseiten ermöglicht, sich an den User anzupassen.
            JavaScript-Objekte können alle möglichen Daten unter einem Namen
            sammeln)
          </li>
          <li className="adsimple-111240294">
            Bildschirmauflösung (zeigt an aus wie vielen Pixeln die
            Bilddarstellung besteht)
          </li>
        </ul>
        <p>
          Unumstritten ist, dass Google diese Daten verwendet und analysiert
          noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der
          Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der
          ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche
          Daten Google genau speichert, erfährt man von Google nicht im Detail.
        </p>
        <p>
          Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir
          uns auf die reCAPTCHA Demo-Version von Google unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/recaptcha/api2/demo"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/recaptcha/api2/demo
          </a>
          . All diese Cookies benötigen zu Trackingzwecken eine eindeutige
          Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der
          Demo-Version gesetzt hat:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> IDE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-111240294-8
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird von der Firma DoubleClick (gehört auch Google)
          gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit
          Werbeanzeigen zu registrieren und zu melden. So kann die
          Werbewirksamkeit gemessen und entsprechende Optimierungsmaßnahmen
          getroffen werden. IDE wird in Browsern unter der Domain
          doubleclick.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> 1P_JAR
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 2019-5-14-12
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie sammelt Statistiken zur Webseite-Nutzung und misst
          Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem
          Käufer wird. Das Cookie wird auch verwendet, um Usern relevante
          Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie vermeiden,
          dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> ANID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           U7j1v3dZa1112402940xgZFmiqWppRWKOr
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Viele Infos konnten wir über dieses Cookie nicht in Erfahrung bringen.
          In der Datenschutzerklärung von Google wird das Cookie im Zusammenhang
          mit „Werbecookies“ wie z. B. “DSID”, “FLC”, “AID”, “TAID” erwähnt.
          ANID wird unter Domain google.com gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 9
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie speichert den Status der Zustimmung eines Users zur Nutzung
          unterschiedlicher Services von Google. CONSENT dient auch der
          Sicherheit, um User zu überprüfen, Betrügereien von
          Anmeldeinformationen zu verhindern und Userdaten vor unbefugten
          Angriffen zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 19
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> NID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0WmuWqy111240294zILzqV_nmt3sDXwPeM5Q
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> NID
          wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche
          anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist
          eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen.
          So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie
          enthält eine einzigartige ID, um persönliche Einstellungen des Users
          für Werbezwecke zu sammeln.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 6
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> DV
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          gEAABBCjJMXcI0dSAAAANbqc111240294-4
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Sobald Sie das „Ich bin kein Roboter“-Häkchen angekreuzt haben, wird
          dieses Cookie gesetzt. Das Cookie wird von Google Analytics für
          personalisierte Werbung verwendet. DV sammelt Informationen in
          anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen
          zu treffen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 10
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung: </strong>Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den
          Google-Server übertragen. Wo genau diese Daten gespeichert werden,
          stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar.
          Ohne eine Bestätigung von Google erhalten zu haben, ist davon
          auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der
          Webseite oder Spracheinstellungen auf den europäischen oder
          amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die
          Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit
          anderen Google-Daten aus weiteren Google-Diensten zusammengeführt.
          Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei
          Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt.
          <strong className="adsimple-111240294"> </strong>Dafür gelten die
          abweichenden Datenschutzbestimmungen der Firma Google.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an
          Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite
          besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen
          ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die
          Daten sobald Sie unsere Seite aufrufen automatisch an Google
          übermittelt. Um diese Daten wieder zu löschen, müssen Sie den
          Google-Support auf {" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/?hl=de&tid=111240294"
          >
            https://support.google.com/?hl=de&tid=111240294
          </a>{" "}
          kontaktieren.
        </p>
        <p>
          Wenn Sie also unsere Webseite verwenden, erklären Sie sich
          einverstanden, dass Google LLC und deren Vertreter automatisch Daten
          erheben, bearbeiten und nutzen.
        </p>
        <p>
          Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von
          Google auf{" "}
          <a
            className="adsimple-111240294"
            href="https://developers.google.com/recaptcha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/recaptcha/
          </a>
          . Google geht hier zwar auf die technische Entwicklung der reCAPTCHA
          näher ein, doch genaue Informationen über Datenspeicherung und
          datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute
          Übersicht über die grundsätzliche Verwendung von Daten bei Google
          finden Sie in der hauseigenen Datenschutzerklärung auf{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>
          .
        </p>
        <p>
          Quelle: Erstellt mit dem Datenschutz Generator von{" "}
          <a
            title="Datenschutz Generator von adsimple.at"
            href="https://www.adsimple.at"
            target="_blank"
            rel="noopener noreferrer"
          >
            Content Marketing AdSimple
          </a>
           in Kooperation mit{" "}
          <a
            href="https://www.bauguide.at"
            target="_blank"
            rel="noopener noreferrer"
            title=""
          >
            bauguide.at
          </a>
        </p>
      </div>
    );
  }
  de() {
    return (
      <div>
        <br />
        <br />
        <h1 className="adsimple-111240294">Datenschutzerklärung</h1>
        <h2 className="adsimple-111240294">Datenschutz</h2>
        <p>
          Wir haben diese Datenschutzerklärung (Fassung 19.01.2020-111240294)
          verfasst, um Ihnen gemäß der Vorgaben der{" "}
          <a
            className="adsimple-111240294"
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutz-Grundverordnung (EU) 2016/679
          </a>{" "}
          zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden
          und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite
          haben.
        </p>
        <p>
          Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr
          technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die
          wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
        </p>
        <h2 className="adsimple-111240294">Automatische Datenspeicherung</h2>
        <p>
          Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
          automatisch erstellt und gespeichert, so auch auf dieser Webseite.
        </p>
        <p>
          Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
          Webserver (Computer auf dem diese Webseite gespeichert ist)
          automatisch Daten wie
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            die Adresse (URL) der aufgerufenen Webseite
          </li>
          <li className="adsimple-111240294">Browser und Browserversion</li>
          <li className="adsimple-111240294">das verwendete Betriebssystem</li>
          <li className="adsimple-111240294">
            die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
          </li>
          <li className="adsimple-111240294">
            den Hostname und die IP-Adresse des Geräts von welchem aus
            zugegriffen wird
          </li>
          <li className="adsimple-111240294">Datum und Uhrzeit</li>
        </ul>
        <p>in Dateien (Webserver-Logfiles).</p>
        <p>
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
          danach automatisch gelöscht. Wir geben diese Daten nicht weiter,
          können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
          rechtswidrigem Verhalten eingesehen werden.
        </p>
        <h2 className="adsimple-111240294">Speicherung persönlicher Daten</h2>
        <p>
          Persönliche Daten, die Sie uns auf dieser Website elektronisch
          übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
          andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars
          oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt
          und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher
          verwahrt und nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit
          jenen Besuchern, die Kontakt ausdrücklich wünschen und für die
          Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
          Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht
          weiter, können jedoch nicht ausschließen, dass diese Daten beim
          Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        </p>
        <p>
          Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits
          dieser Webseite – können wir keine sichere Übertragung und den Schutz
          Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
          niemals unverschlüsselt per E-Mail zu übermitteln.
        </p>
        <h2 className="adsimple-111240294">
          Rechte laut Datenschutzgrundverordnung
        </h2>
        <p>
          Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen{" "}
          <a
            className="adsimple-111240294"
            href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzgesetzes (DSG)
          </a>{" "}
          grundsätzlich die folgende Rechte zu:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Recht auf Berichtigung (Artikel 16 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit
            der Berichtigung oder Löschung personenbezogener Daten oder der
            Einschränkung der Verarbeitung (Artikel 19 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Widerspruchsrecht (Artikel 21 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht, nicht einer ausschließlich auf einer automatisierten
            Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
            unterworfen zu werden (Artikel 22 DSGVO)
          </li>
        </ul>
        <p>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
          Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
          sonst in einer Weise verletzt worden sind, können Sie sich bei der
          Aufsichtsbehörde beschweren, welche in Österreich die
          Datenschutzbehörde ist, deren Webseite Sie unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.dsb.gv.at/?tid=111240294"
          >
            https://www.dsb.gv.at/
          </a>{" "}
          finden.
        </p>
        <h2 className="adsimple-111240294">
          Auswertung des Besucherverhaltens
        </h2>
        <p>
          In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob
          und wie wir Daten Ihres Besuchs dieser Website auswerten. Die
          Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir
          können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person
          schließen.
        </p>
        <p>
          Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu
          widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
        </p>
        <h2 className="adsimple-111240294">TLS-Verschlüsselung mit https</h2>
        <p>
          Wir verwenden https um Daten abhörsicher im Internet zu übertragen
          (Datenschutz durch Technikgestaltung{" "}
          <a
            className="adsimple-111240294"
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Artikel 25 Absatz 1 DSGVO
          </a>
          ). Durch den Einsatz von TLS (Transport Layer Security), einem
          Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
          können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen
          die Benutzung dieser Absicherung der Datenübertragung am kleinen
          Schlosssymbol links oben im Browser und der Verwendung des Schemas
          https (anstatt http) als Teil unserer Internetadresse.
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf unserer Website das Analyse-Tracking Tool Google
          Analytics (GA) des amerikanischen Unternehmens Google LLC (1600
          Amphitheatre Parkway Mountain View, CA 94043, USA). Google Analytics
          sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie
          beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie
          gespeichert und an Google Analytics versandt. Mithilfe der Berichte,
          die wir von Google Analytics erhalten, können wir unsere Website und
          unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir
          näher auf das Tracking Tool ein und informieren Sie vor allem darüber,
          welche Daten gespeichert werden und wie Sie das verhindern können.
        </p>
        <h3 className="adsimple-111240294">Was ist Google Analytics?</h3>
        <p>
          Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse
          unserer Website dient. Damit Google Analytics funktioniert, wird ein
          Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
          Website besuchen, zeichnet dieser Code verschiedene Handlungen auf,
          die Sie auf unserer Website ausführen. Sobald Sie unsere Website
          verlassen, werden diese Daten an die Google-Analytics-Server gesendet
          und dort gespeichert.
        </p>
        <p>
          Google verarbeitet die Daten und wir bekommen Berichte über Ihr
          Userverhalten. Dabei kann es sich unter anderem um folgende Berichte
          handeln:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User
            besser kennen und wissen genauer, wer sich für unser Service
            interessiert.
          </li>
          <li className="adsimple-111240294">
            Anzeigeberichte: Durch Anzeigeberichte können wir unsere
            Onlinewerbung leichter analysieren und verbessern.
          </li>
          <li className="adsimple-111240294">
            Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche
            Informationen darüber, wie wir mehr Menschen für unser Service
            begeistern können.
          </li>
          <li className="adsimple-111240294">
            Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website
            interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer
            Seite zurücklegen und welche Links Sie anklicken.
          </li>
          <li className="adsimple-111240294">
            Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie
            aufgrund einer Marketing-Botschaft eine gewünschte Handlung
            ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher
            zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser
            Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen
            bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
          </li>
          <li className="adsimple-111240294">
            Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf
            unserer Website passiert. Zum Beispiel sehen wir wie viele User
            gerade diesen Text lesen.
          </li>
        </ul>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google Analytics auf unserer Webseite?
        </h3>
        <p>
          Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
          bestmögliche Service bieten. Die Statistiken und Daten von Google
          Analytics helfen uns dieses Ziel zu erreichen.
        </p>
        <p>
          Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den
          Stärken und Schwächen unserer Website. Einerseits können wir unsere
          Seite so optimieren, dass sie von interessierten Menschen auf Google
          leichter gefunden wird. Andererseits helfen uns die Daten, Sie als
          Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
          unserer Website verbessern müssen, um Ihnen das bestmögliche Service
          zu bieten. Die Daten dienen uns auch, unsere Werbe- und
          Marketing-Maßnahmen individueller und kostengünstiger durchzuführen.
          Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen
          Menschen zu zeigen, die sich dafür interessieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Google Analytics gespeichert?
        </h3>
        <p>
          Google Analytics erstellt mithilfe eines Tracking-Codes eine
          zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist.
          So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste
          Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User
          erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID
          gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile
          auszuwerten.
        </p>
        <p>
          Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre
          Interaktionen auf unserer Website gemessen. Interaktionen sind alle
          Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
          auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können
          über Google Analytics generierte Daten mit Drittanbieter-Cookies
          verknüpft werden. Google gibt keine Google Analytics-Daten weiter,
          außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es
          kommen, wenn es gesetzlich erforderlich ist.
        </p>
        <p>Folgende Cookies werden von Google Analytics verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _ga
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          2.1326744211.152111240294-5
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu
          speichern. Grundsätzlich dient es zur Unterscheidung der
          Webseitenbesucher.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          2.1687193234.152111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie dient auch zur Unterscheidung der Webseitenbesucher
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 24
          Stunden
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gat_gtag_UA_
          {"<property-id>"}
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Wird
          zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über
          den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den
          Namen _dc_gtm_ {"<property-id>"}.<br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 1
          Minute
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> AMP_TOKEN
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angaben
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst
          abgerufen werden kann. Andere mögliche Werte weisen auf eine
          Abmeldung, eine Anfrage oder einen Fehler hin.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Sekunden bis zu einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utma
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          1564498958.1564498958.1564498958.1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Mit
          diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und sie
          Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn
          Informationen an Google Analytics gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmt
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird wie _gat_gtag_UA_{"<property-id>"} zum Drosseln der
          Anforderungsrate verwendet.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 10
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmb
          <br />
          <strong className="adsimple-111240294">Wert:</strong>3.10.1564498958
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird
          jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics
          gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmc
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 167421564
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende
          Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und
          wird nur solange gespeichert, bis Sie den Browser wieder schließen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> Nach
          Schließung des Browsers
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmz
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          m|utmccn=(referral)|utmcmd=referral|utmcct=/
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird verwendet, um die Quelle des Besucheraufkommens auf
          unserer Website zu identifizieren. Das heißt, das Cookie speichert,
          von wo Sie auf unsere Website gekommen sind. Das kann eine andere
          Seite bzw. eine Werbeschaltung gewesen sein.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 6
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmv
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern.
          Es wird immer aktualisiert, wenn Informationen an Google Analytics
          gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <p>
          Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die
          mit Google Analytics erhoben werden:
        </p>
        <p>
          <strong className="adsimple-111240294">Heatmaps:</strong> Google legt
          sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche,
          die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer
          Seite „unterwegs“ sind.
        </p>
        <p>
          <strong className="adsimple-111240294">Sitzungsdauer:</strong> Als
          Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite
          verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv
          waren, endet die Sitzung automatisch.
        </p>
        <p>
          <strong className="adsimple-111240294">Absprungrate</strong> (engl.
          Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer
          Website nur eine Seite ansehen und dann unsere Website wieder
          verlassen.
        </p>
        <p>
          <strong className="adsimple-111240294">Kontoerstellung:</strong> Wenn
          Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung
          machen, erhebt Google Analytics diese Daten.
        </p>
        <p>
          <strong className="adsimple-111240294">IP-Adresse:</strong> Die
          IP-Adresse wird nur in gekürzter Form dargestellt, damit keine
          eindeutige Zuordnung möglich ist.
        </p>
        <p>
          <strong className="adsimple-111240294">Standort:</strong> Über die
          IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden.
          Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.
        </p>
        <p>
          <strong className="adsimple-111240294">
            Technische Informationen:
          </strong>{" "}
          Zu den technischen Informationen zählen unter anderem Ihr Browsertyp,
          Ihr Internetanbieter oder Ihre Bildschirmauflösung.
        </p>
        <p>
          <strong className="adsimple-111240294">Herkunftsquelle:</strong>{" "}
          Google Analytics beziehungsweise uns interessiert natürlich auch über
          welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.
        </p>
        <p>
          Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen
          von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das
          Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren
          Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und
          dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch
          Google Analytics.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten
          Server befinden sich in Amerika und folglich werden Ihr Daten meist
          auf amerikanischen Servern gespeichert. Hier können Sie genau
          nachlesen wo sich die Google-Rechenzentren befinden:{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>
        </p>
        <p>
          Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt.
          Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor
          Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt
          es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise
          die Hardware bei Google ausfällt oder Naturkatastrophen Server
          lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google
          dennoch gering.
        </p>
        <p>
          Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer
          Userdaten von 26 Monaten eingestellt. Dann werden Ihre Userdaten
          gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer
          von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur
          Verfügung:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Löschung nach 14 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 26 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 38 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 50 Monaten</li>
          <li className="adsimple-111240294">Keine automatische Löschung</li>
        </ul>
        <p>
          Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat
          die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die
          mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
          DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf
          aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
          Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu
          einer größeren Einheit.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht,
          Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen
          oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung
          von Google Analytics-JavaScript (ga.js, analytics.js, dc.js)
          verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das
          Browser-Add-on können Sie unter{" "}
          <a
            className="adsimple-111240294"
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>{" "}
          runterladen und installieren. Beachten Sie bitte, dass durch dieses
          Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
        </p>
        <p>
          Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics)
          deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser
          eine eigene Anleitung:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
          Framework, wodurch der korrekte und sichere Datentransfer persönlicher
          Daten geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=111240294
          </a>
          . Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um
          die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr
          über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden
          Links:{" "}
          <a
            className="adsimple-111240294"
            href="http://www.google.com/analytics/terms/de.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.google.com/analytics/terms/de.html
          </a>{" "}
          und{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics IP-Anonymisierung
        </h2>
        <p>
          Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von
          Google Analytics implementiert. Diese Funktion wurde von Google
          entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen
          und Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn
          diese eine Speicherung der vollständigen IP-Adresse untersagen. Die
          Anonymisierung bzw. Maskierung der IP findet statt, sobald die
          IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und
          bevor eine Speicherung oder Verarbeitung der Daten stattfindet.
        </p>
        <p>
          Mehr Informationen zur IP-Anonymisierung finden Sie auf 
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/2763052?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/2763052?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Berichte zu demografischen Merkmalen und Interessen
        </h2>
        <p>
          Wir haben in Google Analytics die Funktionen für Werbeberichte
          eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen
          enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können
          wir uns – ohne diese Daten einzelnen Personen zuordnen zu können – ein
          besseres Bild von unseren Nutzern machen. Mehr über die
          Werbefunktionen erfahren Sie{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad"
            target="_blank"
            rel="noopener noreferrer"
          >
            auf https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad
          </a>
          .
        </p>
        <p>
          Sie können die Nutzung der Aktivitäten und Informationen Ihres Google
          Kontos unter “Einstellungen für Werbung” auf{" "}
          <a
            className="adsimple-111240294"
            href="https://adssettings.google.com/authenticated"
          >
            https://adssettings.google.com/authenticated
          </a>{" "}
          per Checkbox beenden.
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Deaktivierungslink
        </h2>
        <p>
          Wenn Sie auf folgenden{" "}
          <strong className="adsimple-111240294">Deaktivierungslink</strong>{" "}
          klicken, können Sie verhindern, dass Google weitere Besuche auf dieser
          Webseite erfasst. Achtung: Das Löschen von Cookies, die Nutzung des
          Inkognito/Privatmodus ihres Browsers, oder die Nutzung eines anderen
          Browsers führt dazu, dass wieder Daten erhoben werden.
        </p>
        <span
          className="adsimple-111240294"
          //   style="cursor: help; border: 0 none; border-bottom-width: 1px; border-style: dashed;"
          title="Es wurde kein UA-Code eingegeben. Bitten Sie den Administrator, dieses Problem zu beheben!"
        >
          Google Analytics deaktivieren
        </span>
        <p> </p>
        <h2 className="adsimple-111240294">
          Google Analytics Zusatz zur Datenverarbeitung
        </h2>
        <p>
          Wir haben mit Google einen Direktkundenvertrag zur Verwendung von
          Google Analytics abgeschlossen, indem wir den “Zusatz zur
          Datenverarbeitung” in Google Analytics akzeptiert haben.
        </p>
        <p>
          Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden
          Sie hier:{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad
          </a>
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Google-Signale Datenschutzerklärung
        </h2>
        <p>
          Wir haben in Google Analytics die Google-Signale aktiviert. So werden
          die bestehenden Google-Analytics-Funktionen (Werbeberichte,
          Remarketing, gerätübergreifende Berichte und Berichte zu Interessen
          und demografische Merkmale) aktualisiert, um zusammengefasste und
          anonymisierte Daten von Ihnen zu erhalten, sofern Sie personalisierte
          Anzeigen in Ihrem Google-Konto erlaubt haben.
        </p>
        <p>
          Das besondere daran ist, dass es sich dabei um ein
          Cross-Device-Tracking handelt. Das heißt Ihre Daten können
          geräteübergreifend analysiert werden. Durch die Aktivierung von
          Google-Signale werden Daten erfasst und mit dem Google-Konto
          verknüpft. Google kann dadurch zum Beispiel erkennen, wenn Sie auf
          unsere Webseite über ein Smartphone ein Produkt ansehen und erst
          später über einen Laptop das Produkt kaufen. Dank der Aktivierung von
          Google-Signale können wir gerätübergreifende Remarketing-Kampagnen
          starten, die sonst in dieser Form nicht möglich wären. Remarketing
          bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot
          zeigen können.
        </p>
        <p>
          In Google Analytics werden zudem durch die Google-Signale weitere
          Besucherdaten wie Standort, Suchverlauf, YouTube-Verlauf und Daten
          über Ihre Handlungen auf unserer Webseite, erfasst. Wir erhalten
          dadurch von Google bessere Werbeberichte und nützlichere Angaben zu
          Ihren Interessen und demografischen Merkmalen. Dazu gehören Ihr Alter,
          welche Sprache sie sprechen, wo Sie wohnen oder welchem Geschlecht Sie
          angehören. Weiters kommen auch noch soziale Kriterien wie Ihr Beruf,
          Ihr Familienstand oder Ihr Einkommen hinzu. All diese Merkmal helfen
          Google Analytics Personengruppen bzw. Zielgruppen zu definieren.
        </p>
        <p>
          Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und
          Interessen besser einschätzen zu können. Dadurch können wir unsere
          Dienstleistungen und Produkte für Sie optimieren und anpassen. Diese
          Daten laufen standardmäßig nach 26 Monaten ab. Bitte beachten Sie,
          dass diese Datenerfassung nur erfolgt, wenn Sie personalisierte
          Werbung in Ihrem Google-Konto zugelassen haben. Es handelt sich dabei
          immer um zusammengefasste und anonyme Daten und nie um Daten einzelner
          Personen. In Ihrem Google-Konto können Sie diese Daten verwalten bzw.
          auch löschen.
        </p>
        <h2 className="adsimple-111240294">
          Facebook-Pixel Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook.
          Dafür haben wir einen Code auf unserer Webseite implementiert. Der
          Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine
          Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen
          verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite
          gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer
          Webseite erwerben, wird das Facebook-Pixel ausgelöst und speichert
          Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies.
          Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie
          IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen.
          Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für
          uns anonym und nicht einsehbar und werden nur im Rahmen von
          Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind
          und angemeldet sind, wird der Besuch unserer Webseite automatisch
          Ihrem Facebook-Benutzerkonto zugeordnet.
        </p>
        <p>
          Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen
          zeigen, die sich auch wirklich dafür interessieren. Mithilfe von
          Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche
          und Interessen abgestimmt werden. So bekommen Facebook-User (sofern
          sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen.
          Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und
          eigenen Werbeanzeigen.
        </p>
        <p>
          Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden
          von Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachten
          Sie, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf
          unserer Webseite werden unterschiedliche Cookies gesetzt.
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _fbp
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          fb.1.1568287647279.257405483-6111240294-7
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie verwendet Facebook, um Werbeprodukte anzuzeigen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0aPf312HOS5Pboo2r..Bdeiuf…1.0.Bdeiuf.
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit Facebook-Pixel auch ordentlich
          funktioniert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_50ae8267e2bdf1253ec1a5769f48e062111240294-3
          <br />
          <strong className="adsimple-111240294">Wert:</strong> Name des Autors
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert den Text und den Namen eines Users, der
          beispielsweise einen Kommentar hinterlässt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_url_50ae8267e2bdf1253ec1a5769f48e062
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          https%3A%2F%2Fwww.testseite…%2F (URL des Autors)
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert die URL der Website, die der User in einem
          Textfeld auf unserer Webseite eingibt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_email_50ae8267e2bdf1253ec1a5769f48e062
          <br />
          <strong className="adsimple-111240294">Wert:</strong> E-Mail-Adresse
          des Autors
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert die E-Mail-Adresse des Users, sofern er sie
          auf der Website bekannt gegeben hat.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung: </strong>Die oben
          genannten Cookies beziehen sich auf ein individuelles Userverhalten.
          Speziell bei der Verwendung von Cookies sind Veränderungen bei
          Facebook nie auszuschließen.
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>{" "}
          grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
          haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
          aktivieren.
        </p>
        <p>
          Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
          empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Facebook Automatischer erweiterter Abgleich Datenschutzerklärung
        </h2>
        <p>
          Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen
          erweiterten Abgleich (engl. Automatic Advanced Matching) aktiviert.
          Diese Funktion des Pixels ermöglicht uns, gehashte E-Mails, Namen,
          Geschlecht, Stadt, Bundesland, Postleitzahl und Geburtsdatum oder
          Telefonnummer als zusätzliche Informationen an Facebook zu senden,
          sofern Sie uns diese Daten zur Verfügung gestellt haben. Diese
          Aktivierung ermöglicht uns Werbekampagnen auf Facebook noch genauer
          auf Menschen, die sich für unsere Dienstleistungen oder Produkte
          interessieren, anzupassen.
        </p>
        <h2 className="adsimple-111240294">Newsletter Datenschutzerklärung</h2>
        <p>
          <span className="adsimple-111240294">
            Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die
            oben genannten persönlichen Daten und geben uns das Recht Sie per
            E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter
            gespeicherten Daten nutzen wir ausschließlich für unseren Newsletter
            und geben diese nicht weiter.
          </span>
        </p>
        <p>
          <span className="adsimple-111240294">
            Sollten Sie sich vom Newsletter abmelden – Sie finden den Link dafür
            in jedem Newsletter ganz unten – dann löschen wir alle Daten die mit
            der Anmeldung zum Newsletter gespeichert wurden.
          </span>
        </p>
        <h2 className="adsimple-111240294">
          Google AdSense Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf dieser Webseite Google AdSense. Das ist ein
          Anzeigenprogramm der Firma Google Inc. (1600 Amphitheatre Parkway
          Mountain View, CA 94043, USA). Mit Google AdSense können wir auf
          dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema passen.
          So bieten wir Ihnen Anzeigen, die im Idealfall einen richtigen
          Mehrwert für Sie darstellen. Im Zuge dieser Datenschutzerklärung über
          Google AdSense erklären wir Ihnen, warum wir Google AdSense auf
          unserer Webseite verwenden, welche Daten von Ihnen verarbeitet und
          gespeichert werden und wie Sie diese Datenspeicherung unterbinden
          können.
        </p>
        <h3 className="adsimple-111240294">Was ist Google AdSense?</h3>
        <p>
          Das Werbeprogramm Google AdSense gibt es mittlerweile seit 2003. Im
          Gegensatz zu Google Ads (früher: Google AdWords) kann man hier nicht
          selbst Werbung schalten. Über Google AdSense werden Werbeanzeigen auf
          Webseiten, wie zum Beispiel auf unserer, ausgespielt. Der größte
          Vorteil dieses Werbedienstes im Vergleich zu manch anderen ist, dass
          Ihnen Google AdSense nur Anzeigen zeigt, die zu unseren Inhalten
          passen. Google hat einen eigenen Algorithmus, der berechnet, welche
          Werbeanzeigen Sie zu Gesicht bekommen. Natürlich wollen wir Ihnen nur
          Werbung bieten, die Sie auch interessiert und Ihnen einen Mehrwert
          bietet. Google überprüft anhand Ihrer Interessen bzw. Ihres
          Userverhaltens und anhand unseres Angebots, welche Werbeanzeigen für
          unsere Webseite und für unserer User geeignet sind. An dieser Stelle
          wollen wir auch gleich erwähnen, dass wir für die Auswahl der
          Werbeanzeigen nicht verantwortlich sind. Wir bieten mit unserer
          Webseite lediglich die Werbefläche an. Die Auswahl der angezeigten
          Werbung trifft Google. Seit August 2013 werden die Anzeigen auch an
          die jeweilige Benutzeroberfläche angepasst. Das heißt, egal ob Sie von
          Ihrem Smartphone, Ihrem PC oder Laptop unsere Webseite besuchen, die
          Anzeigen passen sich an Ihr Endgerät an.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google AdSense auf unserer Webseite?
        </h3>
        <p>
          Das Betreiben einer hochwertigen Webseite erfordert viel Hingabe und
          großen Einsatz. Im Grunde sind wir mit der Arbeit an unserer Webseite
          nie fertig. Wir versuchen stets unsere Seite zu pflegen und so aktuell
          wie möglich zu halten. Natürlich wollen wir mit dieser Arbeit auch
          einen wirtschaftlichen Erfolg erzielen. Darum haben wir uns für
          Werbeanzeigen als Einnahmequelle entschieden. Das Wichtigste für uns
          ist allerdings, Ihren Besuch auf unserer Webseite durch diese Anzeigen
          nicht zu stören. Mithilfe von Google AdSense wird Ihnen nur Werbung
          angeboten, die zu unseren Themen und Ihren Interessen passt.
        </p>
        <p>
          Ähnlich wie bei der Google-Indexierung für eine Webseite, untersucht
          ein Bot den entsprechenden Content und die entsprechenden Angebote
          unserer Webseite. Dann werden die Werbeanzeigen inhaltlich angepasst
          und auf der Webseite präsentiert. Neben den inhaltlichen
          Überschneidungen zwischen Anzeige und Webseiten-Angebot unterstützt
          AdSense auch interessensbezogenes Targeting. Das bedeutet, dass Google
          auch Ihre Daten dazu verwendet, um auf Sie zugeschnittene Werbung
          anzubieten. So erhalten Sie Werbung, die Ihnen im Idealfall einen
          echten Mehrwert bietet und wir haben eine höhere Chance ein bisschen
          etwas zu verdienen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Google AdSense gespeichert?
        </h3>
        <p>
          Damit Google AdSense eine maßgeschneiderte, auf Sie angepasste Werbung
          anzeigen kann, werden unter anderem Cookies verwendet. Cookies sind
          kleine Textdateien, die bestimmte Informationen auf Ihrem Computer
          speichern.
        </p>
        <p>
          In AdSense sollen Cookies bessere Werbung ermöglichen. Die Cookies
          enthalten keine personenidentifizierbaren Daten. Hierbei ist
          allerdings zu beachten, dass Google Daten wie zum Beispiel “Pseudonyme
          Cookie-IDs” (Name oder anderes Identifikationsmerkmal wird durch ein
          Pseudonym ersetzt) oder IP-Adressen als nicht personenidentifizierbare
          Informationen ansieht. Im Rahmen der DSGVO können diese Daten
          allerdings als personenbezogene Daten gelten. Google AdSense sendet
          nach jeder Impression (das ist immer dann der Fall, wenn Sie eine
          Anzeige sehen), jedem Klick und jeder anderen Aktivität, die zu einem
          Aufruf der Google AdSense-Server führt, ein Cookie an den Browser.
          Sofern der Browser das Cookie akzeptiert, wird es dort gespeichert.
        </p>
        <p>
          Drittanbieter können im Rahmen von AdSense unter Umständen Cookies in
          Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um
          Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der
          Webseite erhalten. Als Web-Beacons bezeichnet man kleine Grafiken, die
          eine Logdatei-Analyse und eine Aufzeichnung der Logdatei machen. Diese
          Analyse ermöglicht eine statistische Auswertung für das
          Online-Marketing.
        </p>
        <p>
          Google kann über diese Cookies bestimmte Informationen über Ihr
          Userverhalten auf unserer Webseite sammeln. Dazu zählen:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Informationen wie Sie mit einer Anzeige umgehen (Klicks, Impression,
            Mausbewegungen)
          </li>
          <li className="adsimple-111240294">
            Informationen, ob in Ihrem Browser schon eine Anzeige zu einem
            früheren Zeitpunkt erschienen ist. Diese Daten helfen dabei, Ihnen
            eine Anzeige nicht öfter anzuzeigen.
          </li>
        </ul>
        <p>
          Dabei analysiert Google die Daten zu den angezeigten Werbemitteln und
          Ihre IP-Adresse und wertet diese aus. Google verwendet die Daten in
          erster Linie, um die Effektivität einer Anzeige zu messen und das
          Werbeangebot zu verbessern. Diese Daten werden nicht mit
          personenbezogenen Daten, die Google möglicherweise über andere
          Google-Dienste von Ihnen hat, verknüpft.
        </p>
        <p>
          Im Folgenden stellen wir Ihnen Cookies vor, die Google AdSense für
          Trackingzwecke verwendet. Hierbei beziehen wir uns auf eine
          Test-Webseite, die ausschließlich Google AdSense installiert hat:
          <strong className="adsimple-111240294"> </strong>
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> uid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          891269189111240294-8
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird unter der Domain adform.net gespeichert. Es stellt eine
          eindeutig zugewiesene, maschinell generierte User-ID bereit und
          sammelt Daten über die Aktivität auf unserer Webseite.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> C<br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie identifiziert, ob Ihrer Browser Cookies akzeptiert. Das
          Cookie wird unter der Domain track.adform.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> cid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          8912691894970695056,0,0,0,0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird unter der Domain track.adform.net gespeichert,
          steht für Client-ID und wird verwendet, um die Werbung für Sie zu
          verbessern. Es kann relevantere Werbung an den Besucher weiterleiten
          und hilft, die Berichte über die Kampagnenleistung zu verbessern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> IDE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          zOtj4TWxwbFDjaATZ2TzNaQmxrU111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird unter der Domain doubkeklick.net gespeichert. Es dient
          dazu, Ihre Aktionen nach der Anzeige bzw. nach dem Klicken der Anzeige
          zu registrieren. Dadurch kann man messen, wie gut eine Anzeige bei
          unseren Besuchern ankommt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> test_cookie
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Mithilfe des „test_cookies“ kann man überprüfen, ob Ihr Browser
          überhaupt Cookies unterstützt. Das Cookie wird unter der Domain
          doubleklick.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CT592996
          <br />
          <strong className="adsimple-111240294">Wert:</strong>733366
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Wird
          unter der Domain adform.net gespeichert. Das Cookie wird gesetzt
          sobald Sie auf eine Werbeanzeige klicken. Genauere Informationen über
          die Verwendung dieses Cookies konnten wir nicht in Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einer Stunde
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Google erfasst Ihre IP-Adresse und verschiedene Aktivitäten, die Sie
          auf der Webseite ausführen. Cookies speichern diese Informationen zu
          den Interaktionen auf unsere Webseite. Laut Google sammelt und
          speichert das Unternehmen die angegebenen Informationen auf sichere
          Weise auf den hauseigenen Google-Servern in den USA.
        </p>
        <p>
          Wenn Sie kein Google-Konto haben bzw. nicht angemeldet sind, speichert
          Google die erhobenen Daten mit einer eindeutigen Kennung (ID) meist
          auf Ihrem Browser. Die in Cookies gespeicherten eindeutigen IDs dienen
          beispielsweise dazu, personalisierte Werbung zu gewährleisten. Wenn
          Sie in einem Google-Konto angemeldet sind, kann Google auch
          personenbezogene Daten erheben.
        </p>
        <p>
          Einige der Daten, die Google speichert, können Sie jederzeit wieder
          löschen (siehe nächsten Abschnitt). Viele Informationen, die in
          Cookies gespeichert sind, werden automatisch nach einer bestimmten
          Zeit wieder gelöscht. Es gibt allerdings auch Daten, die von Google
          über einen längeren Zeitraum gespeichert werden. Dies ist dann der
          Fall, wenn Google aus wirtschaftlichen oder rechtlichen
          Notwendigkeiten, gewisse Daten über einen unbestimmten, längeren
          Zeitraum speichern muss.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben immer die Möglichkeit Cookies, die sich auf Ihrem Computer
          befinden, zu löschen oder zu deaktivieren. Wie genau das funktioniert
          hängt von Ihrem Browser ab.
        </p>
        <p>
          Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
          verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
          Herunterladen und Installieren dieses Browser-Plug-ins auf{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/ads/answer/7395996"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/ads/answer/7395996
          </a>{" "}
          werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass
          Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
          verhindern, sondern nur die personalisierte Werbung.
        </p>
        <p>
          Wenn Sie ein Google Konto besitzen, können Sie auf der Webseite{" "}
          <a
            className="adsimple-111240294"
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://adssettings.google.com/authenticated
          </a>{" "}
          personalisierte Werbung deaktivieren. Auch hier sehen Sie dann weiter
          Anzeigen, allerdings sind diese nicht mehr an Ihre Interessen
          angepasst. Dennoch werden die Anzeigen auf der Grundlage von ein paar
          Faktoren, wie Ihrem Standort, dem Browsertyp und der verwendeten
          Suchbegriffe, angezeigt.
        </p>
        <p>
          Welche Daten Google grundsätzlich erfasst und wofür sie diese Daten
          verwenden, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>{" "}
          nachlesen.
        </p>
        <h2 className="adsimple-111240294">
          Amazon-Partnerprogramm Datenschutzerklärung
        </h2>
        <p>
          Wir sind Teilnehmer am Amazon Partnerprogramm welches die Platzierung
          von Amazon-Werbeanzeigen und Partnerlinks auf Webseiten ermöglicht.
          Durch diese Platzierung von Amazon Werbung wird eine
          Werbekostenerstattung erzielt.
          <br />
          Um die Herkunft von Bestellungen nachvollziehen zu können, setzt
          Amazon Cookies ein.
          <br />
          Verantwortliche Stelle im Sinne der Datenschutzgesetze sind die Amazon
          Europe Core S.à.r.l., die Amazon EU S.à.r.l, die Amazon Services
          Europe S.à.r.l. und die Amazon Media EU S.à.r.l., alle vier ansässig
          5, Rue Plaetis, L-2338 Luxemburg sowie Amazon Instant Video Germany
          GmbH, Domagkstr. 28, 80807 München. Als Datenverarbeiter wird in ihrem
          Auftrag die Amazon Deutschland Services GmbH, Marcel-Breuer-Str. 12,
          80807 München, tätig.
          <br />
          Die Datenschutzrichtlinien, welche Informationen Amazon sammelt und
          wie sie diese verwenden finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401"
          >
            https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden als Online-Marketing-Maßnahme Google Ads (früher Google
          AdWords), um unsere Produkte und Dienstleistungen zu bewerben. So
          wollen wir im Internet mehr Menschen auf die hohe Qualität unserer
          Angebote aufmerksam machen. Im Rahmen unserer Werbe-Maßnahmen durch
          Google Ads verwenden wir auf unserer Webseite das Conversion-Tracking
          der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
          USA (“Google”). Mithilfe dieses kostenlosen Tracking-Tools können wir
          unser Werbeangebot an Ihre Interessen und Bedürfnisse  besser
          anpassen. Im Folgenden Artikel wollen wir genauer darauf eingehen,
          warum wir Conversion-Tracking benutzen, welche Daten dabei gespeichert
          werden und wie Sie diese Datenspeicherung verhindern.
        </p>
        <h3 className="adsimple-111240294">
          Was ist Google Ads Conversion-Tracking?
        </h3>
        <p>
          Google Ads (früher Google AdWords) ist das hauseigene
          Online-Werbesystem der Firma Google LLC. Wir sind von der Qualität
          unseres Angebots überzeugt und wollen, dass so viele Menschen wie
          möglich unsere Webseite kennenlernen. Im Onlinebereich bietet Google
          Ads dafür die beste Plattform. Natürlich wollen wir auch einen genauen
          Überblick über den Kosten-Nutzen-Faktor unsere Werbeaktionen gewinnen.
          Darum verwenden wir das Conversion-Tracking-Tool von Google Ads.
        </p>
        <p>
          Doch was ist eine Conversion eigentlich? Eine Conversion entsteht,
          wenn Sie von einem rein interessierten Webseitenbesucher zu einem
          handelnden Besucher werden. Dies passiert immer dann, wenn Sie auf
          unsere Anzeige klicken und im Anschluss eine andere Aktion ausführen,
          wie zum Beispiel unsere Webseite besuchen. Mit dem
          Conversion-Tracking-Tool von Google erfassen wir, was nach einem Klick
          eines Users auf unsere Google Ads-Anzeige geschieht. Zum Beispiel
          können wir so sehen, ob Produkte gekauft werden, Dienstleistungen in
          Anspruch genommen werden oder ob sich User für unseren Newsletter
          angemeldet haben.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google Ads Conversion-Tracking auf unserer
          Webseite?
        </h3>
        <p>
          Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser
          Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen
          wirklich auch nur jene Menschen erreichen, die sich für unsere
          Angebote interessieren. Mit dem Conversion-Tracking Tool sehen wir
          welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den
          gewünschten Kundenaktionen führen. Wir sehen wie viele Kunden mit
          unseren Anzeigen auf einem Gerät interagieren und dann eine Conversion
          durchführen. Durch diese Daten können wir unseren Kosten-Nutzen-Faktor
          berechnen, den Erfolg einzelner Werbemaßnahmen messen und folglich
          unsere Online-Marketing-Maßnahmen optimieren. Wir können weiters
          mithilfe der gewonnenen Daten unsere Webseite für Sie interessanter
          gestalten und unser Werbeangebot noch individueller an Ihre
          Bedürfnisse anpassen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?
        </h3>
        <p>
          Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer
          Webseite eingebunden, um gewisse User-Aktionen besser analysieren zu
          können. Wenn Sie nun eine unserer Google Ads-Anzeigen anklicken, wird
          auf Ihrem Computer (meist im Browser) oder Mobilgerät das Cookie
          „Conversion“ von einer Google-Domain gespeichert. Cookies sind kleine
          Textdateien, die Informationen auf Ihrem Computer speichern.
        </p>
        <p>
          Hier die Daten der wichtigsten Cookies für das Conversion-Tracking von
          Google:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> Conversion
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ111240294-3
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert jede Conversion, die Sie auf unserer Seite
          machen, nachdem Sie über eine Google Ads zu uns gekommen sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gac
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Dies
          ist ein klassisches Google Analytics-Cookie und dient dem Erfassen
          verschiedener Handlungen auf unserer Webseite.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Das Cookie
          _gac scheint nur in Verbindung mit Google Analytics auf. Die oben
          angeführte Aufzählung hat keinen Anspruch auf Vollständigkeit, da
          Google für analytische Auswertung immer wieder auch andere Cookies
          verwendet.
        </p>
        <p>
          Sobald Sie eine Aktion auf unserer Webseite abschließen, erkennt
          Google das Cookie und speichert Ihre Handlung als sogenannte
          Conversion. Solange Sie auf unserer Webseite surfen und das Cookie
          noch nicht abgelaufen ist, erkennen wir und Google, dass Sie über
          unsere Google-Ads-Anzeige zu uns gefunden haben. Das Cookie wird
          ausgelesen und mit den Conversion-Daten zurück an Google Ads gesendet.
          Es ist auch möglich, dass noch andere Cookies zur Messung von
          Conversions verwendet werden. Das Conversion-Tracking von Google Ads
          kann mithilfe von Google Analytics noch verfeinert und verbessert
          werden. Bei Anzeigen, die Google an verschiedenen Orten im Web
          anzeigt, werden unter unserer Domain möglicherweise Cookies mit dem
          Namen “__gads” oder “_gac” gesetzt. Seit September 2017 werden diverse
          Kampagneninformationen von analytics.js mit dem _gac-Cookie
          gespeichert. Das Cookie speichert diese Daten, sobald Sie eine unserer
          Seiten aufrufen, für die die automatische Tag-Kennzeichnung von Google
          Ads eingerichtet wurde. Im Gegensatz zu Cookies, die für
          Google-Domains gesetzt werden, kann Google diese Conversion-Cookies
          nur lesen, wenn Sie sich auf unserer Webseite befinden. Wir erheben
          und erhalten keine personenbezogenen Daten. Wir bekommen von Google
          einen Bericht mit statistischen Auswertungen. So erfahren wir
          beispielsweise die Gesamtanzahl der User, die unsere Anzeige
          angeklickt haben und wir sehen, welche Werbemaßnahmen gut ankamen.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss
          darauf haben, wie Google die erhobenen Daten weiterverwendet. Laut
          Google werden die Daten verschlüsselt und auf sicheren Servern
          gespeichert. In den meisten Fällen laufen Conversion-Cookies nach 30
          Tagen ab und übermitteln keine personenbezogenen Daten. Die Cookies
          mit dem Namen „Conversion“ und „_gac“ (das in Verbindung mit Google
          Analytics zum Einsatz kommt) haben ein Ablaufdatum von 3 Monaten.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben die Möglichkeit am Conversion-Tracking von Google Ads nicht
          teilzunehmen. Wenn Sie das Cookie des Google Conversion-Tracking über
          Ihren Browser deaktivieren, blockieren Sie das Conversion-Tracking. In
          diesem Fall werden Sie in der Statistik des Tracking-Tools nicht
          berücksichtigt. Sie können die Cookie-Einstellungen in Ihrem Browser
          jederzeit verändern. Bei jedem Browser funktioniert dies etwas anders.
          Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
          verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
          Herunterladen und Installieren dieses Browser-Plug-ins auf{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/ads/answer/7395996"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/ads/answer/7395996
          </a>{" "}
          werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass
          Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
          verhindern, sondern nur die personalisierte Werbung.
        </p>
        <p>
          Durch die Zertifizierung für das amerikanische-europäische
          Datenschutzübereinkommen “Privacy Shield”, muss der amerikanische
          Konzern Google LLC die in der EU geltenden Datenschutzgesetze
          einhalten. Wenn Sie Näheres über den Datenschutz bei Google erfahren
          möchten, empfehlen wir die allgemeine Datenschutzerklärung von Google:{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Eingebettete Social Media Elemente Datenschutzerklärung
        </h2>
        <p>
          Wir binden auf unserer Webseite Elemente von Social Media Diensten ein
          um Bilder, Videos und Texte anzuzeigen.
          <br />
          Durch den Besuch von Seiten die diese Elemente darstellen, werden
          Daten von Ihrem Browser zum jeweiligen Social Media Dienst übertragen
          und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
          <br />
          Die folgenden Links führen Sie zu den Seiten der jeweiligen Social
          Media Dienste wo erklärt wird, wie diese mit Ihren Daten umgehen:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Instagram-Datenschutzrichtlinie:{" "}
            <a
              className="adsimple-111240294"
              href="https://help.instagram.com/519522125107875?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://help.instagram.com/519522125107875
            </a>
          </li>
          <li className="adsimple-111240294">
            Für YouTube gilt die Google Datenschutzerklärung:{" "}
            <a
              className="adsimple-111240294"
              href="https://policies.google.com/privacy?hl=de&tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://policies.google.com/privacy?hl=de
            </a>
          </li>
          <li className="adsimple-111240294">
            Facebook-Datenrichtline: 
            <a
              className="adsimple-111240294"
              href="https://www.facebook.com/about/privacy?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/about/privacy
            </a>
          </li>
          <li className="adsimple-111240294">
            Twitter Datenschutzrichtlinie: 
            <a
              className="adsimple-111240294"
              href="https://twitter.com/de/privacy?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://twitter.com/de/privacy
            </a>
          </li>
        </ul>
        <h2 className="adsimple-111240294">Facebook Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf unserer Webseite ausgewählte Facebook Tools von
          Facebook. Facebook ist ein Social Media Network des Unternehmens
          Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
          Dublin 2 Ireland. Mithilfe dieser Tools können wir Ihnen und Menschen,
          die sich für unsere Produkte und Dienstleistungen interessieren, das
          bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick
          über die verschiedenen Facebook Tools, welche Daten an Facebook
          gesendet werden und wie Sie diese Daten löschen können.
        </p>
        <h3 className="adsimple-111240294">Was sind Facebook-Tools?</h3>
        <p>
          Neben vielen anderen Produkten bietet Facebook auch die sogenannten
          “Facebook Business Tools” an. Das ist die offizielle Bezeichnung von
          Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür
          entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden
          sich unter anderem:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Facebook-Pixel</li>
          <li className="adsimple-111240294">
            soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)
          </li>
          <li className="adsimple-111240294">Facebook Login</li>
          <li className="adsimple-111240294">Account Kit</li>
          <li className="adsimple-111240294">
            APIs (Programmierschnittstelle)
          </li>
          <li className="adsimple-111240294">
            SDKs (Sammlung von Programmierwerkzeugen)
          </li>
          <li className="adsimple-111240294">Plattform-Integrationen</li>
          <li className="adsimple-111240294">Plugins</li>
          <li className="adsimple-111240294">Codes</li>
          <li className="adsimple-111240294">Spezifikationen</li>
          <li className="adsimple-111240294">Dokumentationen</li>
          <li className="adsimple-111240294">
            Technologien und Dienstleistungen
          </li>
        </ul>
        <p>
          Durch diese Tools erweitert Facebook Dienstleistungen und hat die
          Möglichkeit, Informationen über User-Aktivitäten außerhalb von
          Facebook zu erhalten.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Facebook-Tools auf unserer Webseite?
        </h3>
        <p>
          Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen,
          die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen
          (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den
          Usern passende Werbung gezeigt werden kann, benötigt Facebook
          allerdings Informationen über die Wünsche und Bedürfnisse der
          Menschen. So werden dem Unternehmen Informationen über das
          Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung
          gestellt. Dadurch sammelt Facebook bessere User-Daten und kann
          interessierten Menschen die passende Werbung über unsere Produkte bzw.
          Dienstleistungen anzeigen. Die Tools ermöglichen somit
          maßgeschneiderte Werbekampagnen auf Facebook.
        </p>
        <p>
          Daten über Ihr Verhalten auf unserer Webseite nennt Facebook
          „Event-Daten“. Diese werden auch für Messungs- und Analysedienste
          verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“
          über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen
          wir durch Analysen einen besseren Einblick, wie Sie unsere
          Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren
          wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer
          Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte
          auf unserer Seite direkt auf Facebook teilen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Facebook-Tools gespeichert?
        </h3>
        <p>
          Durch die Nutzung einzelner Facebook-Tools können personenbezogene
          Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den
          benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer
          und IP-Adresse versandt werden.
        </p>
        <p>
          Facebook verwendet diese Informationen, um die Daten mit den ihren
          eigenen Daten, die es von Ihnen hat (sofern Sie Facebook-Mitglied
          sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden,
          erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig
          großer Datensatz in eine Zeichenkette transformiert wird. Dies dient
          auch der Verschlüsselung von Daten.
        </p>
        <p>
          Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter
          „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf
          unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie
          besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die
          erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise
          Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung
          oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit
          Kontaktdaten verbunden werden. Dadurch kann Facebook bessere
          personalisierte Werbung anbieten. Nach dem bereits erwähnten
          Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.
        </p>
        <p>
          Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook
          die Event-Daten nur, wenn diese mit anderen Daten (die auf andere
          Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese
          Event-Daten nützt Facebook auch für Sicherheits-, Schutz-,
          Entwicklungs- und Forschungszwecken. Viele dieser Daten werden über
          Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die
          zum Speichern von Daten bzw. Informationen in Browsern verwendet
          werden. Je nach verwendeten Tools und abhängig, ob Sie
          Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem
          Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools
          gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine
          Informationen über die Verwendung von Facebook-Cookies erfahren Sie
          auch auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policies/cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policies/cookies
          </a>
          .
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die
          eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat
          auf der ganzen Welt Server verteilt, wo Ihre Daten gespeichert werden.
          Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten
          abgeglichen wurden, innerhalb von 48 Stunden gelöscht.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf
          Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.
        </p>
        <p>
          Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr
          Facebook-Konto vollständig löschen. Und so funktioniert das Löschen
          Ihres Facebook-Kontos:
        </p>
        <p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
        <p>
          2) Anschließend klicken Sie in der linken Spalte auf „Deine
          Facebook-Informationen“.
        </p>
        <p>3) Nun klicken Sie “Deaktivierung und Löschung”.</p>
        <p>
          4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter
          und Konto löschen“
        </p>
        <p>
          5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann
          auf „Konto löschen“
        </p>
        <p>
          Die Speicherung der Daten, die Facebook über unsere Seite erhält,
          erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In
          Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren,
          löschen oder verwalten. Je nach dem welchen Browser Sie verwenden,
          funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
          Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie es erlauben oder nicht.
        </p>
        <p>
          Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework,
          wodurch der korrekte und sichere Datentransfer persönlicher Daten
          geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC
          </a>
          . Wir hoffen wir haben Ihnen die wichtigsten Informationen über die
          Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht.
          Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten
          verwendet, empfehlen wir Ihnen die Datenrichtlinien auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/update
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Facebook Login Datenschutzerklärung
        </h2>
        <p>
          Wir haben auf unserer Seite das praktische Facebook Login integriert.
          So können Sie sich bei uns ganz einfach mit Ihrem Facebook-Konto
          einloggen, ohne ein weiteres Benutzerkonto anlegen zu müssen. Wenn Sie
          sich entscheiden, Ihre Registrierung über das Facebook Login zu
          machen, werden Sie auf das Social Media Network Facebook
          weitergeleitet. Dort erfolgt die Anmeldung über Ihre Facebook
          Nutzerdaten. Durch dieses Login-Verfahren werden Daten über Sie bzw.
          Ihr Userverhalten gespeichert und an Facebook übermittelt.
        </p>
        <p>
          Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im
          Folgenden zeigen wir Ihnen die wichtigsten Cookies, die in Ihrem
          Browser gesetzt werden bzw. schon bestehen, wenn Sie sich über das
          Facebook Login auf unserer Seite anmelden:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0jieyh4c2GnlufEJ9..Bde09j…1.0.Bde09j
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit das sozialen Plugin auf unserer
          Webseite bestmöglich funktioniert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> datr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          4Jh7XUA2111240294SEmPsSfzCOO4JFFl
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Facebook setzt das “datr”-Cookie, wenn ein Webbrowser auf facebook.com
          zugreift, und das Cookie hilft, Anmeldeaktivitäten zu identifizieren
          und die Benutzer zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _js_datr
          <br />
          <strong className="adsimple-111240294">Wert:</strong> deleted
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Session-Cookie setzt Facebook zu Trackingzwecken, auch wenn Sie
          kein Facebook-Konto haben oder ausgeloggt sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Die
          angeführten Cookies sind nur eine kleine Auswahl der Cookies, die
          Facebook zur Verfügung stehen. Weitere Cookies sind beispielsweise _
          fbp, sb oder wd. Eine vollständige Aufzählung ist nicht möglich, da
          Facebook über eine Vielzahl an Cookies verfügt und diese variabel
          einsetzt.
        </p>
        <p>
          Der Facebook Login bietet Ihnen einerseits einen schnellen und
          einfachen Registrierungsprozess, andererseits haben wir so die
          Möglichkeit Daten mit Facebook zu teilen. Dadurch können wir unser
          Angebot und unsere Werbeaktionen besser an Ihre Interessen und
          Bedürfnisse anpassen. Daten, die wir auf diese Weise von Facebook
          erhalten, sind öffentliche Daten wie
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Ihr Facebook-Name</li>
          <li className="adsimple-111240294">Ihr Profilbild</li>
          <li className="adsimple-111240294">
            eine hinterlegte E-Mail-Adresse
          </li>
          <li className="adsimple-111240294">Freundeslisten</li>
          <li className="adsimple-111240294">
            Buttons-Angaben (z.B. „Gefällt mir“-Button)
          </li>
          <li className="adsimple-111240294">Geburtstagsdatum</li>
          <li className="adsimple-111240294">Sprache</li>
          <li className="adsimple-111240294">Wohnort</li>
        </ul>
        <p>
          Im Gegenzug stellen wir Facebook Informationen über Ihre Aktivitäten
          auf unserer Webseite bereit. Das sind unter anderem Informationen über
          Ihr verwendetes Endgerät, welche Unterseiten Sie bei uns besuchen oder
          welche Produkte Sie bei uns erworben haben.
        </p>
        <p>
          Durch die Verwendung von Facebook Login willigen Sie der
          Datenverarbeitung ein. Sie können diese Vereinbarung jederzeit
          widerrufen. Wenn Sie mehr Informationen über die Datenverarbeitung
          durch Facebook erfahren wollen, empfehlen wir Ihnen die
          Facebook-Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://de-de.facebook.com/policy.php?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/policy.php
          </a>
          .
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern.
        </p>
        <h2 className="adsimple-111240294">
          Facebook Soziale Plug-ins Datenschutzerklärung
        </h2>
        <p>
          Auf unserer Webseite sind sogenannte soziale Plug-ins des Unternehmens
          Facebook Inc. eingebaut. Sie erkennen diese Buttons am klassischen
          Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand mit erhobenem
          Daumen) oder an einer eindeutigen „Facebook Plug-in“-Kennzeichnung.
          Ein soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere
          Seite integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am
          meisten verwendeten Funktionen sind die bekannten “Gefällt mir”- und
          “Teilen”-Buttons.
        </p>
        <p>Folgende soziale Plug-ins werden von Facebook angeboten:</p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">“Speichern”-Button</li>
          <li className="adsimple-111240294">
            “Gefällt mir”-Button, Teilen, Senden und Zitat
          </li>
          <li className="adsimple-111240294">Seiten-Plug-in</li>
          <li className="adsimple-111240294">Kommentare</li>
          <li className="adsimple-111240294">Messenger-Plug-in</li>
          <li className="adsimple-111240294">
            Eingebettete Beiträge und Videoplayer
          </li>
          <li className="adsimple-111240294">Gruppen-Plug-in</li>
        </ul>
        <p>
          Auf{" "}
          <a
            className="adsimple-111240294"
            href="https://developers.facebook.com/docs/plugins"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.facebook.com/docs/plugins
          </a>{" "}
          erhalten Sie nähere Informationen, wie die einzelnen Plug-ins
          verwendet werden. Wir nützen die sozialen Plug-ins einerseits, um
          Ihnen ein besseres Usererlebnis auf unserer Seite zu bieten,
          andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren
          kann.
        </p>
        <p>
          Sofern Sie ein Facebook-Konto haben oder{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            facebook.com
          </a>{" "}
          schon mal besucht haben, hat Facebook bereits mindestens ein Cookie in
          Ihrem Browser gesetzt. In diesem Fall sendet Ihr Browser über dieses
          Cookie Informationen an Facebook, sobald Sie unsere Seite besuchen
          bzw. mit sozialen Plug-ins (z.B. dem „Gefällt mir“-Button)
          interagieren.
        </p>
        <p>
          Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder
          gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten Ihre
          IP-Adresse, welche Webseite Sie besucht haben, das Datum, die Uhrzeit
          und weitere Informationen, die Ihren Browser betreffen.
        </p>
        <p>
          Um zu verhindern, dass Facebook während Ihres Besuches auf unserer
          Webseite viele Daten sammelt und mit den Facebook-Daten verbindet,
          müssen Sie sich während des Webseitenbesuchs von Facebook abmelden
          (ausloggen).
        </p>
        <p>
          Falls Sie bei Facebook nicht angemeldet sind oder kein Facebook-Konto
          besitzen, sendet Ihr Browser weniger Informationen an Facebook, weil
          Sie weniger Facebook-Cookies haben. Dennoch können Daten wie
          beispielsweise Ihre IP-Adresse oder welche Webseite Sie besuchen an
          Facebook übertragen werden. Wir möchten noch ausdrücklich darauf
          hinweisen, dass wir über die genauen Inhalte der Daten nicht exakt
          Bescheid wissen. Wir versuchen aber Sie nach unserem aktuellen
          Kenntnisstand so gut als möglich über die Datenverarbeitung
          aufzuklären. Wie Facebook die Daten nutzt, können Sie auch in den
          Datenrichtline des Unternehmens unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/update
          </a>{" "}
          nachlesen.
        </p>
        <p>
          Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn Sie
          eine Webseite mit sozialen Plug-ins von Facebook besuchen:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> dpr
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit die sozialen Plug-ins auf unserer
          Webseite funktionieren.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0jieyh4111240294c2GnlufEJ9..Bde09j…1.0.Bde09j
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Auch
          das Cookie ist nötig, dass die Plug-ins einwandfrei funktionieren.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum::</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Cookies wurden nach einem Test gesetzt, auch wenn Sie nicht
          Facebook-Mitglied sind.
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>
          grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
          haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
          aktivieren.
        </p>
        <p>
          Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
          empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policy.php?tip=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">Instagram Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Webseite Funktionen von Instagram eingebaut.
          Instagram ist eine Social Media Plattform des Unternehmens Instagram
          LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012
          ein Tochterunternehmen von Facebook Inc. und gehört zu den
          Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer
          Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie
          Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite
          zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine
          Instagram-Funktion integriert haben, werden Daten an Instagram
          übermittelt, gespeichert und verarbeitet. Instagram verwendet
          dieselben Systeme und Technologien wie Facebook. Ihre Daten werden
          somit über alle Facebook-Firmen hinweg verarbeitet.
        </p>
        <p>
          Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum
          Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie
          die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu
          Facebook Inc. gehört, beziehen wir unsere Informationen einerseits von
          den Instagram-Richtlinien, andererseits allerdings auch von den
          Facebook-Datenrichtlinien selbst.
        </p>
        <h3 className="adsimple-111240294">Was ist Instagram?</h3>
        <p>
          Instagram ist eines der bekanntesten Social Media Netzwerken weltweit.
          Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von
          audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf
          „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und
          kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch
          in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht
          aktiv sein wollen, können Sie auch nur anderen interessante Users
          folgen.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Instagram auf unserer Webseite?
        </h3>
        <p>
          Instagram ist jene Social Media Plattform, die in den letzten Jahren
          so richtig durch die Decke ging. Und natürlich haben auch wir auf
          diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite
          so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche
          Aufbereitung unserer Inhalte selbstverständlich. Durch die
          eingebetteten Instagram-Funktionen können wir unseren Content mit
          hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt
          bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist,
          können uns die erhobenen Daten auch für personalisierte Werbung auf
          Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen,
          die sich wirklich für unsere Produkte oder Dienstleistungen
          interessieren.
        </p>
        <p>
          Instagram nützt die gesammelten Daten auch zu Messungs- und
          Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr
          Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen,
          dass diese Berichte Sie nicht persönlich identifizieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Instagram gespeichert?
        </h3>
        <p>
          Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie
          Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser
          automatisch mit den Servern von Instagram in Verbindung. Dabei werden
          Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar
          unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen
          Informationen über unserer Webseite, über Ihren Computer, über
          getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser
          Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer
          Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto
          haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten
          über Sie.
        </p>
        <p>
          Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen
          davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten
          sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese
          Kundendaten werden erst an Instagram übermittelt werden, wenn Sie
          zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine
          Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten
          verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“
          übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch
          Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass
          Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen
          Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen
          hat, abgeglichen.
        </p>
        <p>
          Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt
          werden, werden die gesammelten Daten an Facebook übermittelt. Je nach
          verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto
          haben, werden unterschiedlich viele Daten gespeichert.
        </p>
        <p>
          Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich
          funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein
          Instagram-Konto haben oder{" "}
          <a
            className="adsimple-111240294"
            href="http://www.instagram.com?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.instagram.com
          </a>{" "}
          besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das
          der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram,
          sobald Sie mit einer Instagram-Funktion in Berührung kommen.
          Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder
          gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der
          Datenverarbeitung von Instagram beschäftigt haben, können wir nicht
          ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.
        </p>
        <p>
          Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens
          gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button
          oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus,
          dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt
          sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.
        </p>
        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>csrftoken
          <br />
          <strong className="adsimple-111240294">Wert: </strong>“”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen
          gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten
          wir das allerdings nicht in Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>mid
          <br />
          <strong className="adsimple-111240294">Wert: </strong>“”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und
          Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt
          eine eindeutige User-ID fest.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          fbsr_111240294124024
          <br />
          <strong className="adsimple-111240294">Wert: </strong>keine Angaben
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.
          <strong className="adsimple-111240294">
            <br />
          </strong>
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> rur
          <br />
          <strong className="adsimple-111240294">Wert: </strong>ATN
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität
          auf Instagram gewährleistet.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> urlgen
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          {"“{”194.96.75.33”: 1901}"}:1iEtYv:Y833k2_UjKvXgYe111240294”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie dient den Marketingzwecken von Instagram.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Wir können
          hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im
          individuellen Fall gesetzt werden, hängt von den eingebetteten
          Funktionen und Ihrer Verwendung von Instagram ab.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Instagram teilt die erhaltenen Informationen zwischen den
          Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen
          Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter
          Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem
          aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt
          verteilt. Die meisten dieser Server stehen in den USA.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft,
          Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den
          Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre
          Daten auf Instagram völlig löschen wollen, müssen Sie Ihr
          Instagram-Konto dauerhaft löschen.
        </p>
        <p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
        <p>
          Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie
          nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf
          die Webseite des Unternehmens. Klicken Sie auf der Webseite auf
          „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.
        </p>
        <p>
          Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie
          beispielsweise Ihre Fotos und Status-Updates. Informationen, die
          andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto
          und werden folglich nicht gelöscht.
        </p>
        <p>
          Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster
          Linie über Cookies. Diese Cookies können Sie in Ihrem Browser
          verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser
          funktioniert die Verwaltung immer ein bisschen anders. Hier zeigen wir
          Ihnen die Anleitungen der wichtigsten Browser.
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie
          immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann
          können Sie immer individuell entscheiden, ob Sie das Cookie zulassen
          wollen oder nicht.
        </p>
        <p>
          Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook
          ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework. Dieses
          Framework stellt eine korrekte Datenübertragung zwischen den USA und
          der Europäischen Union sicher. Unter 
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC 
          </a>
           erfahren Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten
          Informationen über die Datenverarbeitung durch Instagram
          näherzubringen. Auf{" "}
          <a
            className="adsimple-111240294"
            href="https://help.instagram.com/519522125107875"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://help.instagram.com/519522125107875
          </a>
          <br />
          können Sie sich noch näher mit den Datenrichtlinien von Instagram
          auseinandersetzen.
        </p>
        <h2 className="adsimple-111240294">YouTube Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Webseite YouTube-Videos eingebaut. So können wir
          Ihnen interessante Videos direkt auf unserer Seite präsentieren.
          YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von
          Google LLC ist. Betrieben wird das Videoportal durch YouTube, LLC, 901
          Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Webseite
          eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet
          sich Ihr Browser automatisch mit den Servern von YouTube bzw. Google.
          Dabei werden (je nach Einstellungen) verschiedene Daten übertragen.
          Für die gesamte Datenverarbeitung ist Google verantwortlich und es
          gilt somit auch der Datenschutz von Google.
        </p>
        <p>
          Im Folgenden wollen wir Ihnen genauer erklären, welche Daten
          verarbeitet werden, warum wir YouTube-Videos eingebunden haben und wie
          Sie Ihre Daten verwalten oder löschen können.
        </p>
        <h3 className="adsimple-111240294">Was ist YouTube?</h3>
        <p>
          Auf YouTube können die User kostenlos Videos ansehen, bewerten,
          kommentieren und selbst hochladen. Über die letzten Jahre wurde
          YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit
          wir Videos auf unserer Webseite anzeigen können, stellt YouTube einen
          Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut
          haben.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir YouTube-Videos auf unserer Webseite?
        </h3>
        <p>
          YouTube ist die Videoplattform mit den meisten Besuchern und dem
          besten Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung
          auf unserer Webseite zu bieten. Und natürlich dürfen interessante
          Videos dabei nicht fehlen. Mithilfe unserer eingebetteten Videos
          stellen wir Ihnen neben unseren Texten und Bildern weiteren
          hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der
          Google-Suchmaschine durch die eingebetteten Videos leichter gefunden.
          Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google –
          dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen
          zeigen, die sich für unsere Angebote interessieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von YouTube gespeichert?
        </h3>
        <p>
          Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video
          eingebaut hat, setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse
          und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt
          sind, kann YouTube Ihre Interaktionen auf unserer Webseite meist
          mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie
          Sitzungsdauer, Absprungrate, ungefährer Standort, technische
          Informationen wie Browsertyp, Bildschirmauflösung oder Ihr
          Internetanbieter. Weitere Daten können Kontaktdaten, etwaige
          Bewertungen, das Teilen von Inhalten über Social Media oder das
          Hinzufügen zu Ihren Favoriten auf YouTube sein.
        </p>
        <p>
          Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto
          angemeldet sind, speichert Google Daten mit einer eindeutigen Kennung,
          die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt
          beispielsweise Ihre bevorzugte Spracheinstellung beibehalten. Aber
          viele Interaktionsdaten können nicht gespeichert werden, da weniger
          Cookies gesetzt werden.
        </p>
        <p>
          In der folgenden Liste zeigen wir Cookies, die in einem Test im
          Browser gesetzt wurden. Wir zeigen einerseits Cookies, die ohne
          angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir
          Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann
          keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von
          den Interaktionen auf YouTube abhängen.
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> YSC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          b9-CV6ojI5Y111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert eine eindeutige ID, um Statistiken des
          gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> PREF
          <br />
          <strong className="adsimple-111240294">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt
          über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
          verwenden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> GPS
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um
          den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 95Chz8bagyU
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten
          (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monaten
        </p>
        <p>
          Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto
          angemeldet sind:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> APISID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          zILlvClZSkqGsSwI/AU1aZI6HY7111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie speichert den Status der Zustimmung eines Users zur Nutzung
          unterschiedlicher Services von Google. CONSENT dient auch der
          Sicherheit, um User zu überprüfen und Userdaten vor unbefugten
          Angriffen zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 19
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> HSID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AcRwpgUik9Dveht0I
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu
          können.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> LOGIN_INFO
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AFmmF2swRQIhALl6aL…
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> In
          diesem Cookie werden Informationen über Ihre Login-Daten gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SAPISID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Gerät
          eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre
          Interessen zu erstellen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          oQfNKjAsI111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten
          Anmeldezeitpunkt in digital signierter und verschlüsselter Form.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SIDCC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AN0-TYuqub2JOcDTyL
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und
          welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise
          gesehen haben.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den
          Google-Servern gespeichert. Die meisten dieser Server befinden sich in
          Amerika. Unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>{" "}
           sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten
          sind auf den Servern verteilt. So sind die Daten schneller abrufbar
          und vor Manipulation besser geschützt.
        </p>
        <p>
          Die erhobenen Daten speichert Google unterschiedlich lang. Manche
          Daten können Sie jederzeit löschen, andere werden automatisch nach
          einer begrenzten Zeit gelöscht und wieder andere werden von Google
          über längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine
          Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto
          gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen.
          Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie
          einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
          löschen.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit
          der 2019 eingeführten automatische Löschfunktion von Standort- und
          Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung –
          entweder 3 oder 18 Monate gespeichert und dann gelöscht.
        </p>
        <p>
          Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren
          Browser so konfigurieren, dass Cookies von Google gelöscht bzw.
          deaktiviert werden. Je nach dem welchen Browser Sie verwenden,
          funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
          Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie es erlauben oder nicht. Da YouTube ein
          Tochterunternehmen von Google ist, gibt es eine gemeinsame
          Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten
          erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de.
          </a>
        </p>
        <h2 className="adsimple-111240294">
          YouTube Abonnieren Button Datenschutzerklärung
        </h2>
        <p>
          Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl.
          „Subscribe-Button“) eingebaut. Sie erkennen den Button meist am
          klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in
          weißer Schrift die Wörter „Abonnieren“ oder „YouTube“ und links davon
          das weiße „Play-Symbol“. Der Button kann aber auch in einem anderen
          Design dargestellt sein.
        </p>
        <p>
          Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante
          oder spannende Videos. Mit dem eingebauten „Abonnieren-Button“ können
          Sie unseren Kanal direkt von unserer Webseite aus abonnieren und
          müssen nicht eigens die YouTube-Webseite aufrufen. Wir wollen Ihnen
          somit den Zugang zu unserem umfassenden Content so einfach wie möglich
          machen. Bitte beachten Sie, dass YouTube dadurch Daten von Ihnen
          speichern und verarbeiten kann.
        </p>
        <p>
          Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt
          YouTube – laut Google – mindestens ein Cookie. Dieses Cookie speichert
          Ihre IP-Adresse und unsere URL. Auch Informationen über Ihren Browser,
          Ihren ungefähren Standort und Ihre voreingestellte Sprache kann
          YouTube so erfahren. Bei unserem Test wurden folgende vier Cookies
          gesetzt, ohne bei YouTube angemeldet zu sein:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> YSC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          b9-CV6ojI5111240294Y
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert eine eindeutige ID, um Statistiken des
          gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> PREF
          <br />
          <strong className="adsimple-111240294">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt
          über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
          verwenden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> GPS
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um
          den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          11124029495Chz8bagyU
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten
          (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Cookies wurden nach einem Test gesetzt und können nicht den Anspruch
          auf Vollständigkeit erheben.
        </p>
        <p>
          Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele
          Ihrer Handlungen/Interaktionen auf unserer Webseite mit Hilfe von
          Cookies speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt
          dadurch zum Beispiel Informationen wie lange Sie auf unserer Seite
          surfen, welchen Browsertyp Sie verwenden, welche Bildschirmauflösung
          Sie bevorzugen oder welche Handlungen Sie ausführen.
        </p>
        <p>
          YouTube verwendet diese Daten zum einen um die eigenen
          Dienstleistungen und Angebote zu verbessern, zum anderen um Analysen
          und Statistiken für Werbetreibende (die Google Ads verwenden)
          bereitzustellen.
        </p>
        <h2 className="adsimple-111240294">Twitter Datenschutzerklärung</h2>
        <p>
          Auf unserer Webseite haben wir Funktionen von Twitter eingebaut. Dabei
          handelt es sich zum Beispiel um eingebettete Tweets, Timelines,
          Buttons oder Hashtags. Twitter ist ein Kurznachrichtendienst und eine
          Social-Media-Plattform der Firma Twitter Inc., One Cumberland Place,
          Fenian Street, Dublin 2 D02 AX07, Irland.
        </p>
        <p>
          Nach unserer Kenntnis werden im Europäischen Wirtschaftsraum und in
          der Schweiz durch das bloße Einbinden von Twitter-Funktion noch keine
          personenbezogenen Daten oder Daten zu Ihrer Webaktivitäten an Twitter
          übertragen. Erst wenn Sie mit den Twitter-Funktionen interagieren, wie
          zum Beispiel auf einen Button klicken, können Daten an Twitter
          gesendet, dort gespeichert und verarbeitet werden. Auf diese
          Datenverarbeitung haben wir keinen Einfluss und tragen keine
          Verantwortung. Im Rahmen dieser Datenschutzerklärung wollen wir Ihnen
          einen Überblick geben, welche Daten Twitter speichert, was Twitter mit
          diesen Daten macht und wie Sie sich vor der Datenübertragung
          weitgehend schützen können.
        </p>
        <h3 className="adsimple-111240294">Was ist Twitter?</h3>
        <p>
          Für die einen ist Twitter ein Nachrichtendienst, für andere eine
          Social-Media-Plattform und wieder andere sprechen von einem
          Microblogging-Dienst. All diese Bezeichnungen haben ihre Berechtigung
          und meinen mehr oder weniger dasselbe.
        </p>
        <p>
          Sowohl Privatpersonen als auch Unternehmen nützen Twitter, um mit
          interessierten Personen über Kurznachrichten zu kommunizieren. Pro
          Nachricht erlaubt Twitter nur 280 Zeichen. Diese Nachrichten werden
          „Tweets“ genannt. Anders als beispielsweise bei Facebook fokussiert
          sich der Dienst nicht auf den Ausbau eines Netzwerks für “Freunde”,
          sondern will als weltweite und offene Nachrichten-Plattform verstanden
          werden. Bei Twitter kann man auch ein anonymes Konto führen und Tweets
          können einerseits vom Unternehmen, andererseits von den Usern selbst
          gelöscht werden.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Twitter auf unserer Webseite?
        </h3>
        <p>
          Wie viele andere Webseiten und Unternehmen versuchen wir unserer
          Services und Dienstleistungen über verschiedene Kanäle anzubieten und
          mit unseren Kunden zu kommunizieren. Speziell Twitter ist uns als
          nützlicher „kleiner“ Nachrichtendienst ans Herz gewachsen. Immer
          wieder tweeten oder retweeten wir spannende, lustige oder interessante
          Inhalte. Uns ist klar, dass Sie nicht jeden Kanal extra folgen können.
          Schließlich haben Sie auch noch etwas anderes zu tun. Darum haben wir
          auf unserer Webseite auch Twitter-Funktionen eingebunden. Sie können
          unsere Twitter-Aktivität „vor Ort“ miterleben oder über einen direkten
          Link zu unserer Twitter-Seite kommen. Durch die Einbindung wollen wir
          unser Service und die Nutzerfreundlichkeit auf unserer Webseite
          stärken.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Twitter gespeichert?
        </h3>
        <p>
          Auf manchen unserer Unterseiten finden Sie die eingebauten
          Twitter-Funktionen. Wenn Sie mit den Twitter-Inhalten interagieren,
          wie zum Beispiel auf einen Button klicken, kann Twitter Daten erfassen
          und speichern. Und zwar auch dann, wenn Sie selbst kein Twitter-Konto
          haben. Twitter nennt diese Daten “Log-Daten”. Dazu zählen
          demografische Daten, Browser-Cookie-IDs, die ID Ihres Smartphones,
          gehashte E-Mail-Adressen, und Informationen, welche Seiten Sie bei
          Twitter besucht haben und welche Handlungen Sie ausgeführt haben.
          Twitter speichert natürlich mehr Daten, wenn Sie ein Twitter-Konto
          haben und angemeldet sind. Meistens passiert diese Speicherung über
          Cookies. Cookies sind kleine Text-Dateien, die meist in Ihrem Browser
          gesetzt werden und unterschiedliche Information an Twitter
          übermitteln.
        </p>
        <p>
          Welche Cookies gesetzt werden, wenn Sie nicht bei Twitter angemeldet
          sind, aber eine Webseite mit eingebauten Twitter-Funktionen besuchen,
          zeigen wir Ihnen jetzt. Bitte betrachten Sie diese Aufzählung als
          Beispiel. Einen Anspruch auf Vollständigkeit können wir hier auf
          keinen Fall gewährleisten, da sich die Wahl der Cookies stets
          verändert und von Ihren individuellen Handlungen mit den
          Twitter-Inhalten abhängt.
        </p>
        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          personalization_id
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          “v1_cSJIsogU51SeE111240294”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und
          über welche Werbung Sie möglicherweise zu Twitter gekommen sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>lang
          <br />
          <strong className="adsimple-111240294">Wert: </strong>de
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Ihre voreingestellte bzw. bevorzugte Sprache.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>guest_id
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          111240294v1%3A157132626
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird gesetzt, um Sie als Gast zu identifizieren.
          <strong className="adsimple-111240294"> </strong>
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>fm
          <br />
          <strong className="adsimple-111240294">Wert: </strong>0<br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Zu
          diesem Cookie konnten wir leider den Verwendungszweck nicht in
          Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>external_referer
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          1112402942beTA0sf5lkMrlGt
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie sammelt anonyme Daten, wie zum Beispiel wie oft Sie
          Twitter besuchen und wie lange Sie Twitter besuchen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>Nach 6
          Tage
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>eu_cn
          <br />
          <strong className="adsimple-111240294">Wert: </strong>1<br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Useraktivität und dient diversen Werbezwecken
          von Twitter.{" "}
          <strong className="adsimple-111240294">
            <br />
            Ablaufdatum:{" "}
          </strong>
          Nach einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>ct0
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          c1179f07163a365d2ed7aad84c99d966
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Zu
          diesem Cookie haben wir leider keine Informationen gefunden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 6
          Stunden
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>_twitter_sess
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          53D%253D–dd0248111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Mit
          diesem Cookie können Sie Funktionen innerhalb der Twitter-Webseite
          nutzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Twitter
          arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unsrem
          Test auch die drei Google-Analytics-Cookies _ga, _gat, _gid erkannt.
        </p>
        <p>
          Twitter verwendet die erhobenen Daten einerseits, um das Userverhalten
          besser zu verstehen und somit Ihre eigenen Dienste und Werbeangebote
          zu verbessern, andererseits dienen die Daten auch internen
          Sicherheitsmaßnahmen.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Wenn Twitter Daten von anderen Webseiten erhebt, werden diese nach
          maximal 30 Tagen gelöscht, zusammengefasst oder auf andere Weise
          verdeckt. Die Twitter-Server liegen auf verschiedenen Serverzentren in
          den Vereinigten Staaten. Demnach ist davon auszugehen, dass erhobenen
          Daten in Amerika gesammelt und gespeichert werden. Nach unserer
          Recherche konnten wir nicht eindeutig feststellen, ob Twitter auch
          eigene Server in Europa hat. Grundsätzlich kann Twitter die erhobenen
          Daten speichern, bis sie dem Unternehmen nicht mehr dienlich sind, Sie
          die Daten löschen oder eine gesetzliche Löschfrist besteht.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Twitter betont in Ihren Datenschutzrichtlinien immer wieder, dass sie
          keine Daten von externen Webseitenbesuchen speichert, wenn Sie bzw.
          Ihr Browser sich im europäischen Wirtschaftsraum oder in der Schweiz
          befinden. Falls Sie allerdings mit Twitter direkt interagieren,
          speichert Twitter selbstverständlich auch Daten von Ihnen.
        </p>
        <p>
          Wenn Sie ein Twitter-Konto besitzen, können Sie Ihre Daten verwalten,
          indem Sie unter dem „Profil“-Button auf „Mehr“ klicken. Anschließend
          klicken Sie auf „Einstellungen und Datenschutz“. Hier können Sie die
          Datenverarbeitung individuell verwalten.
        </p>
        <p>
          Wenn Sie kein Twitter-Konto besitzen, können Sie auf{" "}
          <a className="adsimple-111240294" href="https://twitter.com/">
            twitter.com
          </a>
           gehen und dann auf „Individualisierung“ klicken. Unter dem Punkt
          „Individualisierung und Daten“ können Sie Ihre erhobenen Daten
          verwalten.
        </p>
        <p>
          Die meisten Daten werden, wie oben bereits erwähnt, über Cookies
          gespeichert und die können Sie in Ihrem Browser verwalten,
          deaktivieren oder löschen. Bitte beachten Sie, dass Sie die Cookies
          nur in dem von Ihnen gewählten Browser “bearbeiten”. Das heißt:
          verwenden Sie in Zukunft einen anderen Browser, müssen Sie dort Ihre
          Cookies erneut nach Ihren Wünschen verwalten. Hier gibt es die
          Anleitung zur Cookie-Verwaltung der bekanntesten Browser.
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Ihren Browser können Sie auch so verwalten, dass Sie bei jedem
          einzelnen Cookie informiert werden. Dann können Sie immer individuell
          entscheiden, ob Sie ein Cookie zulassen oder nicht.
        </p>
        <p>
          Twitter verwendet die Daten auch für personalisierte Werbung in- und
          außerhalb von Twitter. In den Einstellungen können Sie unter
          „Individualisierung und Daten“ die personalisierte Werbung abschalten.
          Wenn Sie Twitter auf einem Browser nutzen, können Sie die
          personalisierte Werbung unter{" "}
          <a
            className="adsimple-111240294"
            href="http://optout.aboutads.info/?c=2&lang=EN"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://optout.aboutads.info/?c=2&lang=EN
          </a>{" "}
          deaktivieren.
        </p>
        <p>
          Twitter ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework.
          Dieses Framework stellt eine korrekte Datenübertragung zwischen den
          USA und der Europäischen Union sicher. Unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO
          </a>
           erfahren Sie mehr darüber.
        </p>
        <p>
          Wir hoffen, wir haben Ihnen einen grundsätzlichen Überblick über die
          Datenverarbeitung durch Twitter gegeben. Wir erhalten keinen Daten von
          Twitter und tragen auch keine Verantwortung darüber, was Twitter mit
          Ihren Daten macht. Falls Sie noch weitere Fragen zu diesem Thema
          haben, empfehlen wir Ihnen die Twitter-Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://twitter.com/de/privacy"
          >
            https://twitter.com/de/privacy
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">Gravatar Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf dieser Webseite Gravatar der Firma Automattic Inc.
          (60 29th Street #343, San Francisco, CA 94110, USA) um Benutzerbilder
          (Avatar) auf Basis der E-Mail-Adresse bei Beiträgen und Kommentaren
          zuzuordnen.
        </p>
        <p>
          Ein Gravatar ist ein <strong className="adsimple-111240294">G</strong>
          lobally <strong className="adsimple-111240294">
            R
          </strong>ecognized{" "}
          <strong className="adsimple-111240294">Avatar </strong>– ein weltweit
          verfügbares Benutzerbild – welches einer E-Mail-Adresse zugeordnet und
          bei deren Verwendung in verschiedenen Onlinediensten zum Einsatz
          kommen kann.
          <br />
          Da nur beim Einsatz einer bei Gravatar registrierten E-Mail-Adresse
          ein Bild angezeigt wird und somit Daten zu Gravatar übertragen werden,
          können Sie die Übertragung Ihrer Daten zu Gravatar verhindern, indem
          Sie mit einer bei Gravatar nicht registrierten E-Mail-Adresse
          kommentieren oder Beiträge verfassen.
        </p>
        <p>
          Automattic ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
          Framework wodurch der korrekte und sichere Datentransfer persönlicher
          Daten geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC
          </a>{" "}
          .<br />
          Mehr Details zur Datenschutzrichtlinie und welche Daten auf welche Art
          durch Gravatar erfasst werden finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://automattic.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://automattic.com/privacy/
          </a>
          , allgemeine Informationen zu Gravatar auf{" "}
          <a
            className="adsimple-111240294"
            href="http://de.gravatar.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://de.gravatar.com/
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google reCAPTCHA Datenschutzerklärung
        </h2>
        <p>
          Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns
          bestmöglich zu sichern und zu schützen. Um das zu gewährleisten,
          verwenden wir Google reCAPTCHA der Firma Google Inc. (1600
          Amphitheatre Parkway Mountain View, CA 94043, USA). Mit reCAPTCHA
          können wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch
          und Blut sind und kein Roboter oder eine andere Spam-Software. Unter
          Spam verstehen wir jede, auf elektronischen Weg, unerwünschte
          Information, die uns ungefragter Weise zukommt. Bei den klassischen
          CAPTCHAS mussten Sie zur Überprüfung meist Text- oder Bildrätsel
          lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen
          Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie
          einfach ein Häkchen setzen und so bestätigen, dass Sie kein Bot sind.
          Mit der neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr
          ein Häkchen setzen. Wie das genau funktioniert und vor allem welche
          Daten dafür verwendet werden, erfahren Sie im Verlauf dieser
          Datenschutzerklärung.
        </p>
        <h3 className="adsimple-111240294">Was ist reCAPTCHA?</h3>
        <p>
          reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor
          Spam-Software und den Missbrauch durch nicht-menschliche Besucher
          schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie
          Formulare im Internet ausfüllen. Ein Captcha-Dienst ist eine Art
          automatischer Turing-Test, der sicherstellen soll, dass eine Handlung
          im Internet von einem Menschen und nicht von einem Bot vorgenommen
          wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan
          Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch
          fest. Bei Captchas übernimmt das auch der Computer bzw. ein
          Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben,
          die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche
          Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel
          mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen
          von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich
          bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst
          das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den
          Quelltext eingebunden und dann läuft das Tool im Hintergrund und
          analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet
          die Software einen sogenannten Captcha-Score. Google berechnet mit
          diesem Score schon vor der Captcha-Eingabe wie hoch die
          Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw.
          Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots
          gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.)
          manipulieren oder missbrauchen könnten.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir reCAPTCHA auf unserer Webseite?
        </h3>
        <p>
          Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite
          begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen
          getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns
          zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie
          anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma
          Google. So können wir uns ziemlich sicher sein, dass wir eine
          „botfreie“ Webseite bleiben. Durch die Verwendung von reCAPTCHA werden
          Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich
          ein Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite
          und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte
          es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot
          möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren
          oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA
          können wir solche Botangriffe vermeiden.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von reCAPTCHA gespeichert?
        </h3>
        <p>
          reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen,
          ob die Handlungen auf unserer Webseite auch wirklich von Menschen
          stammen. Es kann also die IP-Adresse und andere Daten, die Google für
          den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen
          werden innerhalb der Mitgliedstaaten der EU oder anderer
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          fast immer zuvor gekürzt, bevor die Daten auf einem Server in den USA
          landen. Die IP-Adresse wird nicht mit anderen Daten von Google
          kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit
          Ihrem Google-Konto angemeldet sind. Zuerst prüft der
          reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von
          anderen Google-Diensten (YouTube. Gmail usw.) platziert sind.
          Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser
          und erfasst einen Schnappschuss Ihres Browserfensters.
        </p>
        <p>
          Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht
          den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von
          Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Referrer URL (die Adresse der Seite von der der Besucher kommt)
          </li>
          <li className="adsimple-111240294">
            IP-Adresse (z.B. 256.123.123.1)
          </li>
          <li className="adsimple-111240294">
            Infos über das Betriebssystem (die Software, die den Betrieb Ihres
            Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS
            X oder Linux)
          </li>
          <li className="adsimple-111240294">
            Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
          </li>
          <li className="adsimple-111240294">
            Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder
            der Tastatur ausführen wird gespeichert)
          </li>
          <li className="adsimple-111240294">
            Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie
            auf Ihrem PC voreingestellt haben wird gespeichert)
          </li>
          <li className="adsimple-111240294">
            Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die
            Webseiten ermöglicht, sich an den User anzupassen.
            JavaScript-Objekte können alle möglichen Daten unter einem Namen
            sammeln)
          </li>
          <li className="adsimple-111240294">
            Bildschirmauflösung (zeigt an aus wie vielen Pixeln die
            Bilddarstellung besteht)
          </li>
        </ul>
        <p>
          Unumstritten ist, dass Google diese Daten verwendet und analysiert
          noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der
          Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der
          ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche
          Daten Google genau speichert, erfährt man von Google nicht im Detail.
        </p>
        <p>
          Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir
          uns auf die reCAPTCHA Demo-Version von Google unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/recaptcha/api2/demo"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/recaptcha/api2/demo
          </a>
          . All diese Cookies benötigen zu Trackingzwecken eine eindeutige
          Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der
          Demo-Version gesetzt hat:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> IDE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-111240294-8
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird von der Firma DoubleClick (gehört auch Google)
          gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit
          Werbeanzeigen zu registrieren und zu melden. So kann die
          Werbewirksamkeit gemessen und entsprechende Optimierungsmaßnahmen
          getroffen werden. IDE wird in Browsern unter der Domain
          doubleclick.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> 1P_JAR
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 2019-5-14-12
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie sammelt Statistiken zur Webseite-Nutzung und misst
          Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem
          Käufer wird. Das Cookie wird auch verwendet, um Usern relevante
          Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie vermeiden,
          dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> ANID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           U7j1v3dZa1112402940xgZFmiqWppRWKOr
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Viele Infos konnten wir über dieses Cookie nicht in Erfahrung bringen.
          In der Datenschutzerklärung von Google wird das Cookie im Zusammenhang
          mit „Werbecookies“ wie z. B. “DSID”, “FLC”, “AID”, “TAID” erwähnt.
          ANID wird unter Domain google.com gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 9
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie speichert den Status der Zustimmung eines Users zur Nutzung
          unterschiedlicher Services von Google. CONSENT dient auch der
          Sicherheit, um User zu überprüfen, Betrügereien von
          Anmeldeinformationen zu verhindern und Userdaten vor unbefugten
          Angriffen zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 19
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> NID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0WmuWqy111240294zILzqV_nmt3sDXwPeM5Q
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> NID
          wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche
          anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist
          eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen.
          So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie
          enthält eine einzigartige ID, um persönliche Einstellungen des Users
          für Werbezwecke zu sammeln.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 6
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> DV
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          gEAABBCjJMXcI0dSAAAANbqc111240294-4
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Sobald Sie das „Ich bin kein Roboter“-Häkchen angekreuzt haben, wird
          dieses Cookie gesetzt. Das Cookie wird von Google Analytics für
          personalisierte Werbung verwendet. DV sammelt Informationen in
          anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen
          zu treffen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 10
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung: </strong>Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den
          Google-Server übertragen. Wo genau diese Daten gespeichert werden,
          stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar.
          Ohne eine Bestätigung von Google erhalten zu haben, ist davon
          auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der
          Webseite oder Spracheinstellungen auf den europäischen oder
          amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die
          Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit
          anderen Google-Daten aus weiteren Google-Diensten zusammengeführt.
          Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei
          Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt.
          <strong className="adsimple-111240294"> </strong>Dafür gelten die
          abweichenden Datenschutzbestimmungen der Firma Google.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an
          Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite
          besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen
          ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die
          Daten sobald Sie unsere Seite aufrufen automatisch an Google
          übermittelt. Um diese Daten wieder zu löschen, müssen Sie den
          Google-Support auf {" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/?hl=de&tid=111240294"
          >
            https://support.google.com/?hl=de&tid=111240294
          </a>{" "}
          kontaktieren.
        </p>
        <p>
          Wenn Sie also unsere Webseite verwenden, erklären Sie sich
          einverstanden, dass Google LLC und deren Vertreter automatisch Daten
          erheben, bearbeiten und nutzen.
        </p>
        <p>
          Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von
          Google auf{" "}
          <a
            className="adsimple-111240294"
            href="https://developers.google.com/recaptcha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/recaptcha/
          </a>
          . Google geht hier zwar auf die technische Entwicklung der reCAPTCHA
          näher ein, doch genaue Informationen über Datenspeicherung und
          datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute
          Übersicht über die grundsätzliche Verwendung von Daten bei Google
          finden Sie in der hauseigenen Datenschutzerklärung auf{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>
          .
        </p>
        <p>
          Quelle: Erstellt mit dem Datenschutz Generator von{" "}
          <a
            title="Datenschutz Generator von adsimple.at"
            href="https://www.adsimple.at"
            target="_blank"
            rel="noopener noreferrer"
          >
            Content Marketing AdSimple
          </a>
           in Kooperation mit{" "}
          <a
            href="https://www.bauguide.at"
            target="_blank"
            rel="noopener noreferrer"
            title=""
          >
            bauguide.at
          </a>
        </p>
      </div>
    );
  }
  es() {
    return (
      <div>
        <br />
        <br />
        <h1 className="adsimple-111240294">Política de Privacidad</h1>
        <h2 className="adsimple-111240294">política de privacidad</h2>
        <p>
          Tenemos esta declaración de protección de datos (versión
          19.01.2020-111240294) escrito para servirle de acuerdo con los
          requisitos de {""}
          <a
            className="adsimple-111240294"
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutz-Grundverordnung (EU) 2016/679
          </a>
          {""}explicar qué información recopilamos, cómo usamos los datos y qué
          opciones de decisión tiene usted como visitante de este sitio
          webtener.
        </p>
        <p>
          Desafortunadamente, es en la naturaleza de las cosas que estas
          explicaciones son muy suena técnico, pero tratamos de crear el
          Describa las cosas más importantes de la manera más simple y clara
          posible.
        </p>
        <h2 className="adsimple-111240294">Automatische Datenspeicherung</h2>
        <p>
          Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
          automatisch erstellt und gespeichert, so auch auf dieser Webseite.
        </p>
        <p>
          Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
          Webserver (Computer auf dem diese Webseite gespeichert ist)
          automatisch Daten wie
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            die Adresse (URL) der aufgerufenen Webseite
          </li>
          <li className="adsimple-111240294">Browser und Browserversion</li>
          <li className="adsimple-111240294">das verwendete Betriebssystem</li>
          <li className="adsimple-111240294">
            die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
          </li>
          <li className="adsimple-111240294">
            den Hostname und die IP-Adresse des Geräts von welchem aus
            zugegriffen wird
          </li>
          <li className="adsimple-111240294">Datum und Uhrzeit</li>
        </ul>
        <p>in Dateien (Webserver-Logfiles).</p>
        <p>
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
          danach automatisch gelöscht. Wir geben diese Daten nicht weiter,
          können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
          rechtswidrigem Verhalten eingesehen werden.
        </p>
        <h2 className="adsimple-111240294">Speicherung persönlicher Daten</h2>
        <p>
          Persönliche Daten, die Sie uns auf dieser Website elektronisch
          übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
          andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars
          oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt
          und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher
          verwahrt und nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit
          jenen Besuchern, die Kontakt ausdrücklich wünschen und für die
          Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
          Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht
          weiter, können jedoch nicht ausschließen, dass diese Daten beim
          Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        </p>
        <p>
          Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits
          dieser Webseite – können wir keine sichere Übertragung und den Schutz
          Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
          niemals unverschlüsselt per E-Mail zu übermitteln.
        </p>
        <h2 className="adsimple-111240294">
          Rechte laut Datenschutzgrundverordnung
        </h2>
        <p>
          Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen{" "}
          <a
            className="adsimple-111240294"
            href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzgesetzes (DSG)
          </a>{" "}
          grundsätzlich die folgende Rechte zu:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Recht auf Berichtigung (Artikel 16 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit
            der Berichtigung oder Löschung personenbezogener Daten oder der
            Einschränkung der Verarbeitung (Artikel 19 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Widerspruchsrecht (Artikel 21 DSGVO)
          </li>
          <li className="adsimple-111240294">
            Recht, nicht einer ausschließlich auf einer automatisierten
            Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
            unterworfen zu werden (Artikel 22 DSGVO)
          </li>
        </ul>
        <p>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
          Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
          sonst in einer Weise verletzt worden sind, können Sie sich bei der
          Aufsichtsbehörde beschweren, welche in Österreich die
          Datenschutzbehörde ist, deren Webseite Sie unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.dsb.gv.at/?tid=111240294"
          >
            https://www.dsb.gv.at/
          </a>{" "}
          finden.
        </p>
        <h2 className="adsimple-111240294">
          Auswertung des Besucherverhaltens
        </h2>
        <p>
          In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob
          und wie wir Daten Ihres Besuchs dieser Website auswerten. Die
          Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir
          können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person
          schließen.
        </p>
        <p>
          Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu
          widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
        </p>
        <h2 className="adsimple-111240294">TLS-Verschlüsselung mit https</h2>
        <p>
          Wir verwenden https um Daten abhörsicher im Internet zu übertragen
          (Datenschutz durch Technikgestaltung{" "}
          <a
            className="adsimple-111240294"
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Artikel 25 Absatz 1 DSGVO
          </a>
          ). Durch den Einsatz von TLS (Transport Layer Security), einem
          Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
          können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen
          die Benutzung dieser Absicherung der Datenübertragung am kleinen
          Schlosssymbol links oben im Browser und der Verwendung des Schemas
          https (anstatt http) als Teil unserer Internetadresse.
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf unserer Website das Analyse-Tracking Tool Google
          Analytics (GA) des amerikanischen Unternehmens Google LLC (1600
          Amphitheatre Parkway Mountain View, CA 94043, USA). Google Analytics
          sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie
          beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie
          gespeichert und an Google Analytics versandt. Mithilfe der Berichte,
          die wir von Google Analytics erhalten, können wir unsere Website und
          unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir
          näher auf das Tracking Tool ein und informieren Sie vor allem darüber,
          welche Daten gespeichert werden und wie Sie das verhindern können.
        </p>
        <h3 className="adsimple-111240294">Was ist Google Analytics?</h3>
        <p>
          Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse
          unserer Website dient. Damit Google Analytics funktioniert, wird ein
          Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
          Website besuchen, zeichnet dieser Code verschiedene Handlungen auf,
          die Sie auf unserer Website ausführen. Sobald Sie unsere Website
          verlassen, werden diese Daten an die Google-Analytics-Server gesendet
          und dort gespeichert.
        </p>
        <p>
          Google verarbeitet die Daten und wir bekommen Berichte über Ihr
          Userverhalten. Dabei kann es sich unter anderem um folgende Berichte
          handeln:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User
            besser kennen und wissen genauer, wer sich für unser Service
            interessiert.
          </li>
          <li className="adsimple-111240294">
            Anzeigeberichte: Durch Anzeigeberichte können wir unsere
            Onlinewerbung leichter analysieren und verbessern.
          </li>
          <li className="adsimple-111240294">
            Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche
            Informationen darüber, wie wir mehr Menschen für unser Service
            begeistern können.
          </li>
          <li className="adsimple-111240294">
            Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website
            interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer
            Seite zurücklegen und welche Links Sie anklicken.
          </li>
          <li className="adsimple-111240294">
            Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie
            aufgrund einer Marketing-Botschaft eine gewünschte Handlung
            ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher
            zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser
            Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen
            bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
          </li>
          <li className="adsimple-111240294">
            Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf
            unserer Website passiert. Zum Beispiel sehen wir wie viele User
            gerade diesen Text lesen.
          </li>
        </ul>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google Analytics auf unserer Webseite?
        </h3>
        <p>
          Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
          bestmögliche Service bieten. Die Statistiken und Daten von Google
          Analytics helfen uns dieses Ziel zu erreichen.
        </p>
        <p>
          Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den
          Stärken und Schwächen unserer Website. Einerseits können wir unsere
          Seite so optimieren, dass sie von interessierten Menschen auf Google
          leichter gefunden wird. Andererseits helfen uns die Daten, Sie als
          Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
          unserer Website verbessern müssen, um Ihnen das bestmögliche Service
          zu bieten. Die Daten dienen uns auch, unsere Werbe- und
          Marketing-Maßnahmen individueller und kostengünstiger durchzuführen.
          Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen
          Menschen zu zeigen, die sich dafür interessieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Google Analytics gespeichert?
        </h3>
        <p>
          Google Analytics erstellt mithilfe eines Tracking-Codes eine
          zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist.
          So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste
          Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User
          erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID
          gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile
          auszuwerten.
        </p>
        <p>
          Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre
          Interaktionen auf unserer Website gemessen. Interaktionen sind alle
          Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
          auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können
          über Google Analytics generierte Daten mit Drittanbieter-Cookies
          verknüpft werden. Google gibt keine Google Analytics-Daten weiter,
          außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es
          kommen, wenn es gesetzlich erforderlich ist.
        </p>
        <p>Folgende Cookies werden von Google Analytics verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _ga
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          2.1326744211.152111240294-5
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu
          speichern. Grundsätzlich dient es zur Unterscheidung der
          Webseitenbesucher.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          2.1687193234.152111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie dient auch zur Unterscheidung der Webseitenbesucher
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 24
          Stunden
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gat_gtag_UA_
          {"<property-id>"}
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Wird
          zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über
          den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den
          Namen _dc_gtm_ {"<property-id>"}.<br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 1
          Minute
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> AMP_TOKEN
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angaben
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst
          abgerufen werden kann. Andere mögliche Werte weisen auf eine
          Abmeldung, eine Anfrage oder einen Fehler hin.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Sekunden bis zu einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utma
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
          1564498958.1564498958.1564498958.1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Mit
          diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und sie
          Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn
          Informationen an Google Analytics gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmt
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird wie _gat_gtag_UA_{"<property-id>"} zum Drosseln der
          Anforderungsrate verwendet.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 10
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmb
          <br />
          <strong className="adsimple-111240294">Wert:</strong>3.10.1564498958
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird
          jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics
          gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmc
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 167421564
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende
          Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und
          wird nur solange gespeichert, bis Sie den Browser wieder schließen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> Nach
          Schließung des Browsers
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmz
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          m|utmccn=(referral)|utmcmd=referral|utmcct=/
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird verwendet, um die Quelle des Besucheraufkommens auf
          unserer Website zu identifizieren. Das heißt, das Cookie speichert,
          von wo Sie auf unsere Website gekommen sind. Das kann eine andere
          Seite bzw. eine Werbeschaltung gewesen sein.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 6
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> __utmv
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern.
          Es wird immer aktualisiert, wenn Informationen an Google Analytics
          gesendet werden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <p>
          Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die
          mit Google Analytics erhoben werden:
        </p>
        <p>
          <strong className="adsimple-111240294">Heatmaps:</strong> Google legt
          sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche,
          die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer
          Seite „unterwegs“ sind.
        </p>
        <p>
          <strong className="adsimple-111240294">Sitzungsdauer:</strong> Als
          Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite
          verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv
          waren, endet die Sitzung automatisch.
        </p>
        <p>
          <strong className="adsimple-111240294">Absprungrate</strong> (engl.
          Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer
          Website nur eine Seite ansehen und dann unsere Website wieder
          verlassen.
        </p>
        <p>
          <strong className="adsimple-111240294">Kontoerstellung:</strong> Wenn
          Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung
          machen, erhebt Google Analytics diese Daten.
        </p>
        <p>
          <strong className="adsimple-111240294">IP-Adresse:</strong> Die
          IP-Adresse wird nur in gekürzter Form dargestellt, damit keine
          eindeutige Zuordnung möglich ist.
        </p>
        <p>
          <strong className="adsimple-111240294">Standort:</strong> Über die
          IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden.
          Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.
        </p>
        <p>
          <strong className="adsimple-111240294">
            Technische Informationen:
          </strong>{" "}
          Zu den technischen Informationen zählen unter anderem Ihr Browsertyp,
          Ihr Internetanbieter oder Ihre Bildschirmauflösung.
        </p>
        <p>
          <strong className="adsimple-111240294">Herkunftsquelle:</strong>{" "}
          Google Analytics beziehungsweise uns interessiert natürlich auch über
          welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.
        </p>
        <p>
          Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen
          von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das
          Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren
          Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und
          dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch
          Google Analytics.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten
          Server befinden sich in Amerika und folglich werden Ihr Daten meist
          auf amerikanischen Servern gespeichert. Hier können Sie genau
          nachlesen wo sich die Google-Rechenzentren befinden:{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>
        </p>
        <p>
          Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt.
          Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor
          Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt
          es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise
          die Hardware bei Google ausfällt oder Naturkatastrophen Server
          lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google
          dennoch gering.
        </p>
        <p>
          Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer
          Userdaten von 26 Monaten eingestellt. Dann werden Ihre Userdaten
          gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer
          von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur
          Verfügung:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Löschung nach 14 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 26 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 38 Monaten</li>
          <li className="adsimple-111240294">Löschung nach 50 Monaten</li>
          <li className="adsimple-111240294">Keine automatische Löschung</li>
        </ul>
        <p>
          Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat
          die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die
          mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
          DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf
          aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
          Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu
          einer größeren Einheit.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht,
          Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen
          oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung
          von Google Analytics-JavaScript (ga.js, analytics.js, dc.js)
          verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das
          Browser-Add-on können Sie unter{" "}
          <a
            className="adsimple-111240294"
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>{" "}
          runterladen und installieren. Beachten Sie bitte, dass durch dieses
          Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
        </p>
        <p>
          Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics)
          deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser
          eine eigene Anleitung:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
          Framework, wodurch der korrekte und sichere Datentransfer persönlicher
          Daten geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=111240294
          </a>
          . Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um
          die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr
          über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden
          Links:{" "}
          <a
            className="adsimple-111240294"
            href="http://www.google.com/analytics/terms/de.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.google.com/analytics/terms/de.html
          </a>{" "}
          und{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics IP-Anonymisierung
        </h2>
        <p>
          Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von
          Google Analytics implementiert. Diese Funktion wurde von Google
          entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen
          und Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn
          diese eine Speicherung der vollständigen IP-Adresse untersagen. Die
          Anonymisierung bzw. Maskierung der IP findet statt, sobald die
          IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und
          bevor eine Speicherung oder Verarbeitung der Daten stattfindet.
        </p>
        <p>
          Mehr Informationen zur IP-Anonymisierung finden Sie auf 
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/2763052?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/2763052?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Berichte zu demografischen Merkmalen und Interessen
        </h2>
        <p>
          Wir haben in Google Analytics die Funktionen für Werbeberichte
          eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen
          enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können
          wir uns – ohne diese Daten einzelnen Personen zuordnen zu können – ein
          besseres Bild von unseren Nutzern machen. Mehr über die
          Werbefunktionen erfahren Sie{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad"
            target="_blank"
            rel="noopener noreferrer"
          >
            auf https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad
          </a>
          .
        </p>
        <p>
          Sie können die Nutzung der Aktivitäten und Informationen Ihres Google
          Kontos unter “Einstellungen für Werbung” auf{" "}
          <a
            className="adsimple-111240294"
            href="https://adssettings.google.com/authenticated"
          >
            https://adssettings.google.com/authenticated
          </a>{" "}
          per Checkbox beenden.
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Deaktivierungslink
        </h2>
        <p>
          Wenn Sie auf folgenden{" "}
          <strong className="adsimple-111240294">Deaktivierungslink</strong>{" "}
          klicken, können Sie verhindern, dass Google weitere Besuche auf dieser
          Webseite erfasst. Achtung: Das Löschen von Cookies, die Nutzung des
          Inkognito/Privatmodus ihres Browsers, oder die Nutzung eines anderen
          Browsers führt dazu, dass wieder Daten erhoben werden.
        </p>
        <span
          className="adsimple-111240294"
          //   style="cursor: help; border: 0 none; border-bottom-width: 1px; border-style: dashed;"
          title="Es wurde kein UA-Code eingegeben. Bitten Sie den Administrator, dieses Problem zu beheben!"
        >
          Google Analytics deaktivieren
        </span>
        <p> </p>
        <h2 className="adsimple-111240294">
          Google Analytics Zusatz zur Datenverarbeitung
        </h2>
        <p>
          Wir haben mit Google einen Direktkundenvertrag zur Verwendung von
          Google Analytics abgeschlossen, indem wir den “Zusatz zur
          Datenverarbeitung” in Google Analytics akzeptiert haben.
        </p>
        <p>
          Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden
          Sie hier:{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad
          </a>
        </p>
        <h2 className="adsimple-111240294">
          Google Analytics Google-Signale Datenschutzerklärung
        </h2>
        <p>
          Wir haben in Google Analytics die Google-Signale aktiviert. So werden
          die bestehenden Google-Analytics-Funktionen (Werbeberichte,
          Remarketing, gerätübergreifende Berichte und Berichte zu Interessen
          und demografische Merkmale) aktualisiert, um zusammengefasste und
          anonymisierte Daten von Ihnen zu erhalten, sofern Sie personalisierte
          Anzeigen in Ihrem Google-Konto erlaubt haben.
        </p>
        <p>
          Das besondere daran ist, dass es sich dabei um ein
          Cross-Device-Tracking handelt. Das heißt Ihre Daten können
          geräteübergreifend analysiert werden. Durch die Aktivierung von
          Google-Signale werden Daten erfasst und mit dem Google-Konto
          verknüpft. Google kann dadurch zum Beispiel erkennen, wenn Sie auf
          unsere Webseite über ein Smartphone ein Produkt ansehen und erst
          später über einen Laptop das Produkt kaufen. Dank der Aktivierung von
          Google-Signale können wir gerätübergreifende Remarketing-Kampagnen
          starten, die sonst in dieser Form nicht möglich wären. Remarketing
          bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot
          zeigen können.
        </p>
        <p>
          In Google Analytics werden zudem durch die Google-Signale weitere
          Besucherdaten wie Standort, Suchverlauf, YouTube-Verlauf und Daten
          über Ihre Handlungen auf unserer Webseite, erfasst. Wir erhalten
          dadurch von Google bessere Werbeberichte und nützlichere Angaben zu
          Ihren Interessen und demografischen Merkmalen. Dazu gehören Ihr Alter,
          welche Sprache sie sprechen, wo Sie wohnen oder welchem Geschlecht Sie
          angehören. Weiters kommen auch noch soziale Kriterien wie Ihr Beruf,
          Ihr Familienstand oder Ihr Einkommen hinzu. All diese Merkmal helfen
          Google Analytics Personengruppen bzw. Zielgruppen zu definieren.
        </p>
        <p>
          Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und
          Interessen besser einschätzen zu können. Dadurch können wir unsere
          Dienstleistungen und Produkte für Sie optimieren und anpassen. Diese
          Daten laufen standardmäßig nach 26 Monaten ab. Bitte beachten Sie,
          dass diese Datenerfassung nur erfolgt, wenn Sie personalisierte
          Werbung in Ihrem Google-Konto zugelassen haben. Es handelt sich dabei
          immer um zusammengefasste und anonyme Daten und nie um Daten einzelner
          Personen. In Ihrem Google-Konto können Sie diese Daten verwalten bzw.
          auch löschen.
        </p>
        <h2 className="adsimple-111240294">
          Facebook-Pixel Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook.
          Dafür haben wir einen Code auf unserer Webseite implementiert. Der
          Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine
          Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen
          verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite
          gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer
          Webseite erwerben, wird das Facebook-Pixel ausgelöst und speichert
          Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies.
          Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie
          IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen.
          Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für
          uns anonym und nicht einsehbar und werden nur im Rahmen von
          Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind
          und angemeldet sind, wird der Besuch unserer Webseite automatisch
          Ihrem Facebook-Benutzerkonto zugeordnet.
        </p>
        <p>
          Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen
          zeigen, die sich auch wirklich dafür interessieren. Mithilfe von
          Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche
          und Interessen abgestimmt werden. So bekommen Facebook-User (sofern
          sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen.
          Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und
          eigenen Werbeanzeigen.
        </p>
        <p>
          Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden
          von Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachten
          Sie, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf
          unserer Webseite werden unterschiedliche Cookies gesetzt.
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _fbp
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          fb.1.1568287647279.257405483-6111240294-7
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie verwendet Facebook, um Werbeprodukte anzuzeigen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0aPf312HOS5Pboo2r..Bdeiuf…1.0.Bdeiuf.
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit Facebook-Pixel auch ordentlich
          funktioniert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_50ae8267e2bdf1253ec1a5769f48e062111240294-3
          <br />
          <strong className="adsimple-111240294">Wert:</strong> Name des Autors
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert den Text und den Namen eines Users, der
          beispielsweise einen Kommentar hinterlässt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_url_50ae8267e2bdf1253ec1a5769f48e062
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          https%3A%2F%2Fwww.testseite…%2F (URL des Autors)
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert die URL der Website, die der User in einem
          Textfeld auf unserer Webseite eingibt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          comment_author_email_50ae8267e2bdf1253ec1a5769f48e062
          <br />
          <strong className="adsimple-111240294">Wert:</strong> E-Mail-Adresse
          des Autors
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert die E-Mail-Adresse des Users, sofern er sie
          auf der Website bekannt gegeben hat.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 12
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung: </strong>Die oben
          genannten Cookies beziehen sich auf ein individuelles Userverhalten.
          Speziell bei der Verwendung von Cookies sind Veränderungen bei
          Facebook nie auszuschließen.
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>{" "}
          grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
          haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
          aktivieren.
        </p>
        <p>
          Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
          empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Facebook Automatischer erweiterter Abgleich Datenschutzerklärung
        </h2>
        <p>
          Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen
          erweiterten Abgleich (engl. Automatic Advanced Matching) aktiviert.
          Diese Funktion des Pixels ermöglicht uns, gehashte E-Mails, Namen,
          Geschlecht, Stadt, Bundesland, Postleitzahl und Geburtsdatum oder
          Telefonnummer als zusätzliche Informationen an Facebook zu senden,
          sofern Sie uns diese Daten zur Verfügung gestellt haben. Diese
          Aktivierung ermöglicht uns Werbekampagnen auf Facebook noch genauer
          auf Menschen, die sich für unsere Dienstleistungen oder Produkte
          interessieren, anzupassen.
        </p>
        <h2 className="adsimple-111240294">Newsletter Datenschutzerklärung</h2>
        <p>
          <span className="adsimple-111240294">
            Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die
            oben genannten persönlichen Daten und geben uns das Recht Sie per
            E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter
            gespeicherten Daten nutzen wir ausschließlich für unseren Newsletter
            und geben diese nicht weiter.
          </span>
        </p>
        <p>
          <span className="adsimple-111240294">
            Sollten Sie sich vom Newsletter abmelden – Sie finden den Link dafür
            in jedem Newsletter ganz unten – dann löschen wir alle Daten die mit
            der Anmeldung zum Newsletter gespeichert wurden.
          </span>
        </p>
        <h2 className="adsimple-111240294">
          Google AdSense Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden auf dieser Webseite Google AdSense. Das ist ein
          Anzeigenprogramm der Firma Google Inc. (1600 Amphitheatre Parkway
          Mountain View, CA 94043, USA). Mit Google AdSense können wir auf
          dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema passen.
          So bieten wir Ihnen Anzeigen, die im Idealfall einen richtigen
          Mehrwert für Sie darstellen. Im Zuge dieser Datenschutzerklärung über
          Google AdSense erklären wir Ihnen, warum wir Google AdSense auf
          unserer Webseite verwenden, welche Daten von Ihnen verarbeitet und
          gespeichert werden und wie Sie diese Datenspeicherung unterbinden
          können.
        </p>
        <h3 className="adsimple-111240294">Was ist Google AdSense?</h3>
        <p>
          Das Werbeprogramm Google AdSense gibt es mittlerweile seit 2003. Im
          Gegensatz zu Google Ads (früher: Google AdWords) kann man hier nicht
          selbst Werbung schalten. Über Google AdSense werden Werbeanzeigen auf
          Webseiten, wie zum Beispiel auf unserer, ausgespielt. Der größte
          Vorteil dieses Werbedienstes im Vergleich zu manch anderen ist, dass
          Ihnen Google AdSense nur Anzeigen zeigt, die zu unseren Inhalten
          passen. Google hat einen eigenen Algorithmus, der berechnet, welche
          Werbeanzeigen Sie zu Gesicht bekommen. Natürlich wollen wir Ihnen nur
          Werbung bieten, die Sie auch interessiert und Ihnen einen Mehrwert
          bietet. Google überprüft anhand Ihrer Interessen bzw. Ihres
          Userverhaltens und anhand unseres Angebots, welche Werbeanzeigen für
          unsere Webseite und für unserer User geeignet sind. An dieser Stelle
          wollen wir auch gleich erwähnen, dass wir für die Auswahl der
          Werbeanzeigen nicht verantwortlich sind. Wir bieten mit unserer
          Webseite lediglich die Werbefläche an. Die Auswahl der angezeigten
          Werbung trifft Google. Seit August 2013 werden die Anzeigen auch an
          die jeweilige Benutzeroberfläche angepasst. Das heißt, egal ob Sie von
          Ihrem Smartphone, Ihrem PC oder Laptop unsere Webseite besuchen, die
          Anzeigen passen sich an Ihr Endgerät an.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google AdSense auf unserer Webseite?
        </h3>
        <p>
          Das Betreiben einer hochwertigen Webseite erfordert viel Hingabe und
          großen Einsatz. Im Grunde sind wir mit der Arbeit an unserer Webseite
          nie fertig. Wir versuchen stets unsere Seite zu pflegen und so aktuell
          wie möglich zu halten. Natürlich wollen wir mit dieser Arbeit auch
          einen wirtschaftlichen Erfolg erzielen. Darum haben wir uns für
          Werbeanzeigen als Einnahmequelle entschieden. Das Wichtigste für uns
          ist allerdings, Ihren Besuch auf unserer Webseite durch diese Anzeigen
          nicht zu stören. Mithilfe von Google AdSense wird Ihnen nur Werbung
          angeboten, die zu unseren Themen und Ihren Interessen passt.
        </p>
        <p>
          Ähnlich wie bei der Google-Indexierung für eine Webseite, untersucht
          ein Bot den entsprechenden Content und die entsprechenden Angebote
          unserer Webseite. Dann werden die Werbeanzeigen inhaltlich angepasst
          und auf der Webseite präsentiert. Neben den inhaltlichen
          Überschneidungen zwischen Anzeige und Webseiten-Angebot unterstützt
          AdSense auch interessensbezogenes Targeting. Das bedeutet, dass Google
          auch Ihre Daten dazu verwendet, um auf Sie zugeschnittene Werbung
          anzubieten. So erhalten Sie Werbung, die Ihnen im Idealfall einen
          echten Mehrwert bietet und wir haben eine höhere Chance ein bisschen
          etwas zu verdienen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Google AdSense gespeichert?
        </h3>
        <p>
          Damit Google AdSense eine maßgeschneiderte, auf Sie angepasste Werbung
          anzeigen kann, werden unter anderem Cookies verwendet. Cookies sind
          kleine Textdateien, die bestimmte Informationen auf Ihrem Computer
          speichern.
        </p>
        <p>
          In AdSense sollen Cookies bessere Werbung ermöglichen. Die Cookies
          enthalten keine personenidentifizierbaren Daten. Hierbei ist
          allerdings zu beachten, dass Google Daten wie zum Beispiel “Pseudonyme
          Cookie-IDs” (Name oder anderes Identifikationsmerkmal wird durch ein
          Pseudonym ersetzt) oder IP-Adressen als nicht personenidentifizierbare
          Informationen ansieht. Im Rahmen der DSGVO können diese Daten
          allerdings als personenbezogene Daten gelten. Google AdSense sendet
          nach jeder Impression (das ist immer dann der Fall, wenn Sie eine
          Anzeige sehen), jedem Klick und jeder anderen Aktivität, die zu einem
          Aufruf der Google AdSense-Server führt, ein Cookie an den Browser.
          Sofern der Browser das Cookie akzeptiert, wird es dort gespeichert.
        </p>
        <p>
          Drittanbieter können im Rahmen von AdSense unter Umständen Cookies in
          Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um
          Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der
          Webseite erhalten. Als Web-Beacons bezeichnet man kleine Grafiken, die
          eine Logdatei-Analyse und eine Aufzeichnung der Logdatei machen. Diese
          Analyse ermöglicht eine statistische Auswertung für das
          Online-Marketing.
        </p>
        <p>
          Google kann über diese Cookies bestimmte Informationen über Ihr
          Userverhalten auf unserer Webseite sammeln. Dazu zählen:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Informationen wie Sie mit einer Anzeige umgehen (Klicks, Impression,
            Mausbewegungen)
          </li>
          <li className="adsimple-111240294">
            Informationen, ob in Ihrem Browser schon eine Anzeige zu einem
            früheren Zeitpunkt erschienen ist. Diese Daten helfen dabei, Ihnen
            eine Anzeige nicht öfter anzuzeigen.
          </li>
        </ul>
        <p>
          Dabei analysiert Google die Daten zu den angezeigten Werbemitteln und
          Ihre IP-Adresse und wertet diese aus. Google verwendet die Daten in
          erster Linie, um die Effektivität einer Anzeige zu messen und das
          Werbeangebot zu verbessern. Diese Daten werden nicht mit
          personenbezogenen Daten, die Google möglicherweise über andere
          Google-Dienste von Ihnen hat, verknüpft.
        </p>
        <p>
          Im Folgenden stellen wir Ihnen Cookies vor, die Google AdSense für
          Trackingzwecke verwendet. Hierbei beziehen wir uns auf eine
          Test-Webseite, die ausschließlich Google AdSense installiert hat:
          <strong className="adsimple-111240294"> </strong>
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> uid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          891269189111240294-8
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird unter der Domain adform.net gespeichert. Es stellt eine
          eindeutig zugewiesene, maschinell generierte User-ID bereit und
          sammelt Daten über die Aktivität auf unserer Webseite.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> C<br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie identifiziert, ob Ihrer Browser Cookies akzeptiert. Das
          Cookie wird unter der Domain track.adform.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> cid
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          8912691894970695056,0,0,0,0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird unter der Domain track.adform.net gespeichert,
          steht für Client-ID und wird verwendet, um die Werbung für Sie zu
          verbessern. Es kann relevantere Werbung an den Besucher weiterleiten
          und hilft, die Berichte über die Kampagnenleistung zu verbessern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> IDE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          zOtj4TWxwbFDjaATZ2TzNaQmxrU111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie wird unter der Domain doubkeklick.net gespeichert. Es dient
          dazu, Ihre Aktionen nach der Anzeige bzw. nach dem Klicken der Anzeige
          zu registrieren. Dadurch kann man messen, wie gut eine Anzeige bei
          unseren Besuchern ankommt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> test_cookie
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Mithilfe des „test_cookies“ kann man überprüfen, ob Ihr Browser
          überhaupt Cookies unterstützt. Das Cookie wird unter der Domain
          doubleklick.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 1
          Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CT592996
          <br />
          <strong className="adsimple-111240294">Wert:</strong>733366
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Wird
          unter der Domain adform.net gespeichert. Das Cookie wird gesetzt
          sobald Sie auf eine Werbeanzeige klicken. Genauere Informationen über
          die Verwendung dieses Cookies konnten wir nicht in Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einer Stunde
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Google erfasst Ihre IP-Adresse und verschiedene Aktivitäten, die Sie
          auf der Webseite ausführen. Cookies speichern diese Informationen zu
          den Interaktionen auf unsere Webseite. Laut Google sammelt und
          speichert das Unternehmen die angegebenen Informationen auf sichere
          Weise auf den hauseigenen Google-Servern in den USA.
        </p>
        <p>
          Wenn Sie kein Google-Konto haben bzw. nicht angemeldet sind, speichert
          Google die erhobenen Daten mit einer eindeutigen Kennung (ID) meist
          auf Ihrem Browser. Die in Cookies gespeicherten eindeutigen IDs dienen
          beispielsweise dazu, personalisierte Werbung zu gewährleisten. Wenn
          Sie in einem Google-Konto angemeldet sind, kann Google auch
          personenbezogene Daten erheben.
        </p>
        <p>
          Einige der Daten, die Google speichert, können Sie jederzeit wieder
          löschen (siehe nächsten Abschnitt). Viele Informationen, die in
          Cookies gespeichert sind, werden automatisch nach einer bestimmten
          Zeit wieder gelöscht. Es gibt allerdings auch Daten, die von Google
          über einen längeren Zeitraum gespeichert werden. Dies ist dann der
          Fall, wenn Google aus wirtschaftlichen oder rechtlichen
          Notwendigkeiten, gewisse Daten über einen unbestimmten, längeren
          Zeitraum speichern muss.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben immer die Möglichkeit Cookies, die sich auf Ihrem Computer
          befinden, zu löschen oder zu deaktivieren. Wie genau das funktioniert
          hängt von Ihrem Browser ab.
        </p>
        <p>
          Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
          verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
          Herunterladen und Installieren dieses Browser-Plug-ins auf{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/ads/answer/7395996"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/ads/answer/7395996
          </a>{" "}
          werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass
          Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
          verhindern, sondern nur die personalisierte Werbung.
        </p>
        <p>
          Wenn Sie ein Google Konto besitzen, können Sie auf der Webseite{" "}
          <a
            className="adsimple-111240294"
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://adssettings.google.com/authenticated
          </a>{" "}
          personalisierte Werbung deaktivieren. Auch hier sehen Sie dann weiter
          Anzeigen, allerdings sind diese nicht mehr an Ihre Interessen
          angepasst. Dennoch werden die Anzeigen auf der Grundlage von ein paar
          Faktoren, wie Ihrem Standort, dem Browsertyp und der verwendeten
          Suchbegriffe, angezeigt.
        </p>
        <p>
          Welche Daten Google grundsätzlich erfasst und wofür sie diese Daten
          verwenden, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>{" "}
          nachlesen.
        </p>
        <h2 className="adsimple-111240294">
          Amazon-Partnerprogramm Datenschutzerklärung
        </h2>
        <p>
          Wir sind Teilnehmer am Amazon Partnerprogramm welches die Platzierung
          von Amazon-Werbeanzeigen und Partnerlinks auf Webseiten ermöglicht.
          Durch diese Platzierung von Amazon Werbung wird eine
          Werbekostenerstattung erzielt.
          <br />
          Um die Herkunft von Bestellungen nachvollziehen zu können, setzt
          Amazon Cookies ein.
          <br />
          Verantwortliche Stelle im Sinne der Datenschutzgesetze sind die Amazon
          Europe Core S.à.r.l., die Amazon EU S.à.r.l, die Amazon Services
          Europe S.à.r.l. und die Amazon Media EU S.à.r.l., alle vier ansässig
          5, Rue Plaetis, L-2338 Luxemburg sowie Amazon Instant Video Germany
          GmbH, Domagkstr. 28, 80807 München. Als Datenverarbeiter wird in ihrem
          Auftrag die Amazon Deutschland Services GmbH, Marcel-Breuer-Str. 12,
          80807 München, tätig.
          <br />
          Die Datenschutzrichtlinien, welche Informationen Amazon sammelt und
          wie sie diese verwenden finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401"
          >
            https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung
        </h2>
        <p>
          Wir verwenden als Online-Marketing-Maßnahme Google Ads (früher Google
          AdWords), um unsere Produkte und Dienstleistungen zu bewerben. So
          wollen wir im Internet mehr Menschen auf die hohe Qualität unserer
          Angebote aufmerksam machen. Im Rahmen unserer Werbe-Maßnahmen durch
          Google Ads verwenden wir auf unserer Webseite das Conversion-Tracking
          der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
          USA (“Google”). Mithilfe dieses kostenlosen Tracking-Tools können wir
          unser Werbeangebot an Ihre Interessen und Bedürfnisse  besser
          anpassen. Im Folgenden Artikel wollen wir genauer darauf eingehen,
          warum wir Conversion-Tracking benutzen, welche Daten dabei gespeichert
          werden und wie Sie diese Datenspeicherung verhindern.
        </p>
        <h3 className="adsimple-111240294">
          Was ist Google Ads Conversion-Tracking?
        </h3>
        <p>
          Google Ads (früher Google AdWords) ist das hauseigene
          Online-Werbesystem der Firma Google LLC. Wir sind von der Qualität
          unseres Angebots überzeugt und wollen, dass so viele Menschen wie
          möglich unsere Webseite kennenlernen. Im Onlinebereich bietet Google
          Ads dafür die beste Plattform. Natürlich wollen wir auch einen genauen
          Überblick über den Kosten-Nutzen-Faktor unsere Werbeaktionen gewinnen.
          Darum verwenden wir das Conversion-Tracking-Tool von Google Ads.
        </p>
        <p>
          Doch was ist eine Conversion eigentlich? Eine Conversion entsteht,
          wenn Sie von einem rein interessierten Webseitenbesucher zu einem
          handelnden Besucher werden. Dies passiert immer dann, wenn Sie auf
          unsere Anzeige klicken und im Anschluss eine andere Aktion ausführen,
          wie zum Beispiel unsere Webseite besuchen. Mit dem
          Conversion-Tracking-Tool von Google erfassen wir, was nach einem Klick
          eines Users auf unsere Google Ads-Anzeige geschieht. Zum Beispiel
          können wir so sehen, ob Produkte gekauft werden, Dienstleistungen in
          Anspruch genommen werden oder ob sich User für unseren Newsletter
          angemeldet haben.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Google Ads Conversion-Tracking auf unserer
          Webseite?
        </h3>
        <p>
          Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser
          Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen
          wirklich auch nur jene Menschen erreichen, die sich für unsere
          Angebote interessieren. Mit dem Conversion-Tracking Tool sehen wir
          welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den
          gewünschten Kundenaktionen führen. Wir sehen wie viele Kunden mit
          unseren Anzeigen auf einem Gerät interagieren und dann eine Conversion
          durchführen. Durch diese Daten können wir unseren Kosten-Nutzen-Faktor
          berechnen, den Erfolg einzelner Werbemaßnahmen messen und folglich
          unsere Online-Marketing-Maßnahmen optimieren. Wir können weiters
          mithilfe der gewonnenen Daten unsere Webseite für Sie interessanter
          gestalten und unser Werbeangebot noch individueller an Ihre
          Bedürfnisse anpassen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?
        </h3>
        <p>
          Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer
          Webseite eingebunden, um gewisse User-Aktionen besser analysieren zu
          können. Wenn Sie nun eine unserer Google Ads-Anzeigen anklicken, wird
          auf Ihrem Computer (meist im Browser) oder Mobilgerät das Cookie
          „Conversion“ von einer Google-Domain gespeichert. Cookies sind kleine
          Textdateien, die Informationen auf Ihrem Computer speichern.
        </p>
        <p>
          Hier die Daten der wichtigsten Cookies für das Conversion-Tracking von
          Google:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> Conversion
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ111240294-3
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert jede Conversion, die Sie auf unserer Seite
          machen, nachdem Sie über eine Google Ads zu uns gekommen sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _gac
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Dies
          ist ein klassisches Google Analytics-Cookie und dient dem Erfassen
          verschiedener Handlungen auf unserer Webseite.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Das Cookie
          _gac scheint nur in Verbindung mit Google Analytics auf. Die oben
          angeführte Aufzählung hat keinen Anspruch auf Vollständigkeit, da
          Google für analytische Auswertung immer wieder auch andere Cookies
          verwendet.
        </p>
        <p>
          Sobald Sie eine Aktion auf unserer Webseite abschließen, erkennt
          Google das Cookie und speichert Ihre Handlung als sogenannte
          Conversion. Solange Sie auf unserer Webseite surfen und das Cookie
          noch nicht abgelaufen ist, erkennen wir und Google, dass Sie über
          unsere Google-Ads-Anzeige zu uns gefunden haben. Das Cookie wird
          ausgelesen und mit den Conversion-Daten zurück an Google Ads gesendet.
          Es ist auch möglich, dass noch andere Cookies zur Messung von
          Conversions verwendet werden. Das Conversion-Tracking von Google Ads
          kann mithilfe von Google Analytics noch verfeinert und verbessert
          werden. Bei Anzeigen, die Google an verschiedenen Orten im Web
          anzeigt, werden unter unserer Domain möglicherweise Cookies mit dem
          Namen “__gads” oder “_gac” gesetzt. Seit September 2017 werden diverse
          Kampagneninformationen von analytics.js mit dem _gac-Cookie
          gespeichert. Das Cookie speichert diese Daten, sobald Sie eine unserer
          Seiten aufrufen, für die die automatische Tag-Kennzeichnung von Google
          Ads eingerichtet wurde. Im Gegensatz zu Cookies, die für
          Google-Domains gesetzt werden, kann Google diese Conversion-Cookies
          nur lesen, wenn Sie sich auf unserer Webseite befinden. Wir erheben
          und erhalten keine personenbezogenen Daten. Wir bekommen von Google
          einen Bericht mit statistischen Auswertungen. So erfahren wir
          beispielsweise die Gesamtanzahl der User, die unsere Anzeige
          angeklickt haben und wir sehen, welche Werbemaßnahmen gut ankamen.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss
          darauf haben, wie Google die erhobenen Daten weiterverwendet. Laut
          Google werden die Daten verschlüsselt und auf sicheren Servern
          gespeichert. In den meisten Fällen laufen Conversion-Cookies nach 30
          Tagen ab und übermitteln keine personenbezogenen Daten. Die Cookies
          mit dem Namen „Conversion“ und „_gac“ (das in Verbindung mit Google
          Analytics zum Einsatz kommt) haben ein Ablaufdatum von 3 Monaten.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Sie haben die Möglichkeit am Conversion-Tracking von Google Ads nicht
          teilzunehmen. Wenn Sie das Cookie des Google Conversion-Tracking über
          Ihren Browser deaktivieren, blockieren Sie das Conversion-Tracking. In
          diesem Fall werden Sie in der Statistik des Tracking-Tools nicht
          berücksichtigt. Sie können die Cookie-Einstellungen in Ihrem Browser
          jederzeit verändern. Bei jedem Browser funktioniert dies etwas anders.
          Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
          verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
          Herunterladen und Installieren dieses Browser-Plug-ins auf{" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/ads/answer/7395996"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/ads/answer/7395996
          </a>{" "}
          werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass
          Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
          verhindern, sondern nur die personalisierte Werbung.
        </p>
        <p>
          Durch die Zertifizierung für das amerikanische-europäische
          Datenschutzübereinkommen “Privacy Shield”, muss der amerikanische
          Konzern Google LLC die in der EU geltenden Datenschutzgesetze
          einhalten. Wenn Sie Näheres über den Datenschutz bei Google erfahren
          möchten, empfehlen wir die allgemeine Datenschutzerklärung von Google:{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Eingebettete Social Media Elemente Datenschutzerklärung
        </h2>
        <p>
          Wir binden auf unserer Webseite Elemente von Social Media Diensten ein
          um Bilder, Videos und Texte anzuzeigen.
          <br />
          Durch den Besuch von Seiten die diese Elemente darstellen, werden
          Daten von Ihrem Browser zum jeweiligen Social Media Dienst übertragen
          und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
          <br />
          Die folgenden Links führen Sie zu den Seiten der jeweiligen Social
          Media Dienste wo erklärt wird, wie diese mit Ihren Daten umgehen:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Instagram-Datenschutzrichtlinie:{" "}
            <a
              className="adsimple-111240294"
              href="https://help.instagram.com/519522125107875?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://help.instagram.com/519522125107875
            </a>
          </li>
          <li className="adsimple-111240294">
            Für YouTube gilt die Google Datenschutzerklärung:{" "}
            <a
              className="adsimple-111240294"
              href="https://policies.google.com/privacy?hl=de&tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://policies.google.com/privacy?hl=de
            </a>
          </li>
          <li className="adsimple-111240294">
            Facebook-Datenrichtline: 
            <a
              className="adsimple-111240294"
              href="https://www.facebook.com/about/privacy?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/about/privacy
            </a>
          </li>
          <li className="adsimple-111240294">
            Twitter Datenschutzrichtlinie: 
            <a
              className="adsimple-111240294"
              href="https://twitter.com/de/privacy?tid=111240294"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://twitter.com/de/privacy
            </a>
          </li>
        </ul>
        <h2 className="adsimple-111240294">Facebook Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf unserer Webseite ausgewählte Facebook Tools von
          Facebook. Facebook ist ein Social Media Network des Unternehmens
          Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
          Dublin 2 Ireland. Mithilfe dieser Tools können wir Ihnen und Menschen,
          die sich für unsere Produkte und Dienstleistungen interessieren, das
          bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick
          über die verschiedenen Facebook Tools, welche Daten an Facebook
          gesendet werden und wie Sie diese Daten löschen können.
        </p>
        <h3 className="adsimple-111240294">Was sind Facebook-Tools?</h3>
        <p>
          Neben vielen anderen Produkten bietet Facebook auch die sogenannten
          “Facebook Business Tools” an. Das ist die offizielle Bezeichnung von
          Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür
          entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden
          sich unter anderem:
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Facebook-Pixel</li>
          <li className="adsimple-111240294">
            soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)
          </li>
          <li className="adsimple-111240294">Facebook Login</li>
          <li className="adsimple-111240294">Account Kit</li>
          <li className="adsimple-111240294">
            APIs (Programmierschnittstelle)
          </li>
          <li className="adsimple-111240294">
            SDKs (Sammlung von Programmierwerkzeugen)
          </li>
          <li className="adsimple-111240294">Plattform-Integrationen</li>
          <li className="adsimple-111240294">Plugins</li>
          <li className="adsimple-111240294">Codes</li>
          <li className="adsimple-111240294">Spezifikationen</li>
          <li className="adsimple-111240294">Dokumentationen</li>
          <li className="adsimple-111240294">
            Technologien und Dienstleistungen
          </li>
        </ul>
        <p>
          Durch diese Tools erweitert Facebook Dienstleistungen und hat die
          Möglichkeit, Informationen über User-Aktivitäten außerhalb von
          Facebook zu erhalten.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Facebook-Tools auf unserer Webseite?
        </h3>
        <p>
          Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen,
          die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen
          (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den
          Usern passende Werbung gezeigt werden kann, benötigt Facebook
          allerdings Informationen über die Wünsche und Bedürfnisse der
          Menschen. So werden dem Unternehmen Informationen über das
          Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung
          gestellt. Dadurch sammelt Facebook bessere User-Daten und kann
          interessierten Menschen die passende Werbung über unsere Produkte bzw.
          Dienstleistungen anzeigen. Die Tools ermöglichen somit
          maßgeschneiderte Werbekampagnen auf Facebook.
        </p>
        <p>
          Daten über Ihr Verhalten auf unserer Webseite nennt Facebook
          „Event-Daten“. Diese werden auch für Messungs- und Analysedienste
          verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“
          über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen
          wir durch Analysen einen besseren Einblick, wie Sie unsere
          Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren
          wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer
          Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte
          auf unserer Seite direkt auf Facebook teilen.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Facebook-Tools gespeichert?
        </h3>
        <p>
          Durch die Nutzung einzelner Facebook-Tools können personenbezogene
          Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den
          benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer
          und IP-Adresse versandt werden.
        </p>
        <p>
          Facebook verwendet diese Informationen, um die Daten mit den ihren
          eigenen Daten, die es von Ihnen hat (sofern Sie Facebook-Mitglied
          sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden,
          erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig
          großer Datensatz in eine Zeichenkette transformiert wird. Dies dient
          auch der Verschlüsselung von Daten.
        </p>
        <p>
          Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter
          „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf
          unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie
          besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die
          erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise
          Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung
          oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit
          Kontaktdaten verbunden werden. Dadurch kann Facebook bessere
          personalisierte Werbung anbieten. Nach dem bereits erwähnten
          Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.
        </p>
        <p>
          Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook
          die Event-Daten nur, wenn diese mit anderen Daten (die auf andere
          Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese
          Event-Daten nützt Facebook auch für Sicherheits-, Schutz-,
          Entwicklungs- und Forschungszwecken. Viele dieser Daten werden über
          Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die
          zum Speichern von Daten bzw. Informationen in Browsern verwendet
          werden. Je nach verwendeten Tools und abhängig, ob Sie
          Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem
          Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools
          gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine
          Informationen über die Verwendung von Facebook-Cookies erfahren Sie
          auch auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policies/cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policies/cookies
          </a>
          .
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die
          eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat
          auf der ganzen Welt Server verteilt, wo Ihre Daten gespeichert werden.
          Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten
          abgeglichen wurden, innerhalb von 48 Stunden gelöscht.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf
          Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.
        </p>
        <p>
          Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr
          Facebook-Konto vollständig löschen. Und so funktioniert das Löschen
          Ihres Facebook-Kontos:
        </p>
        <p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
        <p>
          2) Anschließend klicken Sie in der linken Spalte auf „Deine
          Facebook-Informationen“.
        </p>
        <p>3) Nun klicken Sie “Deaktivierung und Löschung”.</p>
        <p>
          4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter
          und Konto löschen“
        </p>
        <p>
          5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann
          auf „Konto löschen“
        </p>
        <p>
          Die Speicherung der Daten, die Facebook über unsere Seite erhält,
          erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In
          Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren,
          löschen oder verwalten. Je nach dem welchen Browser Sie verwenden,
          funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
          Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie es erlauben oder nicht.
        </p>
        <p>
          Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework,
          wodurch der korrekte und sichere Datentransfer persönlicher Daten
          geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC
          </a>
          . Wir hoffen wir haben Ihnen die wichtigsten Informationen über die
          Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht.
          Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten
          verwendet, empfehlen wir Ihnen die Datenrichtlinien auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/update
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Facebook Login Datenschutzerklärung
        </h2>
        <p>
          Wir haben auf unserer Seite das praktische Facebook Login integriert.
          So können Sie sich bei uns ganz einfach mit Ihrem Facebook-Konto
          einloggen, ohne ein weiteres Benutzerkonto anlegen zu müssen. Wenn Sie
          sich entscheiden, Ihre Registrierung über das Facebook Login zu
          machen, werden Sie auf das Social Media Network Facebook
          weitergeleitet. Dort erfolgt die Anmeldung über Ihre Facebook
          Nutzerdaten. Durch dieses Login-Verfahren werden Daten über Sie bzw.
          Ihr Userverhalten gespeichert und an Facebook übermittelt.
        </p>
        <p>
          Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im
          Folgenden zeigen wir Ihnen die wichtigsten Cookies, die in Ihrem
          Browser gesetzt werden bzw. schon bestehen, wenn Sie sich über das
          Facebook Login auf unserer Seite anmelden:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0jieyh4c2GnlufEJ9..Bde09j…1.0.Bde09j
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit das sozialen Plugin auf unserer
          Webseite bestmöglich funktioniert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> datr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          4Jh7XUA2111240294SEmPsSfzCOO4JFFl
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Facebook setzt das “datr”-Cookie, wenn ein Webbrowser auf facebook.com
          zugreift, und das Cookie hilft, Anmeldeaktivitäten zu identifizieren
          und die Benutzer zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> _js_datr
          <br />
          <strong className="adsimple-111240294">Wert:</strong> deleted
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Session-Cookie setzt Facebook zu Trackingzwecken, auch wenn Sie
          kein Facebook-Konto haben oder ausgeloggt sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Die
          angeführten Cookies sind nur eine kleine Auswahl der Cookies, die
          Facebook zur Verfügung stehen. Weitere Cookies sind beispielsweise _
          fbp, sb oder wd. Eine vollständige Aufzählung ist nicht möglich, da
          Facebook über eine Vielzahl an Cookies verfügt und diese variabel
          einsetzt.
        </p>
        <p>
          Der Facebook Login bietet Ihnen einerseits einen schnellen und
          einfachen Registrierungsprozess, andererseits haben wir so die
          Möglichkeit Daten mit Facebook zu teilen. Dadurch können wir unser
          Angebot und unsere Werbeaktionen besser an Ihre Interessen und
          Bedürfnisse anpassen. Daten, die wir auf diese Weise von Facebook
          erhalten, sind öffentliche Daten wie
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">Ihr Facebook-Name</li>
          <li className="adsimple-111240294">Ihr Profilbild</li>
          <li className="adsimple-111240294">
            eine hinterlegte E-Mail-Adresse
          </li>
          <li className="adsimple-111240294">Freundeslisten</li>
          <li className="adsimple-111240294">
            Buttons-Angaben (z.B. „Gefällt mir“-Button)
          </li>
          <li className="adsimple-111240294">Geburtstagsdatum</li>
          <li className="adsimple-111240294">Sprache</li>
          <li className="adsimple-111240294">Wohnort</li>
        </ul>
        <p>
          Im Gegenzug stellen wir Facebook Informationen über Ihre Aktivitäten
          auf unserer Webseite bereit. Das sind unter anderem Informationen über
          Ihr verwendetes Endgerät, welche Unterseiten Sie bei uns besuchen oder
          welche Produkte Sie bei uns erworben haben.
        </p>
        <p>
          Durch die Verwendung von Facebook Login willigen Sie der
          Datenverarbeitung ein. Sie können diese Vereinbarung jederzeit
          widerrufen. Wenn Sie mehr Informationen über die Datenverarbeitung
          durch Facebook erfahren wollen, empfehlen wir Ihnen die
          Facebook-Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://de-de.facebook.com/policy.php?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/policy.php
          </a>
          .
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern.
        </p>
        <h2 className="adsimple-111240294">
          Facebook Soziale Plug-ins Datenschutzerklärung
        </h2>
        <p>
          Auf unserer Webseite sind sogenannte soziale Plug-ins des Unternehmens
          Facebook Inc. eingebaut. Sie erkennen diese Buttons am klassischen
          Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand mit erhobenem
          Daumen) oder an einer eindeutigen „Facebook Plug-in“-Kennzeichnung.
          Ein soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere
          Seite integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am
          meisten verwendeten Funktionen sind die bekannten “Gefällt mir”- und
          “Teilen”-Buttons.
        </p>
        <p>Folgende soziale Plug-ins werden von Facebook angeboten:</p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">“Speichern”-Button</li>
          <li className="adsimple-111240294">
            “Gefällt mir”-Button, Teilen, Senden und Zitat
          </li>
          <li className="adsimple-111240294">Seiten-Plug-in</li>
          <li className="adsimple-111240294">Kommentare</li>
          <li className="adsimple-111240294">Messenger-Plug-in</li>
          <li className="adsimple-111240294">
            Eingebettete Beiträge und Videoplayer
          </li>
          <li className="adsimple-111240294">Gruppen-Plug-in</li>
        </ul>
        <p>
          Auf{" "}
          <a
            className="adsimple-111240294"
            href="https://developers.facebook.com/docs/plugins"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.facebook.com/docs/plugins
          </a>{" "}
          erhalten Sie nähere Informationen, wie die einzelnen Plug-ins
          verwendet werden. Wir nützen die sozialen Plug-ins einerseits, um
          Ihnen ein besseres Usererlebnis auf unserer Seite zu bieten,
          andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren
          kann.
        </p>
        <p>
          Sofern Sie ein Facebook-Konto haben oder{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            facebook.com
          </a>{" "}
          schon mal besucht haben, hat Facebook bereits mindestens ein Cookie in
          Ihrem Browser gesetzt. In diesem Fall sendet Ihr Browser über dieses
          Cookie Informationen an Facebook, sobald Sie unsere Seite besuchen
          bzw. mit sozialen Plug-ins (z.B. dem „Gefällt mir“-Button)
          interagieren.
        </p>
        <p>
          Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder
          gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten Ihre
          IP-Adresse, welche Webseite Sie besucht haben, das Datum, die Uhrzeit
          und weitere Informationen, die Ihren Browser betreffen.
        </p>
        <p>
          Um zu verhindern, dass Facebook während Ihres Besuches auf unserer
          Webseite viele Daten sammelt und mit den Facebook-Daten verbindet,
          müssen Sie sich während des Webseitenbesuchs von Facebook abmelden
          (ausloggen).
        </p>
        <p>
          Falls Sie bei Facebook nicht angemeldet sind oder kein Facebook-Konto
          besitzen, sendet Ihr Browser weniger Informationen an Facebook, weil
          Sie weniger Facebook-Cookies haben. Dennoch können Daten wie
          beispielsweise Ihre IP-Adresse oder welche Webseite Sie besuchen an
          Facebook übertragen werden. Wir möchten noch ausdrücklich darauf
          hinweisen, dass wir über die genauen Inhalte der Daten nicht exakt
          Bescheid wissen. Wir versuchen aber Sie nach unserem aktuellen
          Kenntnisstand so gut als möglich über die Datenverarbeitung
          aufzuklären. Wie Facebook die Daten nutzt, können Sie auch in den
          Datenrichtline des Unternehmens unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/update
          </a>{" "}
          nachlesen.
        </p>
        <p>
          Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn Sie
          eine Webseite mit sozialen Plug-ins von Facebook besuchen:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> dpr
          <br />
          <strong className="adsimple-111240294">Wert:</strong> keine Angabe
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, damit die sozialen Plug-ins auf unserer
          Webseite funktionieren.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> fr
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0jieyh4111240294c2GnlufEJ9..Bde09j…1.0.Bde09j
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Auch
          das Cookie ist nötig, dass die Plug-ins einwandfrei funktionieren.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum::</strong> nach 3
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Cookies wurden nach einem Test gesetzt, auch wenn Sie nicht
          Facebook-Mitglied sind.
        </p>
        <p>
          Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
          für Werbeanzeigen unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>
           selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>
          grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
          haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
          aktivieren.
        </p>
        <p>
          Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
          empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.facebook.com/policy.php?tip=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">Instagram Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Webseite Funktionen von Instagram eingebaut.
          Instagram ist eine Social Media Plattform des Unternehmens Instagram
          LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012
          ein Tochterunternehmen von Facebook Inc. und gehört zu den
          Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer
          Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie
          Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite
          zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine
          Instagram-Funktion integriert haben, werden Daten an Instagram
          übermittelt, gespeichert und verarbeitet. Instagram verwendet
          dieselben Systeme und Technologien wie Facebook. Ihre Daten werden
          somit über alle Facebook-Firmen hinweg verarbeitet.
        </p>
        <p>
          Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum
          Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie
          die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu
          Facebook Inc. gehört, beziehen wir unsere Informationen einerseits von
          den Instagram-Richtlinien, andererseits allerdings auch von den
          Facebook-Datenrichtlinien selbst.
        </p>
        <h3 className="adsimple-111240294">Was ist Instagram?</h3>
        <p>
          Instagram ist eines der bekanntesten Social Media Netzwerken weltweit.
          Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von
          audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf
          „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und
          kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch
          in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht
          aktiv sein wollen, können Sie auch nur anderen interessante Users
          folgen.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Instagram auf unserer Webseite?
        </h3>
        <p>
          Instagram ist jene Social Media Plattform, die in den letzten Jahren
          so richtig durch die Decke ging. Und natürlich haben auch wir auf
          diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite
          so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche
          Aufbereitung unserer Inhalte selbstverständlich. Durch die
          eingebetteten Instagram-Funktionen können wir unseren Content mit
          hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt
          bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist,
          können uns die erhobenen Daten auch für personalisierte Werbung auf
          Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen,
          die sich wirklich für unsere Produkte oder Dienstleistungen
          interessieren.
        </p>
        <p>
          Instagram nützt die gesammelten Daten auch zu Messungs- und
          Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr
          Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen,
          dass diese Berichte Sie nicht persönlich identifizieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Instagram gespeichert?
        </h3>
        <p>
          Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie
          Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser
          automatisch mit den Servern von Instagram in Verbindung. Dabei werden
          Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar
          unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen
          Informationen über unserer Webseite, über Ihren Computer, über
          getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser
          Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer
          Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto
          haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten
          über Sie.
        </p>
        <p>
          Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen
          davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten
          sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese
          Kundendaten werden erst an Instagram übermittelt werden, wenn Sie
          zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine
          Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten
          verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“
          übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch
          Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass
          Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen
          Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen
          hat, abgeglichen.
        </p>
        <p>
          Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt
          werden, werden die gesammelten Daten an Facebook übermittelt. Je nach
          verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto
          haben, werden unterschiedlich viele Daten gespeichert.
        </p>
        <p>
          Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich
          funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein
          Instagram-Konto haben oder{" "}
          <a
            className="adsimple-111240294"
            href="http://www.instagram.com?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.instagram.com
          </a>{" "}
          besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das
          der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram,
          sobald Sie mit einer Instagram-Funktion in Berührung kommen.
          Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder
          gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der
          Datenverarbeitung von Instagram beschäftigt haben, können wir nicht
          ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.
        </p>
        <p>
          Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens
          gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button
          oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus,
          dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt
          sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.
        </p>
        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>csrftoken
          <br />
          <strong className="adsimple-111240294">Wert: </strong>“”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen
          gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten
          wir das allerdings nicht in Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>mid
          <br />
          <strong className="adsimple-111240294">Wert: </strong>“”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und
          Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt
          eine eindeutige User-ID fest.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          fbsr_111240294124024
          <br />
          <strong className="adsimple-111240294">Wert: </strong>keine Angaben
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.
          <strong className="adsimple-111240294">
            <br />
          </strong>
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> rur
          <br />
          <strong className="adsimple-111240294">Wert: </strong>ATN
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität
          auf Instagram gewährleistet.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> urlgen
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          {"“{”194.96.75.33”: 1901}"}:1iEtYv:Y833k2_UjKvXgYe111240294”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie dient den Marketingzwecken von Instagram.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach Ende
          der Sitzung
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Wir können
          hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im
          individuellen Fall gesetzt werden, hängt von den eingebetteten
          Funktionen und Ihrer Verwendung von Instagram ab.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Instagram teilt die erhaltenen Informationen zwischen den
          Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen
          Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter
          Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem
          aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt
          verteilt. Die meisten dieser Server stehen in den USA.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft,
          Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den
          Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre
          Daten auf Instagram völlig löschen wollen, müssen Sie Ihr
          Instagram-Konto dauerhaft löschen.
        </p>
        <p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
        <p>
          Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie
          nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf
          die Webseite des Unternehmens. Klicken Sie auf der Webseite auf
          „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.
        </p>
        <p>
          Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie
          beispielsweise Ihre Fotos und Status-Updates. Informationen, die
          andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto
          und werden folglich nicht gelöscht.
        </p>
        <p>
          Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster
          Linie über Cookies. Diese Cookies können Sie in Ihrem Browser
          verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser
          funktioniert die Verwaltung immer ein bisschen anders. Hier zeigen wir
          Ihnen die Anleitungen der wichtigsten Browser.
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie
          immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann
          können Sie immer individuell entscheiden, ob Sie das Cookie zulassen
          wollen oder nicht.
        </p>
        <p>
          Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook
          ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework. Dieses
          Framework stellt eine korrekte Datenübertragung zwischen den USA und
          der Europäischen Union sicher. Unter 
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC 
          </a>
           erfahren Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten
          Informationen über die Datenverarbeitung durch Instagram
          näherzubringen. Auf{" "}
          <a
            className="adsimple-111240294"
            href="https://help.instagram.com/519522125107875"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://help.instagram.com/519522125107875
          </a>
          <br />
          können Sie sich noch näher mit den Datenrichtlinien von Instagram
          auseinandersetzen.
        </p>
        <h2 className="adsimple-111240294">YouTube Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Webseite YouTube-Videos eingebaut. So können wir
          Ihnen interessante Videos direkt auf unserer Seite präsentieren.
          YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von
          Google LLC ist. Betrieben wird das Videoportal durch YouTube, LLC, 901
          Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Webseite
          eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet
          sich Ihr Browser automatisch mit den Servern von YouTube bzw. Google.
          Dabei werden (je nach Einstellungen) verschiedene Daten übertragen.
          Für die gesamte Datenverarbeitung ist Google verantwortlich und es
          gilt somit auch der Datenschutz von Google.
        </p>
        <p>
          Im Folgenden wollen wir Ihnen genauer erklären, welche Daten
          verarbeitet werden, warum wir YouTube-Videos eingebunden haben und wie
          Sie Ihre Daten verwalten oder löschen können.
        </p>
        <h3 className="adsimple-111240294">Was ist YouTube?</h3>
        <p>
          Auf YouTube können die User kostenlos Videos ansehen, bewerten,
          kommentieren und selbst hochladen. Über die letzten Jahre wurde
          YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit
          wir Videos auf unserer Webseite anzeigen können, stellt YouTube einen
          Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut
          haben.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir YouTube-Videos auf unserer Webseite?
        </h3>
        <p>
          YouTube ist die Videoplattform mit den meisten Besuchern und dem
          besten Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung
          auf unserer Webseite zu bieten. Und natürlich dürfen interessante
          Videos dabei nicht fehlen. Mithilfe unserer eingebetteten Videos
          stellen wir Ihnen neben unseren Texten und Bildern weiteren
          hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der
          Google-Suchmaschine durch die eingebetteten Videos leichter gefunden.
          Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google –
          dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen
          zeigen, die sich für unsere Angebote interessieren.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von YouTube gespeichert?
        </h3>
        <p>
          Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video
          eingebaut hat, setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse
          und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt
          sind, kann YouTube Ihre Interaktionen auf unserer Webseite meist
          mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie
          Sitzungsdauer, Absprungrate, ungefährer Standort, technische
          Informationen wie Browsertyp, Bildschirmauflösung oder Ihr
          Internetanbieter. Weitere Daten können Kontaktdaten, etwaige
          Bewertungen, das Teilen von Inhalten über Social Media oder das
          Hinzufügen zu Ihren Favoriten auf YouTube sein.
        </p>
        <p>
          Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto
          angemeldet sind, speichert Google Daten mit einer eindeutigen Kennung,
          die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt
          beispielsweise Ihre bevorzugte Spracheinstellung beibehalten. Aber
          viele Interaktionsdaten können nicht gespeichert werden, da weniger
          Cookies gesetzt werden.
        </p>
        <p>
          In der folgenden Liste zeigen wir Cookies, die in einem Test im
          Browser gesetzt wurden. Wir zeigen einerseits Cookies, die ohne
          angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir
          Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann
          keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von
          den Interaktionen auf YouTube abhängen.
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> YSC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          b9-CV6ojI5Y111240294-1
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert eine eindeutige ID, um Statistiken des
          gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> PREF
          <br />
          <strong className="adsimple-111240294">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt
          über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
          verwenden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> GPS
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um
          den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 95Chz8bagyU
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten
          (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monaten
        </p>
        <p>
          Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto
          angemeldet sind:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> APISID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          zILlvClZSkqGsSwI/AU1aZI6HY7111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie speichert den Status der Zustimmung eines Users zur Nutzung
          unterschiedlicher Services von Google. CONSENT dient auch der
          Sicherheit, um User zu überprüfen und Userdaten vor unbefugten
          Angriffen zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 19
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> HSID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AcRwpgUik9Dveht0I
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu
          können.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> LOGIN_INFO
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AFmmF2swRQIhALl6aL…
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> In
          diesem Cookie werden Informationen über Ihre Login-Daten gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SAPISID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Gerät
          eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre
          Interessen zu erstellen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          oQfNKjAsI111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten
          Anmeldezeitpunkt in digital signierter und verschlüsselter Form.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> SIDCC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          AN0-TYuqub2JOcDTyL
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und
          welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise
          gesehen haben.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 3
          Monaten
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den
          Google-Servern gespeichert. Die meisten dieser Server befinden sich in
          Amerika. Unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>{" "}
           sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten
          sind auf den Servern verteilt. So sind die Daten schneller abrufbar
          und vor Manipulation besser geschützt.
        </p>
        <p>
          Die erhobenen Daten speichert Google unterschiedlich lang. Manche
          Daten können Sie jederzeit löschen, andere werden automatisch nach
          einer begrenzten Zeit gelöscht und wieder andere werden von Google
          über längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine
          Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto
          gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen.
          Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie
          einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
          löschen.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit
          der 2019 eingeführten automatische Löschfunktion von Standort- und
          Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung –
          entweder 3 oder 18 Monate gespeichert und dann gelöscht.
        </p>
        <p>
          Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren
          Browser so konfigurieren, dass Cookies von Google gelöscht bzw.
          deaktiviert werden. Je nach dem welchen Browser Sie verwenden,
          funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
          Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
          Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
          gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
          entscheiden, ob Sie es erlauben oder nicht. Da YouTube ein
          Tochterunternehmen von Google ist, gibt es eine gemeinsame
          Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten
          erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de.
          </a>
        </p>
        <h2 className="adsimple-111240294">
          YouTube Abonnieren Button Datenschutzerklärung
        </h2>
        <p>
          Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl.
          „Subscribe-Button“) eingebaut. Sie erkennen den Button meist am
          klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in
          weißer Schrift die Wörter „Abonnieren“ oder „YouTube“ und links davon
          das weiße „Play-Symbol“. Der Button kann aber auch in einem anderen
          Design dargestellt sein.
        </p>
        <p>
          Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante
          oder spannende Videos. Mit dem eingebauten „Abonnieren-Button“ können
          Sie unseren Kanal direkt von unserer Webseite aus abonnieren und
          müssen nicht eigens die YouTube-Webseite aufrufen. Wir wollen Ihnen
          somit den Zugang zu unserem umfassenden Content so einfach wie möglich
          machen. Bitte beachten Sie, dass YouTube dadurch Daten von Ihnen
          speichern und verarbeiten kann.
        </p>
        <p>
          Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt
          YouTube – laut Google – mindestens ein Cookie. Dieses Cookie speichert
          Ihre IP-Adresse und unsere URL. Auch Informationen über Ihren Browser,
          Ihren ungefähren Standort und Ihre voreingestellte Sprache kann
          YouTube so erfahren. Bei unserem Test wurden folgende vier Cookies
          gesetzt, ohne bei YouTube angemeldet zu sein:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> YSC
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          b9-CV6ojI5111240294Y
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert eine eindeutige ID, um Statistiken des
          gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> PREF
          <br />
          <strong className="adsimple-111240294">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt
          über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
          verwenden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monate
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> GPS
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 1<br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um
          den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 30
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          11124029495Chz8bagyU
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten
          (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 8
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Diese
          Cookies wurden nach einem Test gesetzt und können nicht den Anspruch
          auf Vollständigkeit erheben.
        </p>
        <p>
          Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele
          Ihrer Handlungen/Interaktionen auf unserer Webseite mit Hilfe von
          Cookies speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt
          dadurch zum Beispiel Informationen wie lange Sie auf unserer Seite
          surfen, welchen Browsertyp Sie verwenden, welche Bildschirmauflösung
          Sie bevorzugen oder welche Handlungen Sie ausführen.
        </p>
        <p>
          YouTube verwendet diese Daten zum einen um die eigenen
          Dienstleistungen und Angebote zu verbessern, zum anderen um Analysen
          und Statistiken für Werbetreibende (die Google Ads verwenden)
          bereitzustellen.
        </p>
        <h2 className="adsimple-111240294">Twitter Datenschutzerklärung</h2>
        <p>
          Auf unserer Webseite haben wir Funktionen von Twitter eingebaut. Dabei
          handelt es sich zum Beispiel um eingebettete Tweets, Timelines,
          Buttons oder Hashtags. Twitter ist ein Kurznachrichtendienst und eine
          Social-Media-Plattform der Firma Twitter Inc., One Cumberland Place,
          Fenian Street, Dublin 2 D02 AX07, Irland.
        </p>
        <p>
          Nach unserer Kenntnis werden im Europäischen Wirtschaftsraum und in
          der Schweiz durch das bloße Einbinden von Twitter-Funktion noch keine
          personenbezogenen Daten oder Daten zu Ihrer Webaktivitäten an Twitter
          übertragen. Erst wenn Sie mit den Twitter-Funktionen interagieren, wie
          zum Beispiel auf einen Button klicken, können Daten an Twitter
          gesendet, dort gespeichert und verarbeitet werden. Auf diese
          Datenverarbeitung haben wir keinen Einfluss und tragen keine
          Verantwortung. Im Rahmen dieser Datenschutzerklärung wollen wir Ihnen
          einen Überblick geben, welche Daten Twitter speichert, was Twitter mit
          diesen Daten macht und wie Sie sich vor der Datenübertragung
          weitgehend schützen können.
        </p>
        <h3 className="adsimple-111240294">Was ist Twitter?</h3>
        <p>
          Für die einen ist Twitter ein Nachrichtendienst, für andere eine
          Social-Media-Plattform und wieder andere sprechen von einem
          Microblogging-Dienst. All diese Bezeichnungen haben ihre Berechtigung
          und meinen mehr oder weniger dasselbe.
        </p>
        <p>
          Sowohl Privatpersonen als auch Unternehmen nützen Twitter, um mit
          interessierten Personen über Kurznachrichten zu kommunizieren. Pro
          Nachricht erlaubt Twitter nur 280 Zeichen. Diese Nachrichten werden
          „Tweets“ genannt. Anders als beispielsweise bei Facebook fokussiert
          sich der Dienst nicht auf den Ausbau eines Netzwerks für “Freunde”,
          sondern will als weltweite und offene Nachrichten-Plattform verstanden
          werden. Bei Twitter kann man auch ein anonymes Konto führen und Tweets
          können einerseits vom Unternehmen, andererseits von den Usern selbst
          gelöscht werden.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir Twitter auf unserer Webseite?
        </h3>
        <p>
          Wie viele andere Webseiten und Unternehmen versuchen wir unserer
          Services und Dienstleistungen über verschiedene Kanäle anzubieten und
          mit unseren Kunden zu kommunizieren. Speziell Twitter ist uns als
          nützlicher „kleiner“ Nachrichtendienst ans Herz gewachsen. Immer
          wieder tweeten oder retweeten wir spannende, lustige oder interessante
          Inhalte. Uns ist klar, dass Sie nicht jeden Kanal extra folgen können.
          Schließlich haben Sie auch noch etwas anderes zu tun. Darum haben wir
          auf unserer Webseite auch Twitter-Funktionen eingebunden. Sie können
          unsere Twitter-Aktivität „vor Ort“ miterleben oder über einen direkten
          Link zu unserer Twitter-Seite kommen. Durch die Einbindung wollen wir
          unser Service und die Nutzerfreundlichkeit auf unserer Webseite
          stärken.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von Twitter gespeichert?
        </h3>
        <p>
          Auf manchen unserer Unterseiten finden Sie die eingebauten
          Twitter-Funktionen. Wenn Sie mit den Twitter-Inhalten interagieren,
          wie zum Beispiel auf einen Button klicken, kann Twitter Daten erfassen
          und speichern. Und zwar auch dann, wenn Sie selbst kein Twitter-Konto
          haben. Twitter nennt diese Daten “Log-Daten”. Dazu zählen
          demografische Daten, Browser-Cookie-IDs, die ID Ihres Smartphones,
          gehashte E-Mail-Adressen, und Informationen, welche Seiten Sie bei
          Twitter besucht haben und welche Handlungen Sie ausgeführt haben.
          Twitter speichert natürlich mehr Daten, wenn Sie ein Twitter-Konto
          haben und angemeldet sind. Meistens passiert diese Speicherung über
          Cookies. Cookies sind kleine Text-Dateien, die meist in Ihrem Browser
          gesetzt werden und unterschiedliche Information an Twitter
          übermitteln.
        </p>
        <p>
          Welche Cookies gesetzt werden, wenn Sie nicht bei Twitter angemeldet
          sind, aber eine Webseite mit eingebauten Twitter-Funktionen besuchen,
          zeigen wir Ihnen jetzt. Bitte betrachten Sie diese Aufzählung als
          Beispiel. Einen Anspruch auf Vollständigkeit können wir hier auf
          keinen Fall gewährleisten, da sich die Wahl der Cookies stets
          verändert und von Ihren individuellen Handlungen mit den
          Twitter-Inhalten abhängt.
        </p>
        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
        <p>
          <strong className="adsimple-111240294">Name:</strong>{" "}
          personalization_id
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          “v1_cSJIsogU51SeE111240294”
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und
          über welche Werbung Sie möglicherweise zu Twitter gekommen sind.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 2
          Jahre
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>lang
          <br />
          <strong className="adsimple-111240294">Wert: </strong>de
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Ihre voreingestellte bzw. bevorzugte Sprache.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>guest_id
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          111240294v1%3A157132626
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie wird gesetzt, um Sie als Gast zu identifizieren.
          <strong className="adsimple-111240294"> </strong>
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 2
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>fm
          <br />
          <strong className="adsimple-111240294">Wert: </strong>0<br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Zu
          diesem Cookie konnten wir leider den Verwendungszweck nicht in
          Erfahrung bringen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>external_referer
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          1112402942beTA0sf5lkMrlGt
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie sammelt anonyme Daten, wie zum Beispiel wie oft Sie
          Twitter besuchen und wie lange Sie Twitter besuchen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>Nach 6
          Tage
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>eu_cn
          <br />
          <strong className="adsimple-111240294">Wert: </strong>1<br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>
          Dieses Cookie speichert Useraktivität und dient diversen Werbezwecken
          von Twitter.{" "}
          <strong className="adsimple-111240294">
            <br />
            Ablaufdatum:{" "}
          </strong>
          Nach einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>ct0
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          c1179f07163a365d2ed7aad84c99d966
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Zu
          diesem Cookie haben wir leider keine Informationen gefunden.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach 6
          Stunden
        </p>
        <p>
          <strong className="adsimple-111240294">Name: </strong>_twitter_sess
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          53D%253D–dd0248111240294-
          <br />
          <strong className="adsimple-111240294">Verwendungszweck: </strong>Mit
          diesem Cookie können Sie Funktionen innerhalb der Twitter-Webseite
          nutzen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum: </strong>nach
          Sitzungsende
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung:</strong> Twitter
          arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unsrem
          Test auch die drei Google-Analytics-Cookies _ga, _gat, _gid erkannt.
        </p>
        <p>
          Twitter verwendet die erhobenen Daten einerseits, um das Userverhalten
          besser zu verstehen und somit Ihre eigenen Dienste und Werbeangebote
          zu verbessern, andererseits dienen die Daten auch internen
          Sicherheitsmaßnahmen.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Wenn Twitter Daten von anderen Webseiten erhebt, werden diese nach
          maximal 30 Tagen gelöscht, zusammengefasst oder auf andere Weise
          verdeckt. Die Twitter-Server liegen auf verschiedenen Serverzentren in
          den Vereinigten Staaten. Demnach ist davon auszugehen, dass erhobenen
          Daten in Amerika gesammelt und gespeichert werden. Nach unserer
          Recherche konnten wir nicht eindeutig feststellen, ob Twitter auch
          eigene Server in Europa hat. Grundsätzlich kann Twitter die erhobenen
          Daten speichern, bis sie dem Unternehmen nicht mehr dienlich sind, Sie
          die Daten löschen oder eine gesetzliche Löschfrist besteht.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Twitter betont in Ihren Datenschutzrichtlinien immer wieder, dass sie
          keine Daten von externen Webseitenbesuchen speichert, wenn Sie bzw.
          Ihr Browser sich im europäischen Wirtschaftsraum oder in der Schweiz
          befinden. Falls Sie allerdings mit Twitter direkt interagieren,
          speichert Twitter selbstverständlich auch Daten von Ihnen.
        </p>
        <p>
          Wenn Sie ein Twitter-Konto besitzen, können Sie Ihre Daten verwalten,
          indem Sie unter dem „Profil“-Button auf „Mehr“ klicken. Anschließend
          klicken Sie auf „Einstellungen und Datenschutz“. Hier können Sie die
          Datenverarbeitung individuell verwalten.
        </p>
        <p>
          Wenn Sie kein Twitter-Konto besitzen, können Sie auf{" "}
          <a className="adsimple-111240294" href="https://twitter.com/">
            twitter.com
          </a>
           gehen und dann auf „Individualisierung“ klicken. Unter dem Punkt
          „Individualisierung und Daten“ können Sie Ihre erhobenen Daten
          verwalten.
        </p>
        <p>
          Die meisten Daten werden, wie oben bereits erwähnt, über Cookies
          gespeichert und die können Sie in Ihrem Browser verwalten,
          deaktivieren oder löschen. Bitte beachten Sie, dass Sie die Cookies
          nur in dem von Ihnen gewählten Browser “bearbeiten”. Das heißt:
          verwenden Sie in Zukunft einen anderen Browser, müssen Sie dort Ihre
          Cookies erneut nach Ihren Wünschen verwalten. Hier gibt es die
          Anleitung zur Cookie-Verwaltung der bekanntesten Browser.
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.google.com/chrome/answer/95647?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-111240294"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Ihren Browser können Sie auch so verwalten, dass Sie bei jedem
          einzelnen Cookie informiert werden. Dann können Sie immer individuell
          entscheiden, ob Sie ein Cookie zulassen oder nicht.
        </p>
        <p>
          Twitter verwendet die Daten auch für personalisierte Werbung in- und
          außerhalb von Twitter. In den Einstellungen können Sie unter
          „Individualisierung und Daten“ die personalisierte Werbung abschalten.
          Wenn Sie Twitter auf einem Browser nutzen, können Sie die
          personalisierte Werbung unter{" "}
          <a
            className="adsimple-111240294"
            href="http://optout.aboutads.info/?c=2&lang=EN"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://optout.aboutads.info/?c=2&lang=EN
          </a>{" "}
          deaktivieren.
        </p>
        <p>
          Twitter ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework.
          Dieses Framework stellt eine korrekte Datenübertragung zwischen den
          USA und der Europäischen Union sicher. Unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO
          </a>
           erfahren Sie mehr darüber.
        </p>
        <p>
          Wir hoffen, wir haben Ihnen einen grundsätzlichen Überblick über die
          Datenverarbeitung durch Twitter gegeben. Wir erhalten keinen Daten von
          Twitter und tragen auch keine Verantwortung darüber, was Twitter mit
          Ihren Daten macht. Falls Sie noch weitere Fragen zu diesem Thema
          haben, empfehlen wir Ihnen die Twitter-Datenschutzerklärung unter{" "}
          <a
            className="adsimple-111240294"
            href="https://twitter.com/de/privacy"
          >
            https://twitter.com/de/privacy
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">Gravatar Datenschutzerklärung</h2>
        <p>
          Wir verwenden auf dieser Webseite Gravatar der Firma Automattic Inc.
          (60 29th Street #343, San Francisco, CA 94110, USA) um Benutzerbilder
          (Avatar) auf Basis der E-Mail-Adresse bei Beiträgen und Kommentaren
          zuzuordnen.
        </p>
        <p>
          Ein Gravatar ist ein <strong className="adsimple-111240294">G</strong>
          lobally <strong className="adsimple-111240294">
            R
          </strong>ecognized{" "}
          <strong className="adsimple-111240294">Avatar </strong>– ein weltweit
          verfügbares Benutzerbild – welches einer E-Mail-Adresse zugeordnet und
          bei deren Verwendung in verschiedenen Onlinediensten zum Einsatz
          kommen kann.
          <br />
          Da nur beim Einsatz einer bei Gravatar registrierten E-Mail-Adresse
          ein Bild angezeigt wird und somit Daten zu Gravatar übertragen werden,
          können Sie die Übertragung Ihrer Daten zu Gravatar verhindern, indem
          Sie mit einer bei Gravatar nicht registrierten E-Mail-Adresse
          kommentieren oder Beiträge verfassen.
        </p>
        <p>
          Automattic ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
          Framework wodurch der korrekte und sichere Datentransfer persönlicher
          Daten geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC
          </a>{" "}
          .<br />
          Mehr Details zur Datenschutzrichtlinie und welche Daten auf welche Art
          durch Gravatar erfasst werden finden Sie auf{" "}
          <a
            className="adsimple-111240294"
            href="https://automattic.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://automattic.com/privacy/
          </a>
          , allgemeine Informationen zu Gravatar auf{" "}
          <a
            className="adsimple-111240294"
            href="http://de.gravatar.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://de.gravatar.com/
          </a>
          .
        </p>
        <h2 className="adsimple-111240294">
          Google reCAPTCHA Datenschutzerklärung
        </h2>
        <p>
          Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns
          bestmöglich zu sichern und zu schützen. Um das zu gewährleisten,
          verwenden wir Google reCAPTCHA der Firma Google Inc. (1600
          Amphitheatre Parkway Mountain View, CA 94043, USA). Mit reCAPTCHA
          können wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch
          und Blut sind und kein Roboter oder eine andere Spam-Software. Unter
          Spam verstehen wir jede, auf elektronischen Weg, unerwünschte
          Information, die uns ungefragter Weise zukommt. Bei den klassischen
          CAPTCHAS mussten Sie zur Überprüfung meist Text- oder Bildrätsel
          lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen
          Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie
          einfach ein Häkchen setzen und so bestätigen, dass Sie kein Bot sind.
          Mit der neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr
          ein Häkchen setzen. Wie das genau funktioniert und vor allem welche
          Daten dafür verwendet werden, erfahren Sie im Verlauf dieser
          Datenschutzerklärung.
        </p>
        <h3 className="adsimple-111240294">Was ist reCAPTCHA?</h3>
        <p>
          reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor
          Spam-Software und den Missbrauch durch nicht-menschliche Besucher
          schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie
          Formulare im Internet ausfüllen. Ein Captcha-Dienst ist eine Art
          automatischer Turing-Test, der sicherstellen soll, dass eine Handlung
          im Internet von einem Menschen und nicht von einem Bot vorgenommen
          wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan
          Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch
          fest. Bei Captchas übernimmt das auch der Computer bzw. ein
          Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben,
          die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche
          Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel
          mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen
          von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich
          bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst
          das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den
          Quelltext eingebunden und dann läuft das Tool im Hintergrund und
          analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet
          die Software einen sogenannten Captcha-Score. Google berechnet mit
          diesem Score schon vor der Captcha-Eingabe wie hoch die
          Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw.
          Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots
          gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.)
          manipulieren oder missbrauchen könnten.
        </p>
        <h3 className="adsimple-111240294">
          Warum verwenden wir reCAPTCHA auf unserer Webseite?
        </h3>
        <p>
          Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite
          begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen
          getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns
          zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie
          anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma
          Google. So können wir uns ziemlich sicher sein, dass wir eine
          „botfreie“ Webseite bleiben. Durch die Verwendung von reCAPTCHA werden
          Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich
          ein Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite
          und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte
          es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot
          möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren
          oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA
          können wir solche Botangriffe vermeiden.
        </p>
        <h3 className="adsimple-111240294">
          Welche Daten werden von reCAPTCHA gespeichert?
        </h3>
        <p>
          reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen,
          ob die Handlungen auf unserer Webseite auch wirklich von Menschen
          stammen. Es kann also die IP-Adresse und andere Daten, die Google für
          den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen
          werden innerhalb der Mitgliedstaaten der EU oder anderer
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          fast immer zuvor gekürzt, bevor die Daten auf einem Server in den USA
          landen. Die IP-Adresse wird nicht mit anderen Daten von Google
          kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit
          Ihrem Google-Konto angemeldet sind. Zuerst prüft der
          reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von
          anderen Google-Diensten (YouTube. Gmail usw.) platziert sind.
          Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser
          und erfasst einen Schnappschuss Ihres Browserfensters.
        </p>
        <p>
          Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht
          den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von
          Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.
        </p>
        <ul className="adsimple-111240294">
          <li className="adsimple-111240294">
            Referrer URL (die Adresse der Seite von der der Besucher kommt)
          </li>
          <li className="adsimple-111240294">
            IP-Adresse (z.B. 256.123.123.1)
          </li>
          <li className="adsimple-111240294">
            Infos über das Betriebssystem (die Software, die den Betrieb Ihres
            Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS
            X oder Linux)
          </li>
          <li className="adsimple-111240294">
            Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
          </li>
          <li className="adsimple-111240294">
            Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder
            der Tastatur ausführen wird gespeichert)
          </li>
          <li className="adsimple-111240294">
            Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie
            auf Ihrem PC voreingestellt haben wird gespeichert)
          </li>
          <li className="adsimple-111240294">
            Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die
            Webseiten ermöglicht, sich an den User anzupassen.
            JavaScript-Objekte können alle möglichen Daten unter einem Namen
            sammeln)
          </li>
          <li className="adsimple-111240294">
            Bildschirmauflösung (zeigt an aus wie vielen Pixeln die
            Bilddarstellung besteht)
          </li>
        </ul>
        <p>
          Unumstritten ist, dass Google diese Daten verwendet und analysiert
          noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der
          Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der
          ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche
          Daten Google genau speichert, erfährt man von Google nicht im Detail.
        </p>
        <p>
          Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir
          uns auf die reCAPTCHA Demo-Version von Google unter{" "}
          <a
            className="adsimple-111240294"
            href="https://www.google.com/recaptcha/api2/demo"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/recaptcha/api2/demo
          </a>
          . All diese Cookies benötigen zu Trackingzwecken eine eindeutige
          Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der
          Demo-Version gesetzt hat:
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> IDE
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-111240294-8
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie wird von der Firma DoubleClick (gehört auch Google)
          gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit
          Werbeanzeigen zu registrieren und zu melden. So kann die
          Werbewirksamkeit gemessen und entsprechende Optimierungsmaßnahmen
          getroffen werden. IDE wird in Browsern unter der Domain
          doubleclick.net gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Jahr
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> 1P_JAR
          <br />
          <strong className="adsimple-111240294">Wert:</strong> 2019-5-14-12
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Dieses Cookie sammelt Statistiken zur Webseite-Nutzung und misst
          Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem
          Käufer wird. Das Cookie wird auch verwendet, um Usern relevante
          Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie vermeiden,
          dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach
          einem Monat
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> ANID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>
           U7j1v3dZa1112402940xgZFmiqWppRWKOr
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Viele Infos konnten wir über dieses Cookie nicht in Erfahrung bringen.
          In der Datenschutzerklärung von Google wird das Cookie im Zusammenhang
          mit „Werbecookies“ wie z. B. “DSID”, “FLC”, “AID”, “TAID” erwähnt.
          ANID wird unter Domain google.com gespeichert.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 9
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-111240294">Wert: </strong>
          YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> Das
          Cookie speichert den Status der Zustimmung eines Users zur Nutzung
          unterschiedlicher Services von Google. CONSENT dient auch der
          Sicherheit, um User zu überprüfen, Betrügereien von
          Anmeldeinformationen zu verhindern und Userdaten vor unbefugten
          Angriffen zu schützen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 19
          Jahren
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> NID
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          0WmuWqy111240294zILzqV_nmt3sDXwPeM5Q
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong> NID
          wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche
          anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist
          eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen.
          So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie
          enthält eine einzigartige ID, um persönliche Einstellungen des Users
          für Werbezwecke zu sammeln.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 6
          Monaten
        </p>
        <p>
          <strong className="adsimple-111240294">Name:</strong> DV
          <br />
          <strong className="adsimple-111240294">Wert:</strong>{" "}
          gEAABBCjJMXcI0dSAAAANbqc111240294-4
          <br />
          <strong className="adsimple-111240294">Verwendungszweck:</strong>{" "}
          Sobald Sie das „Ich bin kein Roboter“-Häkchen angekreuzt haben, wird
          dieses Cookie gesetzt. Das Cookie wird von Google Analytics für
          personalisierte Werbung verwendet. DV sammelt Informationen in
          anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen
          zu treffen.
          <br />
          <strong className="adsimple-111240294">Ablaufdatum:</strong> nach 10
          Minuten
        </p>
        <p>
          <strong className="adsimple-111240294">Anmerkung: </strong>Diese
          Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
          erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
        </p>
        <h3 className="adsimple-111240294">
          Wie lange und wo werden die Daten gespeichert?
        </h3>
        <p>
          Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den
          Google-Server übertragen. Wo genau diese Daten gespeichert werden,
          stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar.
          Ohne eine Bestätigung von Google erhalten zu haben, ist davon
          auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der
          Webseite oder Spracheinstellungen auf den europäischen oder
          amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die
          Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit
          anderen Google-Daten aus weiteren Google-Diensten zusammengeführt.
          Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei
          Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt.
          <strong className="adsimple-111240294"> </strong>Dafür gelten die
          abweichenden Datenschutzbestimmungen der Firma Google.
        </p>
        <h3 className="adsimple-111240294">
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
        </h3>
        <p>
          Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an
          Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite
          besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen
          ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die
          Daten sobald Sie unsere Seite aufrufen automatisch an Google
          übermittelt. Um diese Daten wieder zu löschen, müssen Sie den
          Google-Support auf {" "}
          <a
            className="adsimple-111240294"
            href="https://support.google.com/?hl=de&tid=111240294"
          >
            https://support.google.com/?hl=de&tid=111240294
          </a>{" "}
          kontaktieren.
        </p>
        <p>
          Wenn Sie also unsere Webseite verwenden, erklären Sie sich
          einverstanden, dass Google LLC und deren Vertreter automatisch Daten
          erheben, bearbeiten und nutzen.
        </p>
        <p>
          Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von
          Google auf{" "}
          <a
            className="adsimple-111240294"
            href="https://developers.google.com/recaptcha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/recaptcha/
          </a>
          . Google geht hier zwar auf die technische Entwicklung der reCAPTCHA
          näher ein, doch genaue Informationen über Datenspeicherung und
          datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute
          Übersicht über die grundsätzliche Verwendung von Daten bei Google
          finden Sie in der hauseigenen Datenschutzerklärung auf{" "}
          <a
            className="adsimple-111240294"
            href="https://policies.google.com/privacy?hl=de&tid=111240294"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>
          .
        </p>
        <p>
          Quelle: Erstellt mit dem Datenschutz Generator von{" "}
          <a
            title="Datenschutz Generator von adsimple.at"
            href="https://www.adsimple.at"
            target="_blank"
            rel="noopener noreferrer"
          >
            Content Marketing AdSimple
          </a>
           in Kooperation mit{" "}
          <a
            href="https://www.bauguide.at"
            target="_blank"
            rel="noopener noreferrer"
            title=""
          >
            bauguide.at
          </a>
        </p>
      </div>
    );
  }
  render() {
    console.log(this.props.lang);
    if (this.props.lang === "de") {
      return this.de();
    } else if (this.props.lang === "en") {
      return this.en();
    } else if (this.props.lang === "es") {
      return this.es();
    }
    return <div></div>;
  }
}
